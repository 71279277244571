import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../Navbar/Main'
import Footer from '../Footer/Main'

const Infosec = () => {
  return (
    <>
      <Navbar/>
      <div className="stricky-header stricked-menu main-menu">
        <div className="sticky-header__content"></div>
        </div>
        <section className="page-header">
            <div className="page-header__bg"    ></div>
            <div className="container">
                <ul className="thm-breadcrumb list-unstyled">
                    <li><Link to="/">Home</Link></li>
                    <li><span>Information Security Policy Statement</span></li>
                </ul>
            </div>
        </section>
        <section className="service-five pt-50 pb-50">
        <div className="container">
            <div className="row row-gutter-y-50 service-four__inner"> 
               <div className="col-lg-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">
                        <div className="service-details__content">
                            <h3 className="service-details__title">Information Security Policy Statement</h3>
                            <p>
                            The National Bank of Rwanda’s (NBR) management has established and documented an information security policy 
                            based on the requirements of ISO/IEC 27001:2022 standard, which is appropriate to the Bank’s purpose. 
                            This policy encompasses information security objectives aimed at safeguarding the Confidentiality, 
                            Integrity, and Availability of Information and information processing assets from all threats, 
                            whether internal or external, deliberate or accidental, in relation to the processing, transmitting, 
                            and storing of sensitive Bank Information. NBR shall therefore:
                            <div className="documentation-content">
                          <ul>
                              <li><i className="fa fa-check-circle"></i>  Establish and implement risk-based information security controls.</li>
                              <li><i className="fa fa-check-circle"></i> Periodically review and update operational procedures for all business functions.</li>
                              <li><i className="fa fa-check-circle"></i> Ensure compliance with legal, statutory and regulatory requirements and contractual security obligation</li>
                              <li><i className="fa fa-check-circle"></i> Ensure information security awareness amongst staff, interns, service providers, third party contractors and end users of NBR information systems</li>
                              <li><i className="fa fa-check-circle"></i> Ensure management of security incidents effectively through an incident management framework</li>
                              <li><i className="fa fa-check-circle"></i> Develop business continuity plans that address information security continuity.</li>
                              <li><i className="fa fa-check-circle"></i> Ensure continual improvement of the Information Security Management System (ISMS) through regular reviews of measurable information security objectives.</li>
                            </ul>
                            </div>
                            </p>
            <p>
                NBR’s management is committed to satisfy the applicable requirements related to information security and to the continual 
improvement of the information security management system. The policy shall be communicated to all interested parties
 (defined in the scope documentation of the ISMS) through the approved communication channels used by the 
 Bank and observed in contractual agreements. 
 </p>
                        </div>
                    </div>
                    </div>
                </div> 
        </section>
        <Footer/>
    </>

  );
};

export default Infosec;