import React, { useRef, useState, useEffect } from "react";

const Searchdata = ({ onSearch }) => {

  const inputRef = useRef(null);

  useEffect(() => {
    // Focus the input element when the component mounts
      if (inputRef.current) {
          inputRef.current.focus();
      }
  }, []); // Empty dependency array ensures this effect runs only on mount

    
    
    const [search, setSearch] = useState(""); 

    const onInputChange = value => {
        setSearch(value);
        onSearch(value);
    };
    return (
        <input
            
        value={search}
        // onClick={handleClick}
        autoComplete='off' style={{ width: '80%', borderTopLeftRadius: '50%', borderBottomLeftRadius: '50%' }}
        type="text"
        id="search"
        placeholder="Search here..." 
            className="search-popup__content fsl_search_container"
            // style={{width: '20%', borderRadius: '50%'}}
            ref={inputRef}
            onChange={e => onInputChange(e.target.value)} />
    );

};

export default Searchdata;
