import BGPH1 from '../../assets/images/backgrounds/bnrimage.jpeg'
import ABOUTIMG from '../../assets/images/resources/about.png'
import { Link } from 'react-router-dom'
import React, { useRef, useState, useEffect } from "react";
import axios from 'axios'
import Footer from '../Footer/Main';
import Navbar from '../Navbar/Main';

const Employee = () => {
    
    const [empyear, setEmpyear] = useState()
    useEffect(() => {
        axios.get('/empyear')
            .then((response) => {
                let empyearData = response.data
                console.log(empyearData);
                setEmpyear(empyearData)
            })
            .catch((error) => console.log(error))
    }, [])


    // const yrs = (new Date().getFullYear()) - (1964);

  return (
    <>
<Navbar/>
      <div className="stricky-header stricked-menu main-menu">
        <div className="sticky-header__content"></div>
      </div>
      <section className="page-header">
        <div className="page-header__bg"   ></div>

        <div className="container">
                <ul className="thm-breadcrumb list-unstyled">
                    <li><Link to="/">Home</Link></li>
                    <li><span>Employee of the year</span></li>
                </ul>
            </div>
      </section>
      <section className="service-five pt-50 pb-50">
      
        <div className="container">
        <div className="row row-gutter-y-30 contact-info-one">
        <p className="block-title__tagline text-center"> Best Employee of the Year</p>
        {
                      empyear ? empyear.map((b, index) => (
                                    <>
                    <div className="col-lg-6">
                        
      <p><br/></p>
                        <div className="team-details__image wow text-center fadeInUp" data-wow-duration="1500ms">
                        <img src={`../${b.image}`}/>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="team-details__content">
                            <div className="block-title text-left">
                                {/* <p className="block-title__tagline">{b.role}</p> */}
                                <h2 className="block-title__title">{b.name}</h2>
                            </div>
                            <div className="team-details__social">
                                <Link to={`https://x.com/${b.twitter}`}><i className="fab fa-twitter"></i></Link>
                                <Link to="#"><i className="fab fa-linkedin"></i></Link>
                            </div>
                            <p className="team-details__highlight">Currently {b.role}</p>
                            
                            <p className="team-details__text">{b.description} </p>
                        </div>
                    </div>
                    </> 
                           
                           )) : <p>loading...</p>
                       }
                </div>
            </div><p><br></br></p>

        </section>

        <section className="contact-info-one">
            <div className="container">
                <div className="row">
                    <div className="col-md-4 col-sm-12">
                        <div className="contact-info-one__item">
                            <div className="contact-info-one__icon">
                                <i className="icon-telephone"></i>
                            </div>
                            <div className="contact-info-one__content">
                                <p className="contact-info-one__text">Have any question?</p>
                                <a className="contact-info-one__link" href="tel:+ (250) 788199000 ">+ (250) 788199000 </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <div className="contact-info-one__item">
                            <div className="contact-info-one__icon">
                                <i className="icon-email"></i>
                            </div>
                            <div className="contact-info-one__content">
                                <p className="contact-info-one__text">Write us email</p>
                                <a className="contact-info-one__link" href="mailto:info@bnr.rw">info@bnr.rw</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <div className="contact-info-one__item">
                            <div className="contact-info-one__icon">
                                <i className="icon-pin"></i>
                            </div>
                            <div className="contact-info-one__content">
                                <p className="contact-info-one__text">Visit anytime</p>
                                <a className="contact-info-one__link" href="#"> KN 6 AV 4, Kigali Rwanda</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
{/* 

        <div className="google-map__default">
            <iframe title="template google map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1020806.7305464278!2d29.319977754475655!3d-1.9421909721377053!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19c29654e73840e3%3A0x7490b026cbcca103!2sRwanda!5e0!3m2!1sen!2sin!4v1540289964660" className="map__default" allowFullscreen></iframe>
        </div> */}
      <Link to="/contact" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-comments"></i></Link >

<Footer/>
    </>
  )
}

export default Employee