import us from '../../assets/images/flags/us.png';
import ke from '../../assets/images/flags/ke.png';
import rw from '../../assets/images/flags/rw.png';
import eu from '../../assets/images/flags/eu.png';
import bi from '../../assets/images/flags/bi.png';
import ao from '../../assets/images/flags/ao.png';
import au from '../../assets/images/flags/au.png';
import ca from '../../assets/images/flags/ca.png';
import cn from '../../assets/images/flags/cn.png';
import ch from '../../assets/images/flags/ch.png';
import dk from '../../assets/images/flags/dk.png';
import eg from '../../assets/images/flags/eg.png';
import et from '../../assets/images/flags/et.png';
import mw from '../../assets/images/flags/mw.png';
import ng from '../../assets/images/flags/ng.png';
import gh from '../../assets/images/flags/gh.png';
import kr from '../../assets/images/flags/kr.png';
import mu from '../../assets/images/flags/mu.png';
import pl from '../../assets/images/flags/pl.png';
import kw from '../../assets/images/flags/kw.png';
import il from '../../assets/images/flags/il.png';
import ae from '../../assets/images/flags/ae.png';
import gb from '../../assets/images/flags/gb.png';
import mk from '../../assets/images/flags/mk.png';
import ls from '../../assets/images/flags/ls.png';
import mr from '../../assets/images/flags/mr.png';
import no from '../../assets/images/flags/no.png';
import ma from '../../assets/images/flags/ma.png';
import ly from '../../assets/images/flags/ly.png';
import jp from '../../assets/images/flags/jp.png';
import hk from '../../assets/images/flags/hk.png';
import gn from '../../assets/images/flags/gn.png';
import mz from '../../assets/images/flags/mz.png';
import sa from '../../assets/images/flags/sa.png';
import qa from '../../assets/images/flags/qa.png';
import sg from '../../assets/images/flags/sg.png';
import se from '../../assets/images/flags/se.png';
import tz from '../../assets/images/flags/tz.png';
import zm from '../../assets/images/flags/zm.png';
import ug from '../../assets/images/flags/ug.png';
import tr from '../../assets/images/flags/tr.png';
import sd from '../../assets/images/flags/sd.png';
import ru from '../../assets/images/flags/ru.png';
import jo from '../../assets/images/flags/jo.png';
import br from '../../assets/images/flags/br.png';
import hu from '../../assets/images/flags/hu.png';
import id from '../../assets/images/flags/id.png';
import za from '../../assets/images/flags/za.png';
import ss from '../../assets/images/flags/ss.png';
import sz from '../../assets/images/flags/sz.png';
import zw from '../../assets/images/flags/zw.png';

export const flagUrls = {
    'USD': us,
    'KES': ke,
    'RWF': rw,
    'EUR': eu,
    'BIF': bi,
    'AOA': ao,
    'AUD': au,
    'CAD': ca,
    'CNY': cn,
    'CHF': ch,
    'DKK': dk,
    'EGP': eg,
    'ETB': et,
    'MWK': mw,
    'NGN': ng,
    'GHS': gh,
    'KRW': kr,
    'MUR': mu,
    'PLN': pl,
    'KWD': kw,
    'ILS': il,
    'AED': ae,
    'GBP': gb,
    'INR': '../../assets/images/flags/in.png',
    'KMF': mk,
    'LSL': ls,
    'MRO': mr,
    'NOK': no,
    'MAD': ma,
    'LYD': ly,
    'JPY': jp,
    'HKD': hk,
    'GNF': gn,
    'MZN': mz,
    'SAR': sa,
    'QAR': qa,
    'SGD': sg,
    'SEK': se,
    'TZS': tz,
    // 'XAF': xa,
    'ZMW': zm,
    'UGX': ug,
    'TRY': tr,
    'SDG': sd,
    'RUB': ru,
    'JOD': jo,
    'BRL': br,
    'HUF': hu,
    'IDR': id,
    'CNH': cn,
    'ZAR': za,
    // 'XOF': xo,
    'SSP': ss,
    // 'XDR': xd,
    'SZL': sz,
    'ZIG': zw,
         
    // Add more mappings here
};
export default flagUrls;