import BGPH1 from '../../assets/images/backgrounds/page-header-bg-1-1.jpg'
import logo from '../../assets/images/logo-dark.png'
import { Link } from 'react-router-dom'
import React, { useState, useEffect } from "react";
import axios from 'axios'
import Navbar from '../Navbar/Main';
import Footer from '../Footer/Main';
import categoryInfo from '../../categoryInfo.json'

export default function LawsRegulations() {
    //axios api to display monetary policy data
   
    const [post, setPost] = useState([]); 
    useEffect(() => {
    setPost(categoryInfo.lawsandregulations);
}, []);


  return (
    <>
    <Navbar/>
        <div className="stricky-header stricked-menu main-menu">
            <div className="sticky-header__content"></div>
        </div>
        <section className="page-header">
            <div className="page-header__bg"    ></div>
            <div className="container">
                <ul className="thm-breadcrumb list-unstyled">
                    <li><Link to="/">Home</Link></li>
                    <li><span>Laws and Regulations</span></li>
                </ul>
                  <div className="surrounding slider-one__title">
                  The National Bank of Rwanda is the Supervisor and Regulator of Financial Institutions in Rwanda. 
                            The promotion, soundness and stability of the Sector is attained through implementation of international principles 
                            and standards as reviewed from time to time by competent bodies such as International Monetary Fund (IMF) and World Bank.
                            <br></br>
                            The Legal and regulatory framework embedded in this section is therefore designed to ensure that 
                            Financial Institutions adhere to prudential norms and robust protection of clients and the system in general. 
                            This Section on the Central Bank website contains all relevant Legal Instruments pertaining Financial Sector in Rwanda.

                </div>
            </div>
        </section>
        <section className="service-five ">
            <div className="container">
            <div className="row row-gutter-y-50 service-four__inner">    
               <div className="about-three__content"> 
                        <div className="block-title text-center">
                          <h2 className="block-title__tagline">Get to know about NBR Laws and Regulations</h2>
                        </div>
                </div>                
                {
                      post ? post.map((p, index) => (
                                    <>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="service-card">
                            <div className="service-card__image">
                                <img src={p.image} width="370" height="300" alt="" />
                                <Link to={`${p.link}`} > 
                                 </Link>
                            </div>
                            <div className="service-card__content">
                                <div className="service-card__content__inner">
                                    <div className="service-card__icon">
                                        {/* <i className="icon-payment"></i><Link to={`${p.link}`} > </Link> */}
                                        <img src={logo} width="70" height="70" alt="BNR"/>
                                    </div>
                                    <h3 className="service-card__title">
                                    <Link to={`${p.link}`}>{p.title}</Link>
                                    </h3>
                                    <p className="service-card__text">{p.summary}
                                    <Link to={`${p.link}`} className="service-card__link">
                                        <i className="fa fa-angle-right"></i>
                                    </Link>
                                    </p>
                                </div>
                            </div>
                        </div>
             
                </div>
                </> 
                           
                           )) : <p>loading...</p>
                       }
          
                 </div>
               </div>
        </section>
        <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link>
        <Footer/>
    </>
  )
}

