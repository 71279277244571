import BGPH1 from '../../assets/images/backgrounds/page-header-bg-1-1.jpg'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useParams } from 'react-router-dom'
import React, { useState, useEffect, useMemo,Component }   from 'react'
import SFsidebar from './sfsidebar'
import SFquery from './sfquery'
import axios from 'axios'
//import { Container } from "react-bootstrap";
//import Header from "components/Header";
import { TableHeader, Pagination, Search } from '../DataTable';
import Footer from '../Footer/Main'
import Navbar from '../Navbar/Main'
import DOMPurify from 'dompurify';
// import Service from '../Main'
// import onSubmitForm from '../Main'
import {
    MDBTabsContent,
    MDBTabsPane
  } from 'mdb-react-ui-kit';
// import Service from '../Main'
// import onSubmitForm from '../Main'

export const Ecreview = () => {
    
       
    const [iconsActive, setIconsActive] = useState('tab1');

    const handleIconsClick = (value) => {
      if (value === iconsActive) {
        return;
      }
      setIconsActive(value);
    };
   const [doc, setDoc] = useState([]); 
   const [docer, setDocer] = useState([]);
   const [isLoading, setIsLoading] = useState(true);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });

    const ITEMS_PER_PAGE6 = 30;

    const headers6 = [
        { name: "Date", field: "Date", sortable: false },
        { name: "Economic Review", field: "Economic Review", sortable: false },
    ];

   useEffect(() => {
       axios.get(`/Economic_Review`)
           .then((response) => {
               let postData = response.data
               console.log(postData);
               setDocer(postData)
               setIsLoading(false);
           })
           .catch((error) => console.log(error))
           setIsLoading(false);
   }, [])

  
  const docData6 = useMemo(() => {
    let computeddoc = docer;

    if (search) {
        computeddoc = computeddoc.filter(
                d =>
                d.date_last_modified.toLowerCase().includes(search.toLowerCase()) ||
                d.name.toLowerCase().includes(search.toLowerCase())
        );
    }

    setTotalItems(computeddoc.length);

    //Sorting doc
    if (sorting.field) {
        const reversed = sorting.order === "asc" ? 1 : -1;
        computeddoc = computeddoc.sort(
            (a, b) =>
                reversed * a[sorting.field].localeCompare(b[sorting.field])
        );
    }

    //Current Page slice
    return computeddoc.slice(
        (currentPage - 1) * ITEMS_PER_PAGE6,
        (currentPage - 1) * ITEMS_PER_PAGE6 + ITEMS_PER_PAGE6
    );
}, [docer, currentPage, search, sorting]);

    const ITEMS_PER_PAGE = 30;

    const headers = [
        { name: "Date", field: "Date", sortable: false },
        { name: "Economic Review - Archives", field: "Economic Review - Archives", sortable: false },
    ];

   useEffect(() => {
       axios.get(`/Economic_Review_Archive`)
           .then((response) => {
               let postData = response.data
               console.log(postData);
               setDoc(postData)
           })
           .catch((error) => console.log(error))
   }, [])

   const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    });
  };
  
  const docData = useMemo(() => {
    let computeddoc = doc;

    if (search) {
        computeddoc = computeddoc.filter(
                d =>
                d.date_last_modified.toLowerCase().includes(search.toLowerCase()) ||
                d.name.toLowerCase().includes(search.toLowerCase())
        );
    }

    setTotalItems(computeddoc.length);

    //Sorting doc
    if (sorting.field) {
        const reversed = sorting.order === "asc" ? 1 : -1;
        computeddoc = computeddoc.sort(
            (a, b) =>
                reversed * a[sorting.field].localeCompare(b[sorting.field])
        );
    }

    //Current Page slice
    return computeddoc.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
}, [doc, currentPage, search, sorting]);

   

        const [clicked, setClicked] = useState(0);

        return (
            <>
    <Navbar/>
    <div className="stricky-header stricked-menu main-menu">
            <div className="sticky-header__content"></div>
        </div>
        <section className="page-header">
            <div className="page-header__bg"    ></div>
            <div className="container">
                <ul className="thm-breadcrumb list-unstyled">
                    <li><Link to="/">Home</Link></li>
                    <li><span> BNR Economic Review</span></li>
                </ul>
                {/* <div className="surrounding slider-one__title">
                BNR Economic Review is devoted to publishing articles of interest to policymakers, academics and practitioners on a wide range of economic issues with policy relevance. 
		   The journal welcomes empirical and theoretical studies with an emphasis on central bank responsibilities and other economic policies. 
		   The journal aims to promote interaction between researchers and policymakers and contribute to knowledge dissemination.  
                </div> */}
            </div>
        </section>
        {/* <div  className="about-four__shape"></div>
  <div  className="about-five__shape-2"></div> 
  <div  className="about-five__shape-1"></div>
  <div  className="about-five__shape-9"></div> 
  <div  className="about-five__shape-0"></div> */}
        <section className="service-details pt-30 pb-30">
            <div className="container">
                <div className="row row-gutter-y-50 contact-info-one">
                    <div className="col-lg-8">
                        {/* <div className="service-details__image">
                            <a href="/monetarypolicy" className="service-details__icon"><i className="fa fa-arrow-left"></i></a>
                        </div> */}
                        <div className="service-details__content">
                       
                            <div className="row row-gutter-y-30">
                                <div className="col-md-12">
                 
                      {/* <img src={p.image} alt="" /> */}
                      <h3 className="service-details__title">Get to know about BNR Economic Review</h3>
                      {/* <img src="../../../assets/images/services/service-d-1-1.png" alt="" /> */}
                      {/* <img src= {`../${p.image}`} alt="" /> */}
                      
                                      <p>
                                          BNR Economic Review is devoted to publishing articles of interest to policymakers, academics and practitioners on a wide range of economic issues with policy relevance.
						  The journal welcomes empirical and theoretical studies with an emphasis on central bank responsibilities and other economic policies. 
						  The journal aims to promote interaction between researchers and policymakers and contribute to knowledge dissemination. 
 </p>

<p> The views expressed in each article are those of the authors and do not necessarily represent the views of the National Bank of Rwanda.</p>

<p> For information about the BNR Economic Review contact <a href="#">economicreview@bnr.rw</a></p>

      {isLoading ? (
        <p>Loading...</p>
      ) : (
        docData6 ? (
          <div>
        
        <div className="row benefit-two__tab tabs-box" >
        <ul className="list-unstyled benefit-two__tab__title2 tab-btns tab-buttons"> 

                     <li className={`${iconsActive === 'tab1'} ? 'active-btn' : 'tab-btn'}`} 
                     onClick={() => handleIconsClick('tab1')} >
                         <span>Economic Review - Recent</span>
                     </li>
                     <li className="tab-btn" onClick={() => handleIconsClick('tab2')} active={iconsActive === 'tab2'}>
                         <span>Economic Review - Archives</span>
                     </li>
                 </ul>
<MDBTabsContent>
<MDBTabsPane show={iconsActive === 'tab1'}>

<div className="loan-comparison__body" id="compare-emi-1">
<div className="compare-table_doc table-responsive">
<table className="table">
<TableHeader
headers={headers6}
onSorting={(field, order) =>
    setSorting({ field, order })
}
/>
    <tbody>
       
{
               docData6 ? docData6.map((d, index) => (
                
                <tr key="">
                    <td>{formatDate(d.date_last_modified)}</td>
                    <td>
                    <div className="accrodion-grp service-details__accrodion" data-grp-name="service-details__accrodion-1">

            <div className={`accrodion  wow fadeInUp ${index === clicked && "active"}`} key={index} data-wow-delay="0ms">
                <div className="accrodion-title" onClick={() => setClicked(index)}>
                <h4>BNR Economic Review {d.latest_issue}</h4>
                    <span className="accrodion-icon"></span>
                </div>
                {index === clicked && 
                <div className="accrodion-content">
                    <div className="inner_doc" >
                    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(`${d.summary}`)}} />
                         {/*{d.summary} */}
                        <div/>
                    <p></p>
                    <Link to={`https://${d.link}`} target="_blank" rel="noopener noreferrer">More Details</Link>
                    </div>
                </div>}
            </div>
        </div>
                    </td>
                </tr>
         
   )) : <tr key=""><td>Loading.....</td></tr>
}
    </tbody>
</table>
<div className="col-md-4">
<Pagination
    total={totalItems}
    itemsPerPage={ITEMS_PER_PAGE}
    currentPage={currentPage}
    onPageChange={page => setCurrentPage(page)}
/>
</div>
</div>
</div>
</MDBTabsPane>

<MDBTabsPane show={iconsActive === 'tab2'}>

<div className="loan-comparison__body" id="compare-emi-1">
<div className="compare-table_doc table-responsive">

<table className="table">
<TableHeader
headers={headers}
onSorting={(field, order) =>
    setSorting({ field, order })
}
/>
    <tbody>
       
{
               docData ? docData.map((d, index) => (
                
                <tr key="">
                    <td>{formatDate(d.date_last_modified)}</td>
                    <td>
                    <div className="accrodion-grp service-details__accrodion" data-grp-name="service-details__accrodion-1">

            <div className={`accrodion  wow fadeInUp ${index === clicked && "active"}`} key={index} data-wow-delay="0ms">
                <div className="accrodion-title" onClick={() => setClicked(index)}>
                <h4>BNR Economic Review {d.latest_issue}</h4>
                    <span className="accrodion-icon"></span>
                </div>
                {index === clicked && 
                <div className="accrodion-content">
                    <div className="inner_doc" >
                    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(`${d.summary}`)}} />
                         {/*{d.summary} */}
                        <div/>
                        <Link to={d.file} target="_blank">Download/View <i className="fa fa-file-pdf"></i></Link>
                    </div>
                </div>}
            </div>
        </div>
                    </td>
                </tr>
         
   )) : <tr key=""><td>Loading.....</td></tr>
}
    </tbody>
</table>
<div className="col-md-4">
<Pagination
    total={totalItems}
    itemsPerPage={ITEMS_PER_PAGE}
    currentPage={currentPage}
    onPageChange={page => setCurrentPage(page)}
/>
</div>
</div>
</div>
</MDBTabsPane>

</MDBTabsContent>
</div>
          </div>
        ) : (
          <p>...</p>
        )
      )}
      </div>
      </div>
</div>
</div>

<div className="col-lg-4">
<SFsidebar/>
</div>
</div>
</div>
</section>
                <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link>
                <Footer/>
            </>
          )
}
