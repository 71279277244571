import BGPH1 from '../../../assets/images/backgrounds/page-header-bg-1-1.jpg'
import { Link, useParams } from 'react-router-dom'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { read, utils, writeFile } from 'xlsx';
import Navbar from '../../Navbar/Main';
import Footer from '../../Footer/Main';
import DOMPurify from 'dompurify';

    export function  NewsDetails (){
       

    //adding comment into db

    // const [newComment, setComment] = useState({ fullname: '', email: '', phone: '', post_id: '', comment: '' })
    // const [isSubmitting, setIsSubmitting] = useState(false);
    // const [isSubmitted, setIsSubmitted] = useState(false);
    // const handleChange = (event) => {
    //     setComment({
    //       ...newComment,
    //       [event.target.name]: event.target.value
    //     });
    //   }
    //   const handleSubmit = async (event) => {
    //     event.preventDefault();
    //     setIsSubmitting(true);
    //     try {
    //         // Make API call to submit the form data
    //         const response = await fetch('http://192.168.50.153:5000/addComment', {
    //         method: 'POST',
    //         headers: {
    //           'Content-Type': 'application/json'
    //         },
    //         body: JSON.stringify(newComment)
    //       });
    //       if (!response.ok) {
    //         throw new Error('Network response was not ok');
    //       }
    //       setIsSubmitting(false);
    //       setIsSubmitted(true);
    //       setComment({ fullname: '', email: '', phone: '', post_id: '', comment: '' }); // Clear the form state
    //     } catch (error) {
    //       console.error('Error submitting form', error);
    //       setIsSubmitting(false);
    //     }
    // };
    const [newsd, setNewsd] = useState()
    useEffect(() => {
        axios.get('/newsside')
            .then((response) => {
                let newsData = response.data
                console.log(newsData);
                setNewsd(newsData)
            })
            .catch((error) => console.log(error))
    }, [])

    const [news, setNews] = useState([]);
    const {id} = useParams();

    // useEffect(() => {
    //     axios.get(`/newsdetails/?id=${id}`)
    //         .then((response) => {
    //             let postData = response.data
    //             console.log(postData);
    //             setNews(postData)
    //         })
    //         .catch((error) => console.log(error))
    // }, [id])
		DOMPurify.addHook('afterSanitizeAttributes', function (node) {
		  if (node.tagName === 'A') {
			node.setAttribute('target', '_blank');
			node.setAttribute('rel', 'noopener noreferrer');
		  }
		});

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await fetch(`/newsdetails/?id=${id}`);
            if (response.ok) {
            const jsonData = await response.json();
            setNews(jsonData);
          } else {
            throw new Error('Page not found');
            // You can also set an error state here if needed
          }
        }catch (error){
            console.error(error);
        }
        }
        fetchData();
      }, [id]);
    
 

    //   async function fetchData(url) {
    //     try {
    //       const response = await fetch(url);
    //       if (response.status === 404) {
    //         throw new Error('Page not found');
    //       } else if (response.status === 500) {
    //         throw new Error('Server error');
    //       } else if (!response.ok) {
    //         throw new Error(`HTTP error! status: ${response.status}`);
    //       }
    //       const data = await response.json();
    //       return data;
    //     } catch (error) {
    //       console.error(error);
    //     }
    //   }


    // displaying comments
    // const [com, setCom] = useState()
    //    useEffect(() => {
    //     axios.get('/comments')
    //         .then((response) => {
    //             let postComment = response.data
    //             setCom(postComment)
    //         })
    // }, [])

    //    //displaying right side links
    // const [link, setLink] = useState()
    // useEffect(() => {
    //     axios.get('/monetaryPolicy')
    //         .then((response) => {
    //             let postLink = response.data
    //             setLink(postLink)
    //         })
    // }, [])


    // const [post, setPost] = useState()
 
    // GET Method
    // useEffect(() => {
    //     axios.get('/currency')
    //         .then((response) => {
    //             let postData = response.data
    //             setPost(postData)
    //         })
    // }, [])

    //handle excel
    // const HomeComponent = () => {
    //     const [post, setMovies] = useState([]);
    
    //     const handleImport = ($event) => {
    //         const files = $event.target.files;
    //         if (files.length) {
    //             const file = files[0];
    //             const reader = new FileReader();
    //             reader.onload = (event) => {
    //                 const wb = read(event.target.result);
    //                 const sheets = wb.SheetNames;
    
    //                 if (sheets.length) {
    //                     const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
    //                     setMovies(rows)
    //                 }
    //             }
    //             reader.readAsArrayBuffer(file);
    //         }
    //     }

    // const handleExport = () => {
    //     const headings = [[
    //         'CCY',
    //         'BUYING',
    //         'SELLING',
    //         'AVERAGE', 
    //         'DATE'
    //     ]];
    //     const wb = utils.book_new();
    //     const ws = utils.json_to_sheet([]);
    //     utils.sheet_add_aoa(ws, headings);
    //     utils.sheet_add_json(ws, post, { origin: 'A2', skipHeader: true });
    //     utils.book_append_sheet(wb, ws, 'Report');
    //     writeFile(wb, 'Exchange Rate.xlsx');
    // }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });
      };

  return (
    <>
    <Navbar/>
        <div className="stricky-header stricked-menu main-menu">
            <div className="sticky-header__content"></div>
        </div>
        <section className="page-header">
            
        {
                                  news ? news.map((n, index) => (
                                    
                                    <>
            {/* Background = {`../${n.image}`} */}
            <div className="page-header__bg"></div>
            
            
                </>
                        )) : <p>Loading.....</p>
                    }
            <div className="container">
                <ul className="thm-breadcrumb list-unstyled">
                    <li><Link to="/">Home</Link></li>
                    <li><span>News</span></li>
                </ul>
                {/* {
                                  news ? news.map((n, index) => (
                                    <>
                <h2>{n.title}</h2>
                </>
                        )) : <p>Loading.....</p>
                    } */}
            </div>
        </section>
        <section className="blog-details pt-30 pb-30">
            <div className="container">
            <div className="row row-gutter-y-30  contact-info-one">
                    <div className="col-lg-8">
                    {
                                  news ? news.map((n, index) => (
                                    <>
                        {/* <div className="blog-card__image">
                            <div className="blog-card__date">{formatDate(n.date_last_modified)}</div>
                            <img src={`../${n.image}`} alt="" />
                        </div> */}
                        
                        <h3 className="blog-card__title">{n.title}</h3>
                        {/* <div className="col-md-6">
                            <div className="input-group">
                                <div className="custom-file">
                                    <input type="file" name="file" className="custom-file-input" id="inputGroupFile" required onChange={handleImport}
                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
                                    <label className="custom-file-label" htmlFor="inputGroupFile">Choose file</label>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="col-md-6">
                            <button onClick={handleExport} className="btn btn-primary float-right">
                                Export <i className="fa fa-download"></i>
                            </button>
                        </div> */}
                        <div className="blog-details__content">
                        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(`${n.details}`)}} />
                         {/*{n.details} */}
                        <div/>
                        </div>
                        <div className="blog-card__meta">
                            {/* <Link to="#"><i className="far fa-user-circle"></i> by {n.user}</Link> */}
                            {/* <Link to="/newsdetails"><i className="far fa-comments"></i> 2 Comments</Link> */}
                        </div>
                        </>
                        )) : <p>Loading.....</p>
                    }

                    </div>
                    <div className="col-lg-4">
                        <div className="blog-sidebar">
                            <div className="blog-sidebar__item blog-sidebar__item--search">
                                <form action="/" className="blog-sidebar__search">
                                    <input type="search" placeholder="Search" />
                                    <button type="submit" className="blog-sidebar__search__btn">
                                        <i className="icon-magnifying-glass"></i>
                                    </button>
                                </form>
                            </div>
                            <div className="blog-sidebar__item blog-sidebar__item--posts">
                                <h3 className="blog-sidebar__title">Recent Posts</h3>
                                <ul className="list-unstyled blog-sidebar__post">
                                    
                                    {
                                  newsd ? newsd.map((d, index) => (
                                
                                    <li className="blog-sidebar__post__item">
                                        <div className="blog-sidebar__post__image">
                                            <img src={`../${d.image}`} height="30" width="45" alt="" />
                                        </div>
                                        <div className="blog-sidebar__post__content">
                                            <h3 className="blog-sidebar__post__title"><Link to={`/newsdetails/${d.id}`}>{d.title}</Link></h3>
                                       
                                               <div className="datenews"><i className="far fa-calendar"></i>{formatDate(d.date_last_modified)}
                                               </div> 
                                        </div>
                                    </li>
                                    
                                    )) : <p>Loading.....</p>
                                }
                                
                                    
                                </ul>
                            </div>
                            {/* <div className="blog-sidebar__item blog-sidebar__item--categories">
                                <h3 className="blog-sidebar__title">Site Map</h3>
                                <ul className="list-unstyled blog-sidebar__categories">
                                {
                                link ? link.map((l, index) => (
                                    <>
                                <li key={l.id}>
                                <Link to={`/mpfdetails/${l.id}`}>{l.title}</Link>
                                </li>
                                </>
                                    )) : <p>loading...</p>
                                }
                                </ul>
                            </div> */}
                            <div className="blog-sidebar__item blog-sidebar__item--tags">
                                <h3 className="blog-sidebar__title">Core links</h3>
                                <ul className="list-unstyled blog-sidebar__tags">
                                    <li>
                                        <Link to="/MonetaryPolicy">Monetary Policy</Link>
                                    </li>
                                    <li>
                                        <Link to="/FinancialEducation">Financial Inclusion and Education</Link>
                                    </li>
                                    <li>
                                        <Link to="/FinancialStability">Financial Stability</Link>
                                    </li>
                                    <li>
                                        <Link to="/Statistics">Statistics</Link>
                                    </li>
                                    <li>
                                        <Link to="/FinancialMarket">Financial Market</Link>
                                    </li>
                                    <li>
                                        <Link to="/LawsRegulations">Laws & Regulations</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link>
      <Footer/>
    </>
  )
}