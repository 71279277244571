import BGS1 from '../../assets/images/services/service-s-1-1.png'
import React, { useState, useEffect }   from 'react'
import { useSearchParams } from 'react-router-dom';
import axios from 'axios'
import SearchLinks from '../Home/Search';
import { Link } from 'react-router-dom'

const Searchbar = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [localSearch, setLocalSearch] = useState('');
  
    const [checked, setChecked] = useState({
      name: '',
      isChecked: false
    })
  
    const [results, setResults] = useState({
      isLoading: false,
      error: {
        isError: false,
        message: ""
      },
      data: null
    });
  
    const fetchResults = async (name) => {
      setResults(() => {
        return {
          isLoading: true,
          error: {
            isError: false,
            message: ''
          },
          data: null
        }
      })
  
      axios.get(`/${name}`)
        .then((response) => {
          let postData = response.data
          console.log(postData.length, 'we are printing the length')
          setResults(() => {
            return {
              isLoading: false,
              error: {
                isError: false,
                message: ''
              },
              data: postData
            }
          })
          // setPost(postData)
        })
        .catch((error) => {
          setResults(() => {
            return {
              isLoading: false,
              error: {
                isError: true,
                message: error
              },
              data: null
            }
          })
        })
    }
  
    useEffect(() => {
      fetchResults(searchParams.get("q").replace(" ", ""));
    }, [searchParams])
    const sections = [
        {
          name: "Laws and Regulations",
          url: "lawsregulations"
        },
        {
          name: "Monetary Policy",
          url: "monetarypolicy"
        },
        {
          name: "Market conduct",
          url: "marketconduct"
        },
        {
          name: "Sustainable Finance",
          url: "sustainablefinance"
        },
        {
          name: "Financial Stability",
          url: "financialstability"
        },
        {
          name: "Financial Inclusion",
          url: "financialeducation"
        },
        {
          name: "Financial Markets",
          url: "financialmarket"
        }
      ]
    // const [link, setPost] = useState()
    // useEffect(() => {
    //     axios.get('http://localhost:5001/marketconduct')
    //         .then((response) => {
    //             let postData = response.data
    //             console.log(postData);
    //             setPost(postData)
    //         })
    //         .catch((error) => console.log(error))
    // }, [])


  return (

<div className="blog-sidebar">
                            <div className="blog-sidebar__item blog-sidebar__item--search">
                                {/* <form action="/" className="blog-sidebar__search">
                                <input type="text" name="search" id="search" className='flex-grow border border-gray-300 p-2 rounded-md mr-3 outline-none' placeholder='Search...' onChange={(e) => {
                                        setLocalSearch(e.target.value);
                                    }} />
                                    <button
                                        type="button"
                                        disabled={localSearch.length === 0}
                                        className="blog-sidebar__search__btn" onClick={() => {
                                        console.log(localSearch, 'the local search')
                                        setSearchParams(`q=${localSearch}`);
                                        }}><i className="icon-magnifying-glass"></i>
                                    </button>
                                </form> */}
                  <form className="blog-sidebar__search" action="#" onSubmit={e =>
                    e.preventDefault()}>
                <label htmlFor="search" className="sr-only">Search ...</label>
                <SearchLinks />
                <button type="submit" aria-label="search submit" className="blog-sidebar__search__btn">
                    <i className="icon-magnifying-glass"></i>
                </button>
            </form>
                            </div>
                            <div className="blog-sidebar__item blog-sidebar__item--posts">
                            <h3 className="blog-sidebar__title">Sections</h3>
                            {/* <span className='text-2xl mb-4'>Sections</span> */}
                                {
                                    sections?.map((el, ind) => {
                                    return (
                                        <div className='flex space-x-2 w-full'>
                                        <input 
                                            type="checkbox" 
                                            name={el?.name} 
                                            checked={checked.name === el?.name && checked?.isChecked}
                                            id={el?.name} onChange={(e) => {
                                            setChecked({
                                                name: el?.name,
                                                isChecked: e.target.checked
                                            })
                                            setSearchParams(`q=${el?.url}`);
                                        }} />
                                          {" "} <span className="blog-sidebar__post__title">{el?.name}</span>
                                        </div>
                                    )
                                    })
                                }
                            </div>
                            {/* <div className="blog-sidebar__item blog-sidebar__item--tags">
                                <h3 className="blog-sidebar__title">Core links</h3>
                                <ul className="list-unstyled blog-sidebar__tags">
                                    <li>
                                        <Link to="/MonetaryPolicy">Monetary Policy</Link>
                                    </li>
                                    <li>
                                        <Link to="/FinancialStability">Financial Stability</Link>
                                    </li>
                                    <li>
                                        <Link to="/FinancialEducation">Financial Inclusion</Link>
                                    </li>
                                    <li>
                                        <Link to="/MarketConduct">Market Conduct</Link>
                                    </li>
                                    <li>
                                        <Link to="/SustainableFinance">Sustainable Finance</Link>
                                    </li>
                                    <li>
                                        <Link to="/Statistics">Statistics</Link>
                                    </li>
                                    <li>
                                        <Link to="/FinancialMarket">Financial Market</Link>
                                    </li>
                                    <li>
                                        <Link to="/LawsRegulations">Laws & Regulations</Link>
                                    </li>
                                   
                                </ul>
                            </div> */}
                        </div>
  )
}
export default Searchbar