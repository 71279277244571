import BGPH1 from '../../assets/images/backgrounds/bnrimage.jpeg'
import ABOUTIMG from '../../assets/images/resources/about.png'
import { Link } from 'react-router-dom'
import React, { useRef, useState, useEffect } from "react";

import {
    MDBTabsContent,
    MDBTabsPane
  } from 'mdb-react-ui-kit';
import Footer from '../Footer/Main';
import Navbar from '../Navbar/Main';

const Aboutus = () => {
  return (
    <>
<Navbar/>
      <div className="stricky-header stricked-menu main-menu">
        <div className="sticky-header__content"></div>
      </div>
      <section className="page-header">
        <div className="page-header__bg"   ></div>

        <div className="container">
                <ul className="thm-breadcrumb list-unstyled">
                    <li><Link to="/">Home</Link></li>
                    <li><span>About us</span></li>
                </ul>
            </div>
      </section>
      <section className="service-five pt-50 pb-50">
      <div className="container">
      <div className="row row-gutter-y-0 service-four__inner">
            <div className="col-lg-6">
              <div className="about-four__content">
                <div className="block-title text-left">
                  <p className="block-title__tagline">About the National Bank of Rwanda</p>
                </div>
                {/* <div className="about-four__box">
                  <div className="about-four__box__icon">
                    <i className="icon-bank"></i>
                  </div>
                  <div className="about-four__box__content">
                    <h3 className="about-four__box__title">
                      Toward a World Class Central Bank.
                    </h3>
                  </div>
                </div> */}
                <p className="about-four__text">
                The National Bank of Rwanda (NBR) was established in 1964 with the aim of issuing the Rwandan 
                currency named Franc Rwandais (Frw). Over the years, the role of the NBR has evolved. 
                The current Law N°48/2017 of 23/09/2017 as amended to date, confers a clear mandate on the NBR with 
                a mission of ensuring price stability and a sound financial system. Price stability is achieved by 
                conducting appropriate monetary policy in the interest of a stable macroeconomic environment, while financial 
                stability is achieved by regulating and supervising the financial system. 
                </p>
                <div className="block-title text-left">
                  <p className="block-title__tagline">NBR IDENTITY STATEMENT</p>
                </div>
                <p className="about-four__text">
                The National Bank of Rwanda strives to be a world class central bank contributing to 
                economic growth & development, by using robust monetary policy tools to maintain stable market prices. 
                The Bank ensures financial stability in a free market economy as it embraces innovation, 
                inclusiveness, and economic integration. 
                </p>
{/*              
                <div className="about-four__btns">
                  <Link to="/about" className="thm-btn thm-btn--dark-hover">
                    Discover More
                  </Link>
                </div> */}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-four__image">
              
                <img src="../images/aboutus.png" alt="" />
                {/* <div className="about-four__image__caption">
                  <h3 className="about-four__image__caption__year">
                    {yrs}<i>+</i>
                  </h3>
                  <p className="about-four__image__caption__text">
                    Years Experience
                  </p>
                </div> */}
                
              </div>
            </div>
          </div>
          </div>

            <p><br></br></p>

        </section>

        <section className="contact-info-one">
            <div className="container">
                <div className="row">
                    <div className="col-md-4 col-sm-12">
                        <div className="contact-info-one__item">
                            <div className="contact-info-one__icon">
                                <i className="icon-telephone"></i>
                            </div>
                            <div className="contact-info-one__content">
                                <p className="contact-info-one__text">For inquiries:</p>
                                <a className="contact-info-one__link" href="tel:+ (250) 788199000 ">+ (250) 788199000 </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <div className="contact-info-one__item">
                            <div className="contact-info-one__icon">
                                <i className="icon-email"></i>
                            </div>
                            <div className="contact-info-one__content">
                                <p className="contact-info-one__text">Send us an email on:</p>
                                <a className="contact-info-one__link" href="mailto:info@bnr.rw">info@bnr.rw</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <div className="contact-info-one__item">
                            <div className="contact-info-one__icon">
                                <i className="icon-pin"></i>
                            </div>
                            <div className="contact-info-one__content">
                                <p className="contact-info-one__text">HQ Address:</p>
                                <a className="contact-info-one__link" href="#"> KN 6 AV 4, Kigali Rwanda</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
{/* 

        <div className="google-map__default">
            <iframe title="template google map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1020806.7305464278!2d29.319977754475655!3d-1.9421909721377053!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19c29654e73840e3%3A0x7490b026cbcca103!2sRwanda!5e0!3m2!1sen!2sin!4v1540289964660" className="map__default" allowFullscreen></iframe>
        </div> */}
      <Link to="/contact" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-comments"></i></Link >

<Footer/>
    </>
  )
}

export default Aboutus