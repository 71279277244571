import BGPH1 from '../../../assets/images/backgrounds/page-header-bg-1-1.jpg'
import { Link } from 'react-router-dom'
import React, { useRef, useState, useEffect } from "react";
import AnimatedNumber from "animated-number-react"
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import axios from 'axios'
import {
    MDBTabsContent,
    MDBTabsPane
  } from 'mdb-react-ui-kit';
import Footer from '../FooterKinya/Main';
import Navbar from '../NavbarKinya/Main';

const About = () => {
    const [iconsActive, setIconsActive] = useState('tab1');

    const handleIconsClick = (value: string) => {
      if (value === iconsActive) {
        return;
      }
      setIconsActive(value);
    };
  
    const [board, setPost] = useState()
    useEffect(() => {
        axios.get('/board')
            .then((response) => {
                let boardData = response.data
                console.log(boardData);
                setPost(boardData)
            })
            .catch((error) => console.log(error))
    }, [])

      
    const [senior, setSenior] = useState()
    useEffect(() => {
        axios.get('/senior')
            .then((response) => {
                let seniorData = response.data
                console.log(seniorData);
                setSenior(seniorData)
            })
            .catch((error) => console.log(error))
    }, [])


      
    const [empyear, setEmpyear] = useState()
    useEffect(() => {
        axios.get('/empyear')
            .then((response) => {
                let empyearData = response.data
                console.log(empyearData);
                setEmpyear(empyearData)
            })
            .catch((error) => console.log(error))
    }, [])


    const yrs = (new Date().getFullYear()) - (1964);

  return (
    <>
<Navbar/>
      <div className="stricky-header stricked-menu main-menu">
        <div className="sticky-header__content"></div>
      </div>
      <section className="page-header">
        <div className="page-header__bg"    ></div>

        <div className="container">
          <ul className="thm-breadcrumb list-unstyled">
            <li><Link to="/">Home</Link ></li>
            <li><span>About</span></li>
          </ul>
          <h2>About Us</h2>
        </div>
      </section>
      <section className="service-five pt-50 pb-50">
      <div className="container">
      <div className="row row-gutter-y-50 service-four__inner">
            <div className="col-lg-6">
              <div className="about-four__content">
                <div className="block-title text-left">
                  <p className="block-title__tagline">About Central Bank of Rwanda</p>
                </div>
                <div className="about-four__box">
                  <div className="about-four__box__icon">
                    <i className="icon-bank"></i>
                  </div>
                  <div className="about-four__box__content">
                    <h3 className="about-four__box__title">
                      Toward a World Class Central Bank.
                    </h3>
                  </div>
                </div>
                <p className="about-four__text">
                The National Bank of Rwanda strives to be Worls Class Central Bank 
                that Contributes to economic growth and development, 
                by using robust monetary policy tools to maintain stable market prices. 
                The bank ensures financial stability in free market economy
                as it embraces innovations, diversity, inclusiveness and economic 
                integration
                </p>

                <div className="row row-gutter-y-20">
                  <div className="col-md-6">
                    <div className="about-four__feature">
                      <div className="about-four__feature__content">
                        <div className="about-four__feature__icon">
                          <i className="icon-confirmation"></i>
                        </div>
                        <h3 className="about-four__feature__title">
                        Integrity
                        </h3>
                      </div>
                      <div className="about-four__feature__content">
                        <div className="about-four__feature__icon">
                          <i className="icon-confirmation"></i>
                        </div>
                        <h3 className="about-four__feature__title">
                        Accountability
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="about-four__feature">
                      <div className="about-four__feature__content">
                        <div className="about-four__feature__icon">
                          <i className="icon-confirmation"></i>
                        </div>
                        <h3 className="about-four__feature__title">
                        Excellence
                        </h3>
                      </div>
                      <div className="about-four__feature__content">
                        <div className="about-four__feature__icon">
                          <i className="icon-confirmation"></i>
                        </div>
                        <h3 className="about-four__feature__title">
                        Mutual Respect and Team-work
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="about-four__btns">
                  <Link to="/about" className="thm-btn thm-btn--dark-hover">
                    Discover More
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-four__image">
                <img src="../assets/images/resources/about-4-1.png" alt="" />
                <div className="about-four__image__caption">
                  <h3 className="about-four__image__caption__year">
                    {yrs}<i>+</i>
                  </h3>
                  <p className="about-four__image__caption__text">
                    Years Experience
                  </p>
                </div>
                
              </div>
            </div>
          </div>
          </div>

      <p><br/></p>
        <div className="container">
          {/* <div className="block-title text-center"> */}
          <div className="row row-gutter-y-10 service-four__inner benefit-two__tab tabs-box" >
                    <ul className="list-unstyled benefit-two__tab__title1 tab-btns tab-buttons left">
                                    <li className="tab-btn" onClick={() => handleIconsClick('tab1')} active={iconsActive === 'tab1'}>
                                        <span>Boad members</span>
                                    </li>
                                    <li className="tab-btn" onClick={() => handleIconsClick('tab2')} active={iconsActive === 'tab2'}>
                                        <span>Executive Committee Members</span>
                                    </li>
                                    <li className="tab-btn" onClick={() => handleIconsClick('tab3')} active={iconsActive === 'tab3'}>
                                        <span>Best Employee of the Year</span>
                                    </li>
                                </ul>
                                
      <MDBTabsContent>
      <MDBTabsPane show={iconsActive === 'tab1'}>
      <div className="row row-gutter-y-30 text-center contact-info-one">
        
        <p className="block-title__tagline text-center">Meet BNR Boad members</p>
            
        <p></p>
        {
                      board ? board.map((b, index) => (
                                    <>
          <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">
              <div className="team-card">
             
                  <div className="team-card__image">
                  <Link to= {`/teamdetails/${b.id}`}><img src={`../${b.image}`} alt="" /></Link>
                      <div className="team-card__social">
                          <Link to="#"><i className="fab fa-twitter"></i></Link>
                          <Link to="#"><i className="fab fa-facebook"></i></Link>
                          <Link to="#"><i className="fab fa-pinterest"></i></Link>
                          <Link to="#"><i className="fab fa-instagram"></i></Link>
                      </div>
                  </div>
                  <div className="team-card__content">
                      <div className="team-card__content__inner">
                          <h3 className="team-card__title"><Link to= {`/teamdetails/${b.id}`}>{b.name}</Link></h3>
                          <p className="team-card__designation">{b.role}</p>
                      </div>
                  </div>
              </div>
          </div>
                         </> 
                           
                           )) : <p>...</p>
                       }
          
      </div>
</MDBTabsPane>
        <MDBTabsPane show={iconsActive === 'tab2'}>
            
            <div className="row row-gutter-y-30 text-center contact-info-one">
               
           <p className="block-title__tagline text-center">Meet the senior members</p>
           {
                      senior ? senior.map((b, index) => (
                                    <>
          <div className="col-lg-6 col-md-12 col-sm-12 wow fadeInUp text-center" data-wow-duration="1500ms" data-wow-delay="000ms">
              <div className="team-card">
             
                  <div className="team-card__image">
                  <Link to= {`/teamdetails/${b.id}`}><img src={`../${b.image}`} height="450" width="300" alt="" /></Link>
                      <div className="team-card__social">
                          <Link to="#"><i className="fab fa-twitter"></i></Link>
                          <Link to="#"><i className="fab fa-facebook"></i></Link>
                          <Link to="#"><i className="fab fa-pinterest"></i></Link>
                          <Link to="#"><i className="fab fa-instagram"></i></Link>
                      </div>
                  </div>
                  <div className="team-card__content">
                      <div className="team-card__content__inner">
                          <h3 className="team-card__title"><Link to= {`/teamdetails/${b.id}`}>{b.name}</Link></h3>
                          <p className="team-card__designation">{b.role}</p>
                      </div>
                  </div>
              </div>
          </div>
                         </> 
                           
                           )) : <p>loading...</p>
                       }
             
    
         </div>

        </MDBTabsPane>
        <MDBTabsPane show={iconsActive === 'tab3'}>
        <div className="container">
        <div className="row row-gutter-y-30 contact-info-one">
        <p className="block-title__tagline text-center"> Best Employee of the Year</p>
        {
                      empyear ? empyear.map((b, index) => (
                                    <>
                    <div className="col-lg-6">
                        
      <p><br/></p>
                        <div className="team-details__image wow text-center fadeInUp" data-wow-duration="1500ms">
                        <img src={`../${b.image}`} height="600" width="450" alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="team-details__content">
                            <div className="team-details__floated">{b.name}</div>
                            <div className="block-title text-left">
                                {/* <p className="block-title__tagline">{b.role}</p> */}
                                <h2 className="block-title__title">{b.name}</h2>
                            </div>
                            <div className="team-details__social">
                                <Link to="#"><i className="fab fa-twitter"></i></Link>
                                <Link to="#"><i className="fab fa-facebook"></i></Link>
                                <Link to="#"><i className="fab fa-pinterest"></i></Link>
                                <Link to="#"><i className="fab fa-instagram"></i></Link>
                            </div>
                            <p className="team-details__highlight">Currently {b.role}</p>
                            
                            <p className="team-details__text">{b.description} </p>
                        </div>
                    </div>
                    </> 
                           
                           )) : <p>loading...</p>
                       }
                </div>
            </div>

        </MDBTabsPane>
        </MDBTabsContent>
           </div>
          {/* </div> */}
        </div><p><br></br></p>

        </section>

        <section className="contact-info-one">
            <div className="container">
                <div className="row">
                    <div className="col-md-4 col-sm-12">
                        <div className="contact-info-one__item">
                            <div className="contact-info-one__icon">
                                <i className="icon-telephone"></i>
                            </div>
                            <div className="contact-info-one__content">
                                <p className="contact-info-one__text">Have any question?</p>
                                <a className="contact-info-one__link" href="tel:+ (250) 788199000 ">+ (250) 788199000 </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <div className="contact-info-one__item">
                            <div className="contact-info-one__icon">
                                <i className="icon-email"></i>
                            </div>
                            <div className="contact-info-one__content">
                                <p className="contact-info-one__text">Write us email</p>
                                <a className="contact-info-one__link" href="mailto:info@bnr.rw">info@bnr.rw</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <div className="contact-info-one__item">
                            <div className="contact-info-one__icon">
                                <i className="icon-pin"></i>
                            </div>
                            <div className="contact-info-one__content">
                                <p className="contact-info-one__text">Visit anytime</p>
                                <a className="contact-info-one__link" href="#"> KN 6 AV 4, Kigali Rwanda</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <div className="google-map__default">
            <iframe title="template google map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4562.753041141002!2d-118.80123790098536!3d34.152323469614075!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80e82469c2162619%3A0xba03efb7998eef6d!2sCostco+Wholesale!5e0!3m2!1sbn!2sbd!4v1562518641290!5m2!1sbn!2sbd" className="map__default" allowFullscreen></iframe>
        </div>
      <Link to="/contact" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-comments"></i></Link >

<Footer/>
    </>
  )
}

export default About