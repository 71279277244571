import BGPH1 from '../../assets/images/backgrounds/page-header-bg-1-1.jpg'
import logo from '../../assets/images/logo-dark.png'
import { Link } from 'react-router-dom'
import React, { useState, useEffect } from "react";
import axios from 'axios'
import Navbar from '../Navbar/Main';
import Footer from '../Footer/Main';

export default function Service() {
    //axios api to display monetary policy data
    const [post, setPost] = useState()
    useEffect(() => {
        axios.get('/financialeducation')
            .then((response) => {
                let postData = response.data
                console.log(postData);
                setPost(postData)
            })
            .catch((error) => console.log(error))
    }, [])


  return (
    <>
    <Navbar/>
        <div className="stricky-header stricked-menu main-menu">
            <div className="sticky-header__content"></div>
        </div>
        <section className="page-header">
            <div className="page-header__bg"    ></div>
            <div className="container">
                <ul className="thm-breadcrumb list-unstyled">
                    <li><Link to="/">Home</Link></li>
                    <li><span>Financial Inclusion and Education</span></li>
                </ul>   
                <div className="surrounding slider-one__title">
                The National Bank of Rwanda is a key player in Rwanda’s financial inclusion agenda. 
                The bank works hand in hand with other stakeholders to design and 
                implement strategies and policies aiming at advancing financial inclusion in Rwanda.  

                </div>
            </div>
        </section>
        <section className="service-five">
            <div className="container">
            <div className="row row-gutter-y-50 service-four__inner">    
               <div className="about-three__content"> 
                        <div className="block-title text-center">
                          <h2 className="block-title__tagline">Get to know about NBR Financial Inclusion and Education</h2>
                        </div>
                        <p className="about-three__text">Rwanda considers financial inclusion as an integral enabler for achieving its development and 
                        poverty reduction objectives. Rwanda targets achieving 90 percent formal financial inclusion by 2024.
                        The National Bank of Rwanda is a key player in Rwanda’s financial inclusion agenda. 
                        The bank works hand in hand with other stakeholders to design and implement strategies and policies aiming 
                        at advancing financial inclusion in Rwanda. </p>
                        {/* To achieve this, the Bank has set four strategic objectives:  </p>  
                        <ol className="about-three__text">
                        <li>Increasing access and usage of affordable financial services,</li>
                        <li>Improve efficiency in the provision of financial services,</li>
                        <li>Embracing innovation and digital payments in the financial sector,</li>
                        <li>Capacity building in the financial sector.</li>
                        </ol> */}

                </div>                
                {
                      post ? post.map((p, index) => (
                                    <>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="service-card">
                            <div className="service-card__image">
                                <img src={p.image} width="370" height="300" alt="" />
                                <Link to={`${p.link}`} > 
                                 </Link>
                            </div>
                            <div className="service-card__content">
                                <div className="service-card__content__inner">
                                <div className="service-card__icon">
                                        {/* <i className="icon-payment"></i><Link to={`${p.link}`} > </Link> */}
                                        <img src={logo} width="70" height="70" alt="BNR"/>
                                    </div>
                                    <h3 className="service-card__title">
                                    <Link to={`${p.link}`}>{p.title}</Link>
                                    </h3>
                                    <p className="service-card__text">{p.summary}
                                    <Link to={`${p.link}`} className="service-card__link">
                                        <i className="fa fa-angle-right"></i>
                                    </Link>
                                    </p>
                                </div>
                            </div>
                        </div>
             
                </div>
                </> 
                           
                           )) : <p>loading...</p>
                       }
          
                 </div>
               </div>
        </section>
        <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link>
        <Footer/>
    </>
  )
}

