import logo from '../../assets/images/logo-dark.png'
import flag from '../../assets/images/shapes/flag.png'
import { Link, useLocation} from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import Chatbot from '../Chatbot'

const Navbar = () => {
    
    const [mobile, setmobile] = useState(false)
    const [search, setsearch] = useState(false)
    const [homeDrop, sethomeDrop] = useState(false)
    const [headerDrop, setheaderDrop] = useState(false)
    const [servicesDrop, setservicesDrop] = useState(false)
    const [featuresDrop, setfeaturesDrop] = useState(false)
    const [newsDrop, setnewsDrop] = useState(false)
    const [pagesDrop, setpagesDrop] = useState(false)
    const location = useLocation()
    const path = location.pathname
    const [menu, setmenu] = useState({})

    const activeMenu = () => {
        if(path === "/" || path === "/home-02" || path === "/kinya" ){
            setmenu({home:true})
        }else if(path === "/about" ){
            setmenu({about:true})
        }else if(path === "/MonetaryPolicy" || path === "/team" || path === "/teamdetails"  || path === "/creditaudit" || path === "/creditrepair" || path === "/careers" || path === "/faqs" ||  path === "/applynow"){
            setmenu({pages:true})
        }else if(path === "/exchangeRate" || path === "/personalloan" || path === "/loaneligibility" || path === "/homeloan" || path === "/homeloaneligibility" || path === "/compareEMI" || path === "/monthlyloan"){
            setmenu({features:true})
        }
        else if(path === "/newsmain" || path === "/newsdetails" ){
            setmenu({news:true})
        }
        else if(path === "/contact"){
            setmenu({contact:true})
        }else{
            setmenu({home:true})
        }
    }
    
    const [sticky, setSticky] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };
    }, []);

    useEffect(() => {
        window.scroll(0, 0)
        activeMenu()
    }, [path]);
    const isSticky = () => {
        const scrollTop = window.scrollY;
        scrollTop >= 141 ? setSticky(true) : setSticky(false);
    }

  return (
    <>
    <div className="page-wrapper-header">
        {/* <div className="topbar">
            <div className="container">
               
                <div className="topbar__links">
                    <Link to="#">NATIONAL BANK OF RWANDA</Link>
                </div>
                
                <div className="topbar__social">
                    <Link to="#"><i className="fab fa-twitter"></i></Link>
                    <Link to="#"><i className="fab fa-facebook"></i></Link>
                    <Link to="#"><i className="fab fa-pinterest"></i></Link>
                    <Link to="#"><i className="fab fa-instagram"></i></Link>
                </div>
            </div>
            <div className ="mandate">Rwanda's Central Bank mandated to ensure price stability and a sound financial system</div>
        </div> */}
        <nav className={`main-menu ${sticky && "stricky-header stricked-menu stricky-fixed"}`}>
                 
                  <div className="container">
                      <div className="row">
                      <div className="col-lg-5">
                       <Link to="/" className='title1'>National Bank of Rwanda</Link>
                      </div>
                      
                     <div className="col-lg-7">
                      <div className="main-menu__nav">
                     
                {/* <table width="100%">
                    <tr> */}
                      {/* <td width="30%"> */}
                      {/* <div style={{ backgroundImage: `url(assets/images/logo-dark.png)`}}></div> */}
                      {/* <Link to="/"><img src={logo} width="70" height="70"/></Link> */}
                      {/* </td> */}
                      {/* <td width="70%"> */}
                   
                    <ul className="main-menu__list">
                        {/* <li className={`dropdown ${menu.pages && "current"}`}>
                    
                            <Link to="/"><i className='fa fa-bars'></i></Link>
                           <ul>
                                <li><Link to="/">About</Link> </li>
                                <li className="dropdown">
                                    <Link to="#">Monetary Policy</Link>
                                    <ul>
                                        <li><Link to="/">Monetary Policy Framework</Link></li>
                                        <li><Link to="/">Monetary Policy Report</Link></li>
                                        <li><Link to="/">Monetary Policy Strategy</Link></li>
                                        <li><Link to="/">Monetary Policy Implementation</Link></li>
                                        <li><Link to="/">Monetary Policy Commettee</Link></li>
                                    </ul>
                                </li>
                                <li className="dropdown">
                                    <Link to="#">Financial Stability</Link>
                                    <ul>
                                        <li><Link to="/">Forex bureaus</Link></li>
                                        <li><Link to="/">Financial Consumer Protection</Link></li>
                                        <li><Link to="/">Bank Supervision</Link></li>
                                        <li><Link to="/">Payment System</Link></li>
                                        <li><Link to="/">Credit Information System</Link></li>
                                        <li><Link to="/">Microfinance Institutions</Link></li>
                                        <li><Link to="/">Insurance & Pension</Link></li>
                                        <li><Link to="/">Macro-prudential Policy Framework</Link></li>
                                        <li><Link to="/">Licenses & Other Accreditations granted by NBR</Link></li>
                                        <li><Link to="/">List of Accredited External Auditors for Financial Institutions</Link></li>
                                        <li><Link to="/">Licenced NDFIs</Link></li>
                                    </ul>
                                </li>  
                                <li className="dropdown">
                                    <Link to="#">Statistics</Link>
                                    <ul>
                                        <li><Link to="/">Payment System Statistics</Link></li>
                                        <li><Link to="/">Financial Sector Statistics</Link></li>
                                        <li><Link to="/">External Sector Statistics</Link></li>
                                        <li><Link to="/">Economic Statistics</Link></li>
                                        <li><Link to="/">Public Finance Statistics</Link></li>
                                        <li><Link to="/">Statistics on Financial Markets</Link></li>
                                        <li><Link to="/">Monetary Statistics</Link></li>
                                        <li><Link to="/">CSD statistics</Link></li>
                                        <li><Link to="/">Consumer Price Index</Link></li>
                                        <li><Link to="/">EDWH Web Portal</Link></li>
                                    </ul>
                                </li>
                                <li><Link to="/">Publications</Link></li>
                                <li><Link to="/">Laws and Regulations</Link></li>
                                <li><Link to="/">Financial Inclusion and Education</Link></li>
                                <li><Link to="/">Careers</Link></li>
                            </ul> 
                        </li> */}
{/*                
                        <li className={`about ${menu.about && "current"}`}>
                            <Link to="/about">About</Link>
                        </li>  */}
                        <li className={`dropdown ${menu.home && "current"}`}>
                           <Link to="#">About</Link>
                            
                            <ul>
                                <li><Link to="/aboutus">Get to know about us</Link> </li>
                                <li className="dropdown">
                                    <Link to="#">Our People</Link>
                                    <ul>
                                        <li><Link to="/Board">Board Members</Link></li>
                                        <li><Link to="/directors">Executive Directors</Link></li>
                                        <li><Link to="/employee">Employee of the year</Link></li>
                                    </ul>
                                </li>
                                <li><Link to="/contact">Contact Us</Link></li>
                            </ul> 
                        </li> 
                         <li className={`dropdown ${menu.services && "current" && "hover"}`} 
                        ><Link to="/MonetaryPolicy">Monetary Policy</Link>
                        </li>
                        <li className={`dropdown ${menu.services && "current" && "hover"}`} >
                            <Link to='https://webdash.bnr.rw/' target="_blank">Statistics</Link>
                        </li>
                        <li className={`dropdown ${menu.services && "current" && "hover"}`} 
                        ><Link to="/Publications">News and Publications</Link>
                            {/* <ul>
                                <li><Link to="/mpfdetails">Monetary Policy Framework</Link></li>
                                <li><Link to="/mpcdetails">Monetary Policy Committee</Link></li>
                                <li><Link to="/mpidetails">Monetary Policy Implementation</Link></li>
                                <li><Link to="/mprdetails">Monetary Policy Reports</Link></li>
                            </ul> */}
                        </li>
                        {/* <li className={`dropdown ${menu.pages && "current"}`}><Link to="#">Financial Stability</Link>
                            <ul>
                                <li><Link to="/team">Forex bureaus</Link></li>
                                <li><Link to="/teamdetails">Deposit Guarantee Fund</Link></li>
                                <li><Link to="/creditrepair">Bank Supervision</Link></li>
                                <li><Link to="/creditaudit">Payment System</Link></li>
                                <li><Link to="/careers">Credit Information System</Link></li>
                                <li><Link to="/faqs">Microfinance Institutions</Link></li>
                                <li><Link to="/applynow">Insurance & Pension</Link></li>
                                <li><Link to="/careers">Macro-prudential Policy Framework</Link></li>
                                <li><Link to="/faqs">Licenses & Other Accreditations granted by NBR</Link></li>
                                <li><Link to="/applynow">List of Accredited External Auditors for Financial Institutions</Link></li>
                                <li><Link to="/applynow">Licenced NDFIs</Link></li>
                            </ul>
                        </li> */}
                        {/* <li className={`dropdown ${menu.pages && "current"}`}><Link to="#">Statistics</Link>
                            <ul>
                                <li><Link to="/team">Team</Link></li>
                                <li><Link to="/teamdetails">Team Details</Link></li>
                                <li><Link to="/creditrepair">Credit Repair</Link></li>
                                <li><Link to="/creditaudit">Credit Audit</Link></li>
                                <li><Link to="/careers">Careers</Link></li>
                                <li><Link to="/faqs">Faqs</Link></li>
                                <li><Link to="/applynow">Apply Now</Link></li>
                            </ul>
                        </li> */}
                   
                        {/* <li className={`dropdown ${menu.news && "current"}`}><Link to="/"><img src={flag} width="40" height="20"/></Link>
                             <ul>
                                <li><Link to="/"><img src={flagen} width="40" height="20"/></Link></li>
                            </ul> 
                        </li>*/}
                        {/* <li className={`contact ${menu.contact && "current"}`}><Link to="/contact">Careers</Link></li> */}
                    </ul>
                </div>
</div>
                      </div>
                      {/* </td>
                    </tr>
                </table> */}
                   
               
                <div className="main-menu__right">
                    <Link to="#" onClick= {() => setmobile(true)} className="main-menu__toggler mobile-nav__toggler">
                        <i className="fa fa-bars"></i>
                    </Link>
                    {/* <Link to="#" onClick={() => setsearch(true)} className="main-menu__search search-toggler">
                        <i className="icon-magnifying-glass"></i>
                    </Link>
                    
                    <Link to="exchangeRate" className="thm-btn main-menu__btn">Exchange Rate</Link>
                    
                    <Link to="tel:926668880000" className="main-menu__contact">
                        <span className="main-menu__contact__icon">
                            <i className="icon-phone"></i>
                        </span>
                        <span className="main-menu__contact__text">
                            <strong>+ (250) 788199000 </strong>
                            Mon to Fri: 9 am to 6 pm
                        </span>
                    </Link> */}
                </div>
            </div>
        </nav>
    </div>

    
    {/* <div className={`search-popup ${search && "active"}`}>
     <div className="search-popup__overlay search-toggler" onClick={() => setsearch(false)}></div>
        <div className="search-popup__content">
            <form action="#">
                <label htmlFor="search" className="sr-only">search here</label>
                <input type="text" id="search" placeholder="Search Here..." />
                <button type="submit" aria-label="search submit" className="thm-btn">
                    <i className="icon-magnifying-glass"></i>
                </button>
            </form>
        </div>
    </div> */}

    
    <div className={`mobile-nav__wrapper ${mobile ? "expanded" : ""}`}>
            <div className="mobile-nav__overlay mobile-nav__toggler" onClick={() => setmobile(false)}></div>
            <div className="mobile-nav__content">
                <span className="mobile-nav__close mobile-nav__toggler" onClick={() => setmobile(false)}><i className="fa fa-times"></i></span>

                <div className="logo-box">
                    <Link to="/" aria-label="logo image"><img src="assets/images/logo-light.png" width="155" alt="" /></Link>
                </div>
              
                <div className="mobile-nav__container">
                    <ul className="main-menu__list">
                        <li className="current"> <Link to="/" onClick={() => setmobile(false)}>Home</Link></li>
                        <li className="dropdown">
                            <Link to="#" className={featuresDrop ? "expanded" : ""}>About
                                <button aria-label="dropdown toggler" className={featuresDrop ? "expanded" : ""} onClick={() => setfeaturesDrop(featuresDrop ? false : true)}>
                                    <i className="fa fa-angle-down"></i>
                                </button>
                            </Link>
                            {featuresDrop &&
                                <ul style={{ display: "block" }}>
                                    <li><Link to="/aboutus" onClick={() => setmobile(false)}>About us</Link></li>
                                    <li><Link to="/board" onClick={() => setmobile(false)}>Board Members</Link></li>
                                    <li><Link to="/directors" onClick={() => setmobile(false)}>Executive Directors</Link></li>
                                    <li><Link to="/employee" onClick={() => setmobile(false)}>Employee of the Year</Link></li>
                                    <li><Link to="/contact" onClick={() => setmobile(false)}>Contact Us</Link></li>
                                </ul>}
                        </li>
                       <li className="current"> 
			<Link to="/MonetaryPolicy" onClick={() => setmobile(false)}>Monetary Policy
			          <button>
					<i className="fa fa-angle-down"></i>
				</button>

			</Link>
			</li>

                          <li className="current"> 
			<Link to='https://webdash.bnr.rw/' onClick={() => setmobile(false)} target="_blank">Statistics
			          <button>
					<i className="fa fa-angle-down"></i>
				</button>

			</Link>
			</li>
                          <li className="current"> 
			<Link to="/Publications" onClick={() => setmobile(false)}>News and Publications
			          <button>
					<i className="fa fa-angle-down"></i>
				</button>

			</Link>
			</li>
                        {/* <li>
                            <Link to="/lawsandregulations" onClick={() => setmobile(false)}>Laws and Regulations</Link>
                        </li> */}
                    </ul>

                </div>
                

                <ul className="mobile-nav__contact list-unstyled">
                    <li>
                        <i className="icon-email"></i>
                        <Link to="mailto:info@bnr.rw">info@bnr.rw</Link>
                    </li>
                    <li>
                        <i className="icon-telephone"></i>
                        <Link to="tel:+250788199000">+ (250) 788199000 </Link>
                    </li>
                </ul>

                <div className="mobile-nav__social">
                    <Link to="https://twitter.com/CentralBankRw/" target="_blank"><i className="fab fa-twitter"></i></Link>
                    <Link to="https://www.facebook.com/CentralBankRw/" target="_blank"><i className="fab fa-facebook"></i></Link>
                    <Link to="http://www.youtube.com/channel/UCNL-AuMdkEhr_FnfeT0BKRQ" target="_blank"><i className="fab fa-youtube"></i></Link>
                    <Link to="#"><i className="fab fa-instagram"></i></Link>
                </div>

            </div>
          
        </div>
    
        <div>
              <Chatbot/>
            </div>
    </>
  )
}

export default Navbar