import BGPH1 from '../../assets/images/backgrounds/page-header-bg-1-1.jpg'
import { Link } from 'react-router-dom'
import React from 'react'
import Footer from '../Footer/Main'
import Navbar from '../Navbar/Main'

const Contact = () => {
  return (
    <>
    <Navbar/>
        <div className="stricky-header stricked-menu main-menu">
            <div className="sticky-header__content"></div>
        </div>
        <section className="page-header">
            <div className="page-header__bg"   ></div>
            <div className="container">
                <ul className="thm-breadcrumb list-unstyled">
                    <li><Link to="/">Home</Link></li>
                    <li><span>Contact</span></li>
                </ul>
            </div>
        </section>

        <section className="contact-info-one">
            <div className="container">
                <div className="row">
                    {/* <div className="col-md-4 col-sm-12">
                        <div className="contact-info-one__item">
                            <div className="contact-info-one__icon">
                                <i className="icon-telephone"></i>
                            </div>
                            <div className="contact-info-one__content">
                                <p className="contact-info-one__text">General enquiries</p>
                                <a className="contact-info-one__link" href="tel:+ (250) 788199000 ">+ (250) 788199000 </a>
                            </div>
                        </div>
                    </div> */}
                    <div className="col-md-4 col-sm-12">
                        <div className="contact-info-one__item">
                            <div className="contact-info-one__icon">
                                <i className="icon-email"></i>
                            </div>
                            <div className="contact-info-one__content">
                                <p className="contact-info-one__text">Media Enquiries</p>
                                <a className="contact-info-one__link" href="mailto:info@bnr.rw">communication@bnr.rw</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <div className="contact-info-one__item">
                            <div className="contact-info-one__icon">
                                <i className="fa fa-globe"></i>
                            </div>
                            <div className="contact-info-one__content">
                                <p className="contact-info-one__text">Access and Apply for Services</p>
                                <a className="contact-info-one__link" href="https://e-correspondence.bnr.rw/">https://e-correspondence.bnr.rw</a>
                            </div>
                        </div>
                    </div>
                      <div className="col-md-4 col-sm-12">
                        <div className="contact-info-one__item">
                            <div className="contact-info-one__icon">
                                <i className="fab fa-facebook-f"></i>
                            </div>
                            <div className="contact-info-one__content">
                                <p className="contact-info-one__text">Social media</p>
                                  <a className="contact-info-one__link map__default" href="https://twitter.com/CentralBankRw/" target="_blank">
                                       <i className="fab fa-twitter-square"></i></a>{" "}
                                  <a className="contact-info-one__link map__default" href="https://www.facebook.com/CentralBankRw/" target="_blank">
                                       <i className="fab fa-facebook-square"></i>
					</a>{" "}
                                  <a className="contact-info-one__link map__default" href="http://www.youtube.com/channel/UCNL-AuMdkEhr_FnfeT0BKRQ" target="_blank">
                                       <i className="fab fa-youtube-square"></i>
                                      </a>
                            </div>
                        </div>
                    </div> 
               
                  </div>
                       <div className="row">
                      <div className="col-md-12 col-sm-12">
                        <div className="compare-table_contact">
                                <table className="table table-striped table-hover">
                                  <thead>
                                      <th><i className="icon-bank"></i>Branch</th>
                                      <th><i className="icon-phone"></i>Contact Number</th>
                                      <th><i className="icon-pin"></i>Address</th>
                                  </thead>
                                  <tbody>
                                        <tr>
                                                <td>Head Office </td>
                                                <td>+(250) 788199000 </td>
                                                <td>KN 6 AV 4, Kigali Rwanda, P.O Box: 531 Kigali</td>
                                      </tr>
                                     <tr>
                                                <td>Rubavu Branch </td>
                                                <td>+(250) 788199102 </td>
                                                <td>Rubavu Town - Rubavu District</td>
                                      </tr>
                                      <tr>
                                                <td>Eastern Branch</td>
                                                <td>+(250) 788199504 </td>
                                                <td>Eastern Province - Rwamagana District ,P.O Box: 14 Rwamagana</td>
                                      </tr>
                                      <tr>
                                                <td>Northern Branch </td>
                                                <td>+(250) 788199605</td>
                                                <td>Northern Province - Musanze District, P.O Box: 127 Musanze </td>
                                      </tr>
                                      <tr>
                                                <td>Western Branch </td>
                                                <td>+(250) 788199400</td>
                                                <td>Western Province - Rusizi District ,P.O Box: 462 Rusizi </td>
                                      </tr>
                                      <tr>
                                                <td>Southern Branch </td>
                                                <td>+(250) 788199880</td>
                                                <td>Southern Province - Huye District ,P.O Box: 622 Huye </td>
                                      </tr>
                                      
                                    </tbody>
                                 </table>
                        </div>
                      </div>
                      </div>
            </div>
        </section>
{/* 
        <div className="google-map__default">
            <iframe title="template google map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4562.753041141002!2d-118.80123790098536!3d34.152323469614075!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80e82469c2162619%3A0xba03efb7998eef6d!2sCostco+Wholesale!5e0!3m2!1sbn!2sbd!4v1562518641290!5m2!1sbn!2sbd" className="map__default" allowfullscreen></iframe>
        </div> */}
        <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link>
    <Footer/>
    </>
  )
}

export default Contact