import BGPH1 from '../../assets/images/backgrounds/page-header-bg-1-1.jpg'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useParams } from 'react-router-dom'
import React, { useState, useEffect, useMemo }   from 'react'
import FSsidebar from './fssidebar'
import axios from 'axios'
//import { Container } from "react-bootstrap";
//import Header from "components/Header";
import { TableHeader, Pagination, Search } from '../DataTable';
import Navbar from '../Navbar/Main'
import Footer from '../Footer/Main'
import FSquery from './fsquery'
// import Service from '../Main'
// import onSubmitForm from '../Main'

export const Banksupervision = () => {
    

   const [doc, setDoc] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });

    const ITEMS_PER_PAGE = 10;

    const headers = [
        { name: "Date", field: "Date", sortable: false },
        { name: "Documents", field: "Documents", sortable: false },
    ];

   const [loading, setLoading] = useState(true);
   useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`/fsbs`);
        const docData = await response.json();
        setDoc(docData);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []); 
   const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    });
  };
  const docData = useMemo(() => {
    let computeddoc = doc;

    if (search) {
        computeddoc = computeddoc.filter(
                d =>
                d.date_last_modified.toLowerCase().includes(search.toLowerCase()) ||
                d.name.toLowerCase().includes(search.toLowerCase())
        );
    }

    setTotalItems(computeddoc.length);

    //Sorting doc
    if (sorting.field) {
        const reversed = sorting.order === "asc" ? 1 : -1;
        computeddoc = computeddoc.sort(
            (a, b) =>
                reversed * a[sorting.field].localeCompare(b[sorting.field])
        );
    }

    //Current Page slice
    return computeddoc.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
}, [doc, currentPage, search, sorting]);



        const [clicked, setClicked] = useState(0);

  return (
    <>
    <Navbar/>
        <div className="stricky-header stricked-menu main-menu">
            <div className="sticky-header__content"></div>
        </div>
        <section className="page-header">
            <div className="page-header__bg"    ></div>
            <div className="container">
                <ul className="thm-breadcrumb list-unstyled">
                    <li><Link to="/">Home</Link></li>
                    <li><span>Bank Supervision</span></li>
                </ul>
                <div className="surrounding slider-one__title">
                The National Bank of Rwanda is in charge of regulating and supervising banks. The current banking industry is composed of:
                    Commercial Banks, Development Bank and Microfinance Banks
                </div>
            </div>
        </section>
        {/* <div  className="about-four__shape"></div>
  <div  className="about-five__shape-2"></div> 
  <div  className="about-five__shape-1"></div>
  <div  className="about-five__shape-9"></div> 
  <div  className="about-five__shape-0"></div> */}
        <section className="service-details pt-30 pb-30">
            <div className="container">
                <div className="row row-gutter-y-50 contact-info-one">
                    <div className="col-lg-8">
                        {/* <div className="service-details__image">
                            <a href="/monetarypolicy" className="service-details__icon"><i className="fa fa-arrow-left"></i></a>
                        </div> */}
                        <div className="service-details__content">
                       
                            <div className="row row-gutter-y-30">
                                <div className="col-md-12">

                                
                 
                      {/* <img src={p.image} alt="" /> */}
                      <h3 className="service-details__title">Bank Supervision</h3>
                      {/* <img src="../../../assets/images/services/service-d-1-1.png" alt="" /> */}
                      {/* <img src= {`../${p.image}`} alt="" /> */}
                      
                            <p>The National Bank of Rwanda is in charge of supervision and regulation of Banks to ensure their safety, soundness, and compliance with applicable laws and regulations. 
                                This essential role supports financial stability and helps to maintain public confidence, protect consumers, and foster a robust banking sector. 
                                This mandate is achieved by licensing banks in accordance with the Law Governing Banks as well and its implementing Regulations which categorizes banks into:</p>
                                <p><ul>
                                    <li>Commercial Banks: These provide the full range of banking services such as deposit mobilization, lending, payments, foreign exchange, trade finance, financial management etc. and are required to hold minimum paid up capital of FRW 20 billion.</li>
                                    <li>Development Bank: These are non-deposit taking banks which mobilize funds primarily for the purpose of financing development projects and programs. The minimum paid up capital for this category of banks is FRW 50 billion.</li>
                                    <li>Cooperative Banks: These are formed under the Cooperatives Law and whose activities are restricted to members of the bank. The minimum paid up capital requirement for this category is FRW 10 billion.</li>
                                    <li>Mortgage banks: These grant mortgage loans and collect deposits for participation in these loans. The minimum paid up capital for such banks is FRW 10 billion.</li>
                                </ul></p>
                                <p>Our Supervisory Practices:</p>
                                <p><ul>
                                    <li>Offsite Surveillance: Conduct regular assessments of risks and vulnerabilities, continuously monitor banks’ financial health and performance to detect warning signs and take necessary actions to prevent or mitigate potential risks to financial stability.</li>
                                    <li>Onsite Inspections: Conduct regular onsite visits to banks to assess their compliance with legal and regulatory requirements and best practices as well as the effectiveness of their risk management systems and controls.</li>
                                    <li>Risk Based Supervision (RBS) Framework including the Supervisory Review and Evaluation Process (SREP): This is a structured, forward-looking process designed to determine key risks to which individual banks and the entire industry are exposed; 
                                        assess the risk management policies and practices that are used to mitigate risk; and focus supervisory resources based on the risk characteristics of the institution. </li>
                                    <li>Data collection: Banks regularly report to NBR through the Electronic Data Warehouse (EDWH) and these reports are reviewed in accordance with the RBS framework. 
                                        Both quantitative and qualitative indicators are used to assess risk and impact to inform supervisory actions and decision making for financial stability.</li>
                                    <li>E-Correspondence: NBR stakeholders access and apply for services, submit related requirements through a user friendly, paperless and interactive online system, and these are timely handled in accordance with the citizen charter and existing regulations.</li>
                                </ul></p>
                                {loading ? (
                
                <p></p>
                    ) : docData && docData.length > 0 ? (
                        <> 
                       <p className="service-details__text">The following are documents related to Bank Supervision</p>
                       <div className="loan-comparison__body" id="compare-emi-1">
                <div className="compare-table_doc table-responsive">
                        <div className="benefit-two__tab__doc2">
                            <ul className="list-unstyled">
                                <li className='row'> 
                                    <Search className="search" onSearch={value => { setSearch(value); setCurrentPage(1); }}/>
                                 <span><Link className='linkcolor' to="/banking">Link for Laws and Regulations</Link></span></li> 
                            </ul>
                        </div>
                  <table className="table">
                  <TableHeader
                            headers={headers}
                            onSorting={(field, order) =>
                                setSorting({ field, order })
                            }
                        />
                                <tbody>
                                   
                         {
                                           docData ? docData.map((d, index) => (
                                            
                                            <tr key="">
                                                <td>{formatDate(d.date_last_modified)}</td>
                                                <td>
                                                <div className="accrodion-grp service-details__accrodion" data-grp-name="service-details__accrodion-1">

                                        <div className={`accrodion  wow fadeInUp ${index === clicked && "active"}`} key={index} data-wow-delay="0ms">
                                            <div className="accrodion-title" onClick={() => setClicked(index)}>
                                                <h4>{d.name}</h4>
                                                <span className="accrodion-icon"></span>
                                            </div>
                                            {index === clicked && 
                                            <div className="accrodion-content">
                                                <div className="inner_doc" >
                                                    <p>{d.summary}</p>
                                                    <Link to={d.file} target="_blank">Download/View <i className="fa fa-file-pdf"></i></Link>
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                                </td>
                                            </tr>
                                     
                               )) : <tr key=""><td>Loading.....</td></tr>
                            }
                                </tbody>
                  </table>
                  <div className="col-md-4">
                            <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div>
                            </div>
                            </div>
                            </>  
                      ) : (
                        <p></p>
                      )}
                            </div>
                            </div>
                    </div>
                    </div>
                    
                    <div className="col-lg-4">
                    <FSsidebar/>
                    </div>
                    <FSquery/>
                </div>
            </div>
        </section>
        <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link>
        <Footer/>
    </>
  )
}
