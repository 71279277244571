import BGPH1 from '../../../assets/images/backgrounds/page-header-bg-1-1.jpg'
import { Link } from 'react-router-dom'
import React, { useRef, useState, useEffect, useMemo } from "react";
import axios from 'axios';
import Footer from '../FooterKinya/Main';
import Navbar from '../NavbarKinya/Main';

const Careers = () => {

    // const [post, setPost] = useState()
    const [calendar, setcalendar] = useState([]);
    // useEffect(() => {
    //     axios.get('/fmddocuments')
    //         .then((response) => {
    //             let postData = response.data
    //             console.log(postData);
    //             setPost(postData)
    //         })
    //         .catch((error) => console.log(error))
    // }, [])

    useEffect(() => {
        const getData = () => {
            fetch("/calendar")
                .then(response => response.json())
                .then(json => {
                    setcalendar(json);
                    console.log(json);
                });
        };
        getData();
      }, []);
      const formatDate1 = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });
      };

  return (
    <>
      <Navbar/>
      <div className="stricky-header stricked-menu main-menu">
        <div className="sticky-header__content"></div>
        </div>
        <section className="page-header">
            <div className="page-header__bg"    ></div>
           
            <div className="container">
                <ul className="thm-breadcrumb list-unstyled">
                    <li><Link to="/">Home</Link></li>
                    <li><span>Careers</span></li>
                </ul>
                <h2>Careers</h2>
            </div>
        </section>
        <section className="service-five pt-50 pb-50">
        <div className="container">
            <div className="row row-gutter-y-50 service-four__inner"> 
                       <div className="block-title text-center">
                          <h2 className="block-title__tagline">Event calendar</h2>
                        </div>
                        <div className="col-lg-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">
                        {calendar.map(cal => (
                                              <>
                        <div className="career-one__item">
                            <div className="career-one__icon">
                            <i class="fa fa-calendar-check" aria-hidden="true"></i>

                            </div>
                            <div className="career-one__content">
                                <h3 className="career-one__title"><Link to="/calendar">{cal.title}</Link></h3> 
                                The event will take place at <i className="icon-pin"></i> {cal.place},on date {formatDate1(cal.date)} at <i className="icon-hour"></i>{cal.start_time}


                            </div>
                            <div className="career-one__btns">
                                <Link to="/calendar" className="thm-btn">More Details</Link>
                            </div>
                        </div>
                        </>
                                      ))}
                    </div>
                </div>
                 
                 
            </div>
        </section>

        <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link>
        <Footer/>
    </>
  )
}

export default Careers