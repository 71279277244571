// import BGImg from "../../assets/images/backgrounds/slider-1-1.jpg";
// import BGImg2 from "../../assets/images/backgrounds/slider-1-7.jpg";
import BGPH1 from '../../../assets/images/backgrounds/page-header-bg-1-1.jpg'
import usd_img from '../../../assets/images/case/usd.png'
// import ugx_img from '../../assets/images/case/ugx.png'
import kes_img from '../../../assets/images/case/kes.png'
import bif_img from '../../../assets/images/case/bif.png'
import eur_img from '../../../assets/images/case/eur.png'
// import BGCase12 from '../../assets/images/case/case-1-2.png'
// import BGCase13 from '../../assets/images/case/case-1-3.png'
// import BGCase14 from '../../assets/images/case/case-1-4.png'
import OwlCarousel from "react-owl-carousel";
import { Link } from "react-router-dom";
//import { LineChart, ResponsiveContainer, Legend, Tooltip, Line, XAxis, YAxis, CartesianGrid } from 'recharts';
// import { read, utils, writeFile } from 'xlsx';
import AnimatedNumber from "animated-number-react";
import React, { useRef, useState, useEffect, useMemo } from "react";
// import { TableHeader, Pagination, Search } from '../../DataTable';
import Marquee from "react-fast-marquee";
import Navbar from "../NavbarKinya/Main";
import Footer from "../FooterKinya/Main";
import axios from 'axios'
// import {
//   MDBTabsContent,
//   MDBTabsPane
// } from 'mdb-react-ui-kit';
// import { Fonts } from "react-bootstrap-icons";

// import { LineChart, ResponsiveContainer, Legend, Tooltip, Line, XAxis, YAxis, CartesianGrid } from 'recharts';

const Main = () => {
  const [ytShow , setytShow] = useState (false)
  const activeRef = useRef(null);
  const [sticky, setSticky] = useState(false);
  const carouselRef = useRef()
  const [iconsActive, setIconsActive] = useState('tab1');

  const handleIconsClick = (value: string) => {
    if (value === iconsActive) {
      return;
    }
    setIconsActive(value);
  };

  const yrs = (new Date().getFullYear()) - (1964);
  
	useEffect(() => {
		window.addEventListener("scroll", isSticky);
		return () => {
			window.removeEventListener("scroll", isSticky);
		};
	}, []);

//   const handleExport = () => {
//     const headings = [[
//         'id',
//         'CCY',
//         'BUYING',
//         'SELLING',
//         'AVERAGE', 
//         'DATE'
//     ]];
//     const wb = utils.book_new();
//     const ws = utils.json_to_sheet([]);
//     utils.sheet_add_aoa(ws, headings);
//     utils.sheet_add_json(ws, post, { origin: 'A2', skipHeader: true });
//     utils.book_append_sheet(wb, ws, 'Report');
//     writeFile(wb, 'Exchange Rate.xlsx');
// }

	const isSticky = () => {
		const scrollTop = window.scrollY;
		scrollTop >= 250 ? setSticky(true) : setSticky(false);
	}
  //scroll to top
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Attach scroll event listener
  window.addEventListener('scroll', handleScroll);

  const sliderOptions = {
    loop: true,
    items: 1,
    navText: ["", ""],
    margin: 0,
    dots: false,
    nav: true,
    animateOut: "slideOutDown",
    animateIn: "fadeIn",
    smartSpeed: 1000,
    autoplay: true,
    autoplayTimeout: 7000,
    autoplayHoverPause: false,
  };
  const sliderFeature = {
    loop: true,
    items: 1,
    navText: ["<span class='slider-one__carousel__btn__left'></span>", "<span class='slider-one__carousel__btn__left'></span>"],
    margin: 30,
    dots: true,
    nav: true,
    animateOut: "slideOutDown",
    animateIn: "fadeIn",
    smartSpeed: 1000,
    autoplay: true,
    items: 1,
    smartSpeed: 700,
    responsive: {
        0: {
            margin: 0,
            items: 1
        },
        300: {
            margin: 30,
            items: 1
        },
        600: {
            margin: 30,
            items: 2
        },
        900: {
            margin: 30,
            items: 3
        }
    },
}
const sliderTestimonial = {
  loop: true,
  items: 1,
  navText: ["<span class='slider-one__carousel__btn__left'></span>", "<span class='slider-one__carousel__btn__left'></span>"],
  margin: 0,
  dots: true,
  nav: true,
  animateOut: "slideOutDown",
  animateIn: "fadeIn",
  smartSpeed: 1000,
  autoplay: true,
  autoplayTimeout: 7000,
  autoplayHoverPause: false
}

//exchange rate get method
const [doc, setDoc] = useState([]);
const [kes, setKes] = useState([]);
const [bif, setBif] = useState([]);
const [eur, setEur] = useState([]);
const [ugx, setUgx] = useState([]);
const [usd, setUsd] = useState([]);
const [update, setUpdate] = useState([]);
const [calendar, setcalendar] = useState([]);
const [totalItems, setTotalItems] = useState(0);
const [currentPage, setCurrentPage] = useState(1);
const [search, setSearch] = useState("");
const [sorting, setSorting] = useState({ field: "", order: "" });

const ITEMS_PER_PAGE = 7;
const ITEMS_PER_PAGE1 = 4;

const headers = [
    { name: "CCY", field: "ccy", sortable: false },
    { name: "Buying", field: "buying", sortable: true },
    { name: "Selling", field: "selling", sortable: true },
    { name: "Date", field: "date", sortable: false }
];

const headers1 = [
  { name: "Date", field: "date", sortable: false },
  { name: "Documents", field: "documents", sortable: false },
];
useEffect(() => {
  const getData = () => {
      fetch("/calendarlimit")
          .then(response => response.json())
          .then(json => {
              setcalendar(json);
              console.log(json);
          });
  };
  getData();
}, []);
const formatDate1 = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
  });
};

useEffect(() => {
  const getData = () => {
      fetch("/updates")
          .then(response => response.json())
          .then(json => {
              setUpdate(json);
              console.log(json);
          });
  };
  getData();
}, []);


//sliding currencies
useEffect(() => {
    const getData = () => {
        fetch("/usd")
            .then(response => response.json())
            .then(json => {
                setUsd(json);
                console.log(json);
            });
    };
    getData();
}, []);

useEffect(() => {
  const getData = () => {
      fetch("/bif")
          .then(response => response.json())
          .then(json => {
              setBif(json);
              console.log(json);
          });
  };
  getData();
}, []);

useEffect(() => {
  const getData = () => {
      fetch("/ugx")
          .then(response => response.json())
          .then(json => {
              setUgx(json);
              console.log(json);
          });
  };
  getData();
}, []);

useEffect(() => {
  const getData = () => {
      fetch("/eur")
          .then(response => response.json())
          .then(json => {
              setEur(json);
              console.log(json);
          });
  };
  getData();
}, []);

useEffect(() => {
  const getData = () => {
      fetch("/kes")
          .then(response => response.json())
          .then(json => {
              setKes(json);
              console.log(json);
          });
  };
  getData();
}, []);

const [mp, setMp] = useState()
useEffect(() => {
    axios.get('/monetaryPolicy')
        .then((response) => {
            let postData = response.data
            console.log(postData);
            setMp(postData)
        })
        .catch((error) => console.log(error))
}, [])

const [fe, setFe] = useState()
useEffect(() => {
    axios.get('/financialeducation')
        .then((response) => {
            let postData = response.data
            console.log(postData);
            setFe(postData)
        })
        .catch((error) => console.log(error))
}, [])

const [fs, setFs] = useState()
useEffect(() => {
    axios.get('/financialstability')
        .then((response) => {
            let postData = response.data
            console.log(postData);
            setFs(postData)
        })
        .catch((error) => console.log(error))
}, [])

const [stat, setStat] = useState()
useEffect(() => {
    axios.get('/statistics')
        .then((response) => {
            let postData = response.data
            console.log(postData);
            setStat(postData)
        })
        .catch((error) => console.log(error))
}, [])

const [fm, setFm] = useState()
useEffect(() => {
    axios.get('/financialmarket')
        .then((response) => {
            let postData = response.data
            console.log(postData);
            setFm(postData)
        })
        .catch((error) => console.log(error))
}, [])

const [lr, setLr] = useState()
useEffect(() => {
    axios.get('/lawsregulations')
        .then((response) => {
            let postData = response.data
            console.log(postData);
            setLr(postData)
        })
        .catch((error) => console.log(error))
}, [])


// const formatDate = (dateString) => {
//   const date = new Date(dateString);
//   return date.toLocaleDateString('en-US', {
//       year: 'numeric',
//       month: '2-digit',
//       day: '2-digit'
//   });
// };

// const currencyData = useMemo(() => {
//     let computedcurrency = currency;

//     if (search) {
//         computedcurrency = computedcurrency.filter(
//             comment =>
//                 comment.currency_name.toLowerCase().includes(search.toLowerCase()) ||
//                 comment.post_date.toLowerCase().includes(search.toLowerCase())
//         );
//     }

//     setTotalItems(computedcurrency.length);

    //Sorting currency
    // if (sorting.field) {
    //     const reversed = sorting.order === "asc" ? 1 : -1;
    //     computedcurrency = computedcurrency.sort(
    //         (a, b) =>
    //             reversed * a[sorting.field].localeCompare(b[sorting.field])
    //     );
    // }

    //Current Page slice
//     return computedcurrency.slice(
//         (currentPage - 1) * ITEMS_PER_PAGE,
//         (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
//     );
// }, [currency, currentPage, search, sorting]);



  // const [usd, setUSD] = useState()
  // useEffect(() => {
  //     axios.get('http://192.168.50.153:5000/usd')
  //         .then((response) => {
  //             let usdData = response.data
  //             console.log(usdData);
  //             setUSD(usdData)
  //         })
  //         .catch((error) => console.log(error))
  // }, [])

  //infration chart

  // const [post, setPost] = useState()
  // useEffect(() => {
  //     axios.get('/fmddocuments')
  //         .then((response) => {
  //             let postData = response.data
  //             console.log(postData);
  //             setPost(postData)
  //         })
  //         .catch((error) => console.log(error))
  // }, [])

  const [news, setNews] = useState()
  useEffect(() => {
      axios.get('/news')
          .then((response) => {
              let newsData = response.data
              console.log(newsData);
              setNews(newsData)
          })
          .catch((error) => console.log(error))
  }, [])


  const [newskey, setNewskey] = useState()
  useEffect(() => {
      axios.get('/newskey')
          .then((response) => {
              let newsData = response.data
              console.log(newsData);
              setNewskey(newsData)
          })
          .catch((error) => console.log(error))
  }, [])


  const [infradata, setInfradata] = useState()
  useEffect(() => {
      axios.get('/infration')
          .then((response) => {
              let postinfradata = response.data
              setInfradata(postinfradata)
          })
          .catch((error) => console.log(error))
  }, [])

  useEffect(() => {
    axios.get(`/alldocuments`)
        .then((response) => {
            let postData = response.data
            console.log(postData);
            setDoc(postData)
        })
        .catch((error) => console.log(error))
}, [])


const docData = useMemo(() => {
  let computeddoc = doc;

  if (search) {
      computeddoc = computeddoc.filter(
              d =>
              d.date_last_modified.toLowerCase().includes(search.toLowerCase()) ||
              d.name.toLowerCase().includes(search.toLowerCase())
      );
  }

  setTotalItems(computeddoc.length);

  if (sorting.field) {
    const reversed = sorting.order === "asc" ? 1 : -1;
    computeddoc = computeddoc.sort(
        (a, b) =>
            reversed * a[sorting.field].localeCompare(b[sorting.field])
    );
}
  
  //Current Page slice
  return computeddoc.slice(
      (currentPage - 1) * ITEMS_PER_PAGE1,
      (currentPage - 1) * ITEMS_PER_PAGE1 + ITEMS_PER_PAGE1
  );
}, [doc, currentPage, search, sorting]);

  return (
    <>
    <Navbar/>
      <div className="stricky-header stricked-menu main-menu">
        <div className="sticky-header__content"></div>
      </div>
      <section className="slider-one" >
        <OwlCarousel
          ref={carouselRef}
          className="thm-owl__carousel thm-owl__carousel--custom-nav owl-carousel owl-theme owl-dot-style-one"
          data-owl-nav-prev=".slider-one__carousel__btn__left"
          data-owl-nav-next=".slider-one__carousel__btn__right"
          {...sliderOptions}
        >
          <div className="item">
            <div className="slider-one__item">
              <div className="slider-one__lines">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div
                className="slider-one__image"
                style={{ backgroundImage: `url(${BGPH1})` }}
              ></div>

              <div className="container">
                <div className="search-popup__content">
                    <div className ="mandate1">
                        <p>
                        Banki nkuru y’u Rwanda ibereyeho kubungabunga ibiciro ngo bitajegajega ndetse na gahunda y’imari ihamye
                        </p>
                    </div>
            <form action="#">
                <label htmlFor="search" className="sr-only">Shakisha hano...</label>
                <input type="text" id="search" placeholder="Search here..." />
                <button type="submit" aria-label="search submit" className="thm-btn">
                    <i className="icon-magnifying-glass"></i>
                </button>
            </form>
        </div>
              </div>
            </div>
          </div>
        </OwlCarousel>
     
        <a href="">
        <div
          className="slider-one__box wow fadeInRight"
          data-wow-duration="1500ms"
        >
          <div className="slider-one__box__content">
            {
              
                    newskey ? newskey.map((nk, index) => (
                      <>
                   <h3 className="slider-one__box__title"> <Link className="slider-one__box__title" to={`/rw/newsdetails/${nk.id}`}>{nk.title} </Link> </h3>
                   </>
                )) : <p></p>
               
                                    }
        </div>
        </div>
        <div
          className="slider-one__box wow fadeInRight"
          data-wow-duration="1500ms"
        >    
        </div>
        </a>
        </section> 
        <section className="fact-one pt-20 pb-20">
        <div className="container">
          <div className="row row-gutter-y-30">
          {  update ? update.map((up, index) => (
                  <>
                
            <div className="col-lg-3 col-md-6">
            <br></br>
              <div className="fact-one__item">
                <div className="fact-one__count">
                  <span className="count-box">
                    <span
                      className="count-text"
                      data-stop={up.central_bank_rate}
                      data-speed="500"
                    >
                      <AnimatedNumber
                        value={up.central_bank_rate}
                        duration={50}
                        // formatValue={(v) => Math.round(v)}
                      />
                    </span>
                  </span>
                  %
                </div>
                <h3 className="fact-one__title">Igipimo cya Banki Nkuru</h3>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <br></br>
              <div className="fact-one__item">
                <div className="fact-one__count">
                  <span className="count-box">
                    <span
                      className="count-text"
                      data-stop={up.inflation_rate}
                      data-speed="500"
                    >
                      {" "}
                      <AnimatedNumber
                        value={up.inflation_rate}
                        duration={30}
                        // formatValue={(v) => Math.round(v)}
                      />
                    </span>
                  </span>
                  %
                </div>
                <h3 className="fact-one__title">Igipimo cy'ifaranga</h3>
              </div>
            </div>
                   </>
                     )) : <p>Loading.....</p>
                }
            <div className="col-lg-6 col-md-6">
              <div className="fact-one__item1">
              {/* <div className="testimonials-three__carousel"> */}
                                {/* <div className="testimonials-three__carousel__line"></div> */}
                                <OwlCarousel className="thm-owl__carousel owl-carousel owl-theme owl-dot-style-one" {...sliderTestimonial} data-owl-options='{
                                        "loop": true,
                                        "items": 1,
                                        "autoplay": true,
                                        "autoplayHoverPause": true,
                                        "autoplayTimeout": 5000,
                                        "smartSpeed": 500,
                                        "nav": false,
                                        "dots": true,
                                        "margin": 10,
                                        "responsive": {
                                          "0": {
                                            "items": 1
                                          },
                                          "768": {
                                            "items": 1
                                          },
                                          "992": {
                                            "items": 1
                                          }
                                        }
                                      }'>
                                      {
                                  news ? news.map((n, index) => (
                                        <div className="testimonial-card-two">
                                            <div className="testimonial-card-two__content">
                                            <div className="testimonial-card-two__text">
                                            <p className="career-one__title1">{n.title}</p>
                                                  {n.summary}
                                                  </div>
                                                    <Link to={`/rw/newsdetails/${n.id}`} className="blog-card__link1">Ibirambuye</Link>
                                                
                                            </div>
                                        </div>
                                            )) : <p>Loading.....</p>
                                          }
                                        
                                </OwlCarousel>
              </div>
            </div>
            
          </div>
        </div>
      </section>
      <section className="service-five pt-10 pb-30">
            <div className="container">
            <div className="block-title__tagline_ccy">
        <table>
          <tr>
            <td>
            <Marquee>
            |{  usd ? usd.map((us, index) => (
                  <>
                   <img src={usd_img} height="15"/>USD: Kugura: {us.buying_rate}, Kugurisha: {us.selling_rate}
                   </>
                     )) : <p>Loading.....</p>
                }
            |{  bif ? bif.map((bi, index) => (
                  <>
                   <img src={bif_img} height="15"/>BIF: Kugura: {bi.buying_rate}, Kugurisha: {bi.selling_rate}
                   </>
                     )) : <p>Loading.....</p>
                }
            |{  eur ? eur.map((eu, index) => (
                  <>
                   <img src={eur_img} height="15"/>EUR: Kugura: {eu.buying_rate}, Kugurisha: {eu.selling_rate}
                   </>
                     )) : <p>Loading.....</p>
                }
            |{  kes ? kes.map((ke, index) => (
                  <>
                   <img src={kes_img} height="15"/>KES: Kugura: {ke.buying_rate}, Kugurisha: {ke.selling_rate}
                   </>
                     )) : <p>Loading.....</p>
                }
            {/* |{  ugx ? ugx.map((ug, index) => (
                  <>
                   <img src={ugx_img} height="15"/>UGX: Buying: {ug.buying_rate} , Selling: {ug.selling_rate}
                   </>
                     )) : <p>Loading.....</p>
                } */}
             </Marquee>
            </td>
            <td>
            <div className="btn-wrap documentation-knowledge-design-2-btn-wrap">
              <Link to="/rw/exchangeRate" className="documentation-knowledge-btn">Ibindi</Link>
             </div>
             </td> 
          </tr>
          </table>
        </div>  
        </div>
        <p></p>
        <p></p>
        <div className="container">
                <div className="row row-gutter-y-30">
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">
                      <div className="documentation-box documentation-knowledge-design-2">
                          <div className="documentation-card-header">
                                   <h3><Link to="/rw/MonetaryPolicy">Politiki y'ifaranga</Link></h3>
                          </div>
                          <div className="documentation-content">
                            <ul>
                             {
                            mp ? mp.map((p, index) => (
                                    <>
                              <li><Link to={`rw/mpdetails/${p.id}`}><i className="fa fa-angle-right"></i> {p.title}</Link></li>
                              </> 
                           
                               )) : <p>loading...</p>
                             }
                            </ul>
                          </div>
                          <div className="btn-wrap documentation-knowledge-design-2-btn-wrap">
                            <a className="documentation-knowledge-btn" href="/rw/MonetaryPolicy">Ibindi bikurikira </a> 
                          </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">
                      <div className="documentation-box documentation-knowledge-design-2">
                          <div className="documentation-card-header">
                                   <h3><Link to="/rw/FinancialEducation">Ubumenyi ku mari kuri bose</Link></h3>
                          </div>
                          <div className="documentation-content">
                          <ul>
                             {
                            fe ? fe.map((p, index) => (
                                    <>
                              <li><Link to={`/rw/fedetails/${p.id}`}><i className="fa fa-angle-right"></i> {p.title}</Link></li>
                              </> 
                           
                               )) : <p>loading...</p>
                             }
                            </ul>
                          </div>
                          <div className="btn-wrap documentation-knowledge-design-2-btn-wrap">
                            <Link className="documentation-knowledge-btn" to="/rw/FinancialEducation">Ibindi bikurikira</Link> 
                          </div>
                      </div>
                    </div>
                    
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">
                      <div className="documentation-box documentation-knowledge-design-2">
                          <div className="documentation-card-header">
                                   <h3><Link to="/rw/FinancialStability">Urwogo rw'imari rutajegajega</Link></h3>
                          </div>
                          <div className="documentation-content">
                          <ul>
                             {
                            fs ? fs.map((p, index) => (
                                    <>
                              <li><Link to={`/rw/fsdetails/${p.id}`}><i className="fa fa-angle-right"></i> {p.title}</Link></li>
                              </> 
                           
                               )) : <p>loading...</p>
                             }
                            </ul>
                          </div>
                          <div className="btn-wrap documentation-knowledge-design-2-btn-wrap">
                            <Link className="documentation-knowledge-btn" to="/rw/FinancialStability">Ibindi bikurikira </Link> 
                          </div>
                      </div>
                    </div>   
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">
                      <div className="documentation-box documentation-knowledge-design-2">
                          <div className="documentation-card-header">
                                   <h3><Link to="/rw/Statistics">Ibarurishamibare</Link></h3>
                          </div>
                          <div className="documentation-content">
                          <ul>
                             {
                            stat ? stat.map((p, index) => (
                                    <>
                              <li><Link to={`/rw/statdetails/${p.id}`}><i className="fa fa-angle-right"></i> {p.title}</Link></li>
                              </> 
                           
                               )) : <p>loading...</p>
                             }
                            </ul>
                          </div>
                          <div className="btn-wrap documentation-knowledge-design-2-btn-wrap">
                            <Link className="documentation-knowledge-btn" to="/rw/Statistics">Ibindi bikurikira </Link> 
                          </div>
                      </div>
                    </div>     
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">
                      <div className="documentation-box documentation-knowledge-design-2">
                          <div className="documentation-card-header">
                                   <h3><Link to="/rw/financialmarket">Isoko ry'imari</Link></h3>
                          </div>
                          <div className="documentation-content">
                          <ul>
                             {
                            fm ? fm.map((p, index) => (
                                    <>
                              <li><Link to={`/rw/fmdetails/${p.id}`}><i className="fa fa-angle-right"></i> {p.title}</Link></li>
                              </> 
                           
                               )) : <p>loading...</p>
                             }
                            </ul>
                          </div>
                          <div className="btn-wrap documentation-knowledge-design-2-btn-wrap">
                            <Link className="documentation-knowledge-btn" to="/rw/financialmarket">Ibindi bikurikira </Link> 
                          </div>
                      </div>
                    </div>       
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">
                      <div className="documentation-box documentation-knowledge-design-2">
                          <div className="documentation-card-header">
                                   <h3><Link to="/rw/lawsregulations">Amategeko n'amabwiriza</Link></h3>
                          </div>
                          <div className="documentation-content">
                          <ul>
                             {
                            lr ? lr.map((p, index) => (
                                    <>
                              <li><Link to={`/rw/lrdetails/${p.id}`}><i className="fa fa-angle-right"></i> {p.title}</Link></li>
                              </> 
                           
                               )) : <p>loading...</p>
                             }
                            </ul>
                          </div>
                          <div className="btn-wrap documentation-knowledge-design-2-btn-wrap">
                            <Link className="documentation-knowledge-btn" to="/rw/lawsregulations">Ibindi bikurikira</Link> 
                          </div>
                      </div>
                    </div>
                 
                </div>
                <p></p>
            {/* <div className="container">
        <div className="row row-gutter-y-30 contact-info-one">
        <div className="block-title text-center">
                    <h6 className="block-title__tagline">Our latest news updates & articles</h6>
         </div>
         {
                    news ? news.map((n, index) => (
                                          
                    <div className="col-lg-4 col-md-12 col-sm-12">
                        <div className="blog-card">
                            <div className="blog-card__image">
                                <div className="blog-card__date">{formatDate(n.date_last_modified)}</div>
                                <img src={n.image} alt="" height="230" width="370"/>
                                <Link to="#"></Link>
                                
                            </div>
                            <div className="blog-card__content">
                               <p className="blog-card__text"><Link to="/newsdetails">{n.title} </Link></p>
                                <Link to="#" className="blog-card__link">Read More</Link>
                            </div>
                        </div>
                    </div>
                    
                    )) : <p>Loading.....</p>
                                    }

                    </div>
                  
                  </div> */}
            </div>
            <div className="container">
                                          
                <div className="row row-gutter-y-30">
                   
         {
                    news ? news.map((n, index) => (
                    <div className="col-lg-4 col-md-12">
                        <div className="blog-card-two">
                            <div className="blog-card-two__image" style={{ backgroundImage: `url(${n.image})`}}>
                            </div>
                            <div className="blog-card-two__content">
                                <h3 className="blog-card-two__title"><Link to={`/rw/newsdetails/${n.id}`}>{n.title} </Link></h3>
                            </div>
                        </div>
                    </div>
                
                )) : <p>Loading.....</p>
                                    }
                </div>
                {/* <div className="row">
                        <div className="col-lg-3">
                            <div className="blog-three__btns">
                                <Link to="newsdetails" className="thm-btn">View All News</Link>
                            </div>
                        </div>
                    </div> */}
            </div>
        </section>
            {/* <section className="service-four">
            <div className="container">
          
          <div className="service-four__inner1 wow fadeInUp" data-wow-duration="1500ms"> */}

                {/* <div className="row row-gutter-y-20">
            <div className="block-title text-center ">
                    <p className="block-title__tagline">Trending videos</p>
                </div>
                <div align ="center" className="col-lg-4 col-md-12">
                          <iframe
                               width="320"
                               height="180"
                              src={"https://www.youtube.com/embed/q9VA499zXnI"}
                              title="Trending video"
                              frameborder="0"
                              allowFullScreen
                            />
                    </div>
                    <div align ="center" className="col-lg-4 col-md-12">
                          <iframe
                              width="320"
                              height="180"
                              src={"https://www.youtube.com/embed/mlxaE5lN5Og"}
                              title="Trending video"
                              frameborder="0"
                              allowFullScreen
                            />
                    </div>
                    <div align ="center" className="col-lg-4 col-md-12">
                          <iframe
                              width="320"
                              height="180"
                              src={"https://www.youtube.com/embed/xjKxqVNyLLY"}
                              title="Trending video"
                              frameborder="0"
                              allowFullScreen
                            />
                    </div>
                </div>
             </div>
            </div> 
            
        </section>*/}
        
       
          {/* <section className="call-to-action-one call-to-action-one--has-bottom-margin">
            <div className="container">
            
                <div className="call-to-action-one__inner wow fadeInUp" data-wow-duration="1500ms">
               
              <form onSubmit={handleSubmit} >
              <div className="row row-gutter-y-20 row-gutter-x-180">
                              
                                    <div className="col-lg-6">
                                     <h3 className="call-to-action-one__title">How do you rate our services?</h3>
                                    </div>
                                    <div className="col-lg-4">
                                        
                                    <ul className="list-unstyled radiolist">
                                          <li>
                                            <input type="radio" name="flexRadioDefault" className="checker"></input>
                                                 Excellent
                                          </li>
                                          <li>
                                            <input type="radio" name="flexRadioDefault" className="checker" checked></input>
                                                 Very good
                                          </li>
                                          <li>
                                            <input type="radio" name="flexRadioDefault" className="checker"></input>
                                                 Good
                                          </li>
                                          
                                     </ul>  
                                         </div>
                                    <div className="col-lg-2">
                                        <button type="submit" className="thm-btn" disabled={isSubmitting}> 
                                        {isSubmitting ? 'Submitting...' : 'Submit'}</button>
                                        {isSubmitted && <p>Comment submitted successfully!</p>}
                                    </div>


                                </div>
                              
                            </form>
                </div>
            </div>
        </section> */}

        <button
      className={`scroll-to-top ${isVisible ? 'show' : 'hide'}`}
      onClick={scrollToTop}
    >
      <i className="fa fa-angle-up"></i>
    </button>

   <Footer/>  
    </>
  );
};

export default Main;
