import BGPH1 from '../../assets/images/backgrounds/page-header-bg-1-1.jpg'
import { Link } from 'react-router-dom'
import React, { useState, useEffect, useMemo}   from 'react'
import { TableHeader, Pagination, Search } from '../DataTable';
import axios from 'axios'
import Footer from '../Footer/Main'
import Navbar from '../Navbar/Main'

const Faqs = () => {

    const [clicked, setClicked] = useState(0);
    const [doc, setFaqs] = useState([]); 
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const ITEMS_PER_PAGE = 10;

    const headers = [
        { name: "Frequently Asked Questions", field: "Frequently Asked Questions", sortable: false },
    ];
   useEffect(() => {
       axios.get(`/faqs`)
           .then((response) => {
               let postData = response.data
               console.log(postData);
               setFaqs(postData)
           })
           .catch((error) => console.log(error))
   }, [])
   const faqs = useMemo(() => {
    let computeddoc = doc;

    if (search) {
        computeddoc = computeddoc.filter(
                d =>
                d.date_last_modified.toLowerCase().includes(search.toLowerCase()) ||
                d.name.toLowerCase().includes(search.toLowerCase())
        );
    }

    setTotalItems(computeddoc.length);

    //Sorting doc
    if (sorting.field) {
        const reversed = sorting.order === "asc" ? 1 : -1;
        computeddoc = computeddoc.sort(
            (a, b) =>
                reversed * a[sorting.field].localeCompare(b[sorting.field])
        );
    }

    //Current Page slice
    return computeddoc.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
}, [doc, currentPage, search, sorting]);
  return (
    <>
    <Navbar/>
        <div className="stricky-header stricked-menu main-menu">
            <div className="sticky-header__content"></div>
        </div>
        <section className="page-header">
            <div className="page-header__bg"     ></div>
              <div className="container">
                <ul className="thm-breadcrumb list-unstyled">
                    <li><Link to="/">Home</Link></li>
                    <li><span>FAQs</span></li>
                </ul>
                <h2>FAQs</h2>
            </div>
        </section>
        <section className="service-five pt-50 pb-50">
        <div className="container">
        <div className="compare-table_doc table-responsive">
            <table className="table">
     <TableHeader
               headers={headers}
               onSorting={(field, order) =>
                   setSorting({ field, order })
               }
           />
                   <tbody>
                   {
                              faqs.map((item, index) => (
                               
                               <tr key="">
                                   <td>
                                   <div className="accrodion-grp service-details__accrodion" data-grp-name="service-details__accrodion-1">

                           <div className={`accrodion  wow fadeInUp ${index === clicked && "active"}`} key={index} data-wow-delay="0ms">
                               <div className="accrodion-title" onClick={() => setClicked(index)}>
                                   <h4>{item.question}</h4>
                                   <span className="accrodion-icon"></span>
                               </div>
                               {index === clicked && 
                               <div className="accrodion-content">
                                   <div className="inner_doc" >
                                   <p>{item.answer}</p>
                                   </div>
                               </div>}
                           </div>
                       </div>
                                   </td>
                               </tr>
                        
                  ))
               }

                   </tbody>
        </table>
          <div className="col-md-4">
               <Pagination
                   total={totalItems}
                   itemsPerPage={ITEMS_PER_PAGE}
                   currentPage={currentPage}
                   onPageChange={page => setCurrentPage(page)}
               />
           </div>
                </div>
            </div>
        </section>
        <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link>
        <Footer/>
    </>
  )
}

export default Faqs