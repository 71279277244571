import BGS1 from '../../assets/images/services/service-s-1-1.png'
import React, { useState, useEffect }   from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import SearchLinks from '../Home/Search'

export default function SFsidebar() {

    const [link, setPost] = useState()
    useEffect(() => {
        axios.get('/sustainablefinance')
            .then((response) => {
                let postData = response.data
                console.log(postData);
                setPost(postData)
            })
            .catch((error) => console.log(error))
    }, [])


  return (

<div className="blog-sidebar">
                            <div className="blog-sidebar__item blog-sidebar__item--posts">
                                <h3 className="blog-sidebar__title">Related links</h3>
                                <ul className="list-unstyled blog-sidebar__post">
                                    
                                {
                                link ? link.map((p, index) => (
                                    <>
                                
                                    <li className="blog-sidebar__post__item">
                                        <div className="blog-sidebar__post__image">
                                            <img src={`../${p.image}`} height="30" width="45" alt="" />
                                        </div>
                                        <div className="blog-sidebar__post__content">
                                            <h3 className="blog-sidebar__post__title"><Link to={`${p.link}`}>{p.title}</Link></h3>
                                        </div>
                                    </li>
                                    
                                      </> 
                                    )) : <p>Loading.....</p>
                                }
                                
                                    
                                </ul>
                            </div>
                            <div className="blog-sidebar__item blog-sidebar__item--tags">
                                <h3 className="blog-sidebar__title">Core links</h3>
                                <ul className="list-unstyled blog-sidebar__tags">
                                    <li>
                                        <Link to="/MonetaryPolicy">Monetary Policy</Link>
                                    </li>
                                    <li>
                                        <Link to="/FinancialStability">Financial Stability</Link>
                                    </li>
                                    <li>
                                        <Link to="/FinancialEducation">Financial Inclusion</Link>
                                    </li>
                                    <li>
                                        <Link to="/MarketConduct">Market Conduct</Link>
                                    </li>
                                    <li>
                                        <Link to="/SustainableFinance">Sustainable Finance</Link>
                                    </li>
                                    <li>
                                        <Link to="/Statistics">Statistics</Link>
                                    </li>
                                    <li>
                                        <Link to="/FinancialMarket">Financial Market</Link>
                                    </li>
                                    <li>
                                        <Link to="/LawsRegulations">Laws & Regulations</Link>
                                    </li>
                                   
                                </ul>
                            </div>
                        </div>
  )
}
