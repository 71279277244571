import React, { useEffect, useState,useContext } from 'react'
import {Navigate, Switch, Route, Routes, useLocation } from 'react-router-dom'
//import { Redirect } from 'auth0-js'


import NotFound from '../components/notfound'
import Navbar from '../components/Navbar/Main'
import Footer from '../components/Footer/Main'
import Home from '../components/Home/Main'
import Kinya from '../components/Kinya/Main'
import About from '../components/About/Main'
import Service from '../components/Services/Service/Main'
import MonetaryPolicy from '../components/MonetaryPolicy/Main'
import Publications from '../components/Publications/Main'
import ServiceDetails from '../components/Services/ServiceDetails/Main'
// import {MPFDetails} from '../components/MonetaryPolicy/MPFDetails/Main'
// import {MPFDetails} from '../components/MonetaryPolicy/MPdetails'
import {FEDetails} from '../components/FinancialEducation/FEdetails'
import FinancialEducation from '../components/FinancialEducation/Main'
import Sustainablefinance from '../components/SustainableFinance/Main'
import MarketConduct from '../components/MarketConduct/Main'
import {FSDetails} from '../components/FinancialStability/FSdetails'
import Statistics from '../components/Statistics/Main'
import {STATDetails} from '../components/Statistics/STATdetails'
import LawsRegulations from '../components/LawsandRegulations/Main'
import {LRDetails} from '../components/LawsandRegulations/LRdetails'
import FinancialStability from '../components/FinancialStability/Main'
import FinancialMarket from '../components/FinancialMarket/Main'
import Team from '../components/Teams/Main'
import TeamDetails from '../components/Teams/teamdetails'
import {FMDetails} from '../components/FinancialMarket/FMdetails'
import JOBdocuments from '../components/Careers/jobdocuments'
import PROdocuments from '../components/Procurement/prodocuments'
import CreditAudit from '../components/Credit/CreditAudit/Main'
import CreditRepair from '../components/Credit/CreditRepair/Main'
import Careers from '../components/Careers/Main'
import Faqs from '../components/Faqs/Main'
import {Aml} from '../components/LawsandRegulations/aml'
import {Pension} from '../components/LawsandRegulations/pension'
import {Insurance} from '../components/LawsandRegulations/insurance'
import {Accredit_low} from '../components/LawsandRegulations/accredit_low'
import {Banking} from '../components/LawsandRegulations/banking'
import {Regdigest} from '../components/LawsandRegulations/regdigest'
import {Creditreport} from '../components/LawsandRegulations/creditreport'
import {Inclusiondata} from '../components/FinancialEducation/inclusiondata'
import {Inclusionpolicy} from '../components/FinancialEducation/inclusionpolicy'
import {Savingsgroup} from '../components/FinancialEducation/savingsgroup'
import {Educate} from '../components/FinancialEducation/educate'
import {Education} from '../components/FinancialEducation/financialed'
import {Mpcommitee} from '../components/MonetaryPolicy/mpcommitee'
import {Mpframework} from '../components/MonetaryPolicy/mpframework'
import {Mpimplementation} from '../components/MonetaryPolicy/mpimplementation'
import {Mpreports} from '../components/MonetaryPolicy/mpreports'
import {Mpstrategy} from '../components/MonetaryPolicy/mpstrategy'
import {Psstatistics} from '../components/Statistics/psstatistics'
import {Ecostatistics} from '../components/Statistics/ecostatistics'
import {Fsstatistics} from '../components/Statistics/fsstatistics'
import { Mminterestrates } from '../components/FinancialMarket/mminterestrates'
import { Mminstruments } from '../components/FinancialMarket/mminstruments'
import { Yieldcurve } from '../components/FinancialMarket/yieldcurve'
import { Interbankmarket } from '../components/FinancialMarket/interbankmarket'
import { Avgexchangerate } from '../components/FinancialMarket/avgexchangerate'
import { Forex } from '../components/FinancialStability/forex'
import { Banksupervision } from '../components/FinancialStability/banksupervision'
import { Insurances } from '../components/FinancialStability/insurances'
import { Pensions } from '../components/FinancialStability/pensions'
import { Accreditations } from '../components/FinancialStability/accreditations'
import { Microfinance } from '../components/FinancialStability/microfinance'
import { Paymentsystem } from '../components/FinancialStability/paymentsystem'
import { Currency } from '../components/LawsandRegulations/currencylaw'
import { Crosscutting } from '../components/LawsandRegulations/crosscuttings'
import { Depositinsurance } from '../components/LawsandRegulations/depositinsurance'
import { Financialmarkets } from '../components/LawsandRegulations/financialmarkets'
import { Foreignexchange } from '../components/LawsandRegulations/foreignexchange'
import { Fscprotection } from '../components/LawsandRegulations/fscprotection'
import { Ndfis } from '../components/LawsandRegulations/ndfis'
import { Microfinances } from '../components/LawsandRegulations/microfinances'
import { Paymentsystems } from '../components/LawsandRegulations/paymentsystems'
import { Tcsps } from '../components/LawsandRegulations/tcsp'
import { Resultdata } from '../components/Features/resultdata'
import Infosec from '../components/Features/infosec'
import Statuse from '../components/Features/statuse'
import Board from '../components/About/board'
import Aboutus from '../components/About/aboutus'
import Directors from '../components/About/directors'
import Employee from '../components/About/employee'
import ApplyNow from '../components/ApplyNow/Main'
import CompareEMI from '../components/Features/CompareEMI/Main'
import ExchangeRate from '../components/Features/ExchangeRate/Main'
import Calendar from '../components/Calendar/Main'
import { ExchangeRateHis } from '../components/Features/ExchangeRateHis/Main'
import HomeLoan from '../components/Features/HomeLoan/Main'
import HomeLoanEligibility from '../components/Features/HomeLoanEligibility/Main'
import LoanEligibility from '../components/Features/LoanEligibility/Main'
import MonthlyLoan from '../components/Features/MonthlyLoan/Main'
import PersonalLoan from '../components/Features/PersonalLoan/Main'
import NewsMain from '../components/News/NewsMain/Main'
// import NewsDetails from '../components/News/NewsDetails/Main'
import {NewsDetails} from '../components/News/NewsDetails/Main'
import Contact from '../components/Contact/Main'
//import history from './utils/history';

// Kinyarwanda version
import NavbarKinya from '../components/rw/NavbarKinya/Main'
import FooterKinya from '../components/rw/FooterKinya/Main'
import HomeRw from '../components/rw/Home/Main'
import AboutRw from '../components/rw/About/Main'
import MonetaryPolicyRw from '../components/rw/MonetaryPolicy/Main'
import PublicationsRw from '../components/rw/Publications/Main'
// import {MPFDetailsRw} from '../components/rw/MonetaryPolicy/MPdetails'
import {FEDetailsRw} from '../components/rw/FinancialEducation/FEdetails'
import FinancialEducationRw from '../components/rw/FinancialEducation/Main'
import {FSDetailsRw} from '../components/rw/FinancialStability/FSdetails'
import StatisticsRw from '../components/rw/Statistics/Main'
import {STATDetailsRw} from '../components/rw/Statistics/STATdetails'
import LawsRegulationsRw from '../components/rw/LawsRegulations/Main'
import {LRDetailsRw} from '../components/rw/LawsRegulations/LRdetails'
import FinancialStabilityRw from '../components/rw/FinancialStability/Main'
import FinancialMarketRw from '../components/rw/FinancialMarket/Main'
// import TeamRw from '../components/rw/Teams/Main'
import TeamDetailsRw from '../components/rw/Teams/teamdetails'
import {FMDetailsRw} from '../components/rw/FinancialMarket/FMdetails'
import JOBdocumentsRw from '../components/rw/Careers/jobdocuments'
import PROdocumentsRw from '../components/rw/Procurement/prodocuments'
import CareersRw from '../components/rw/Careers/Main'
import FaqsRw from '../components/rw/Faqs/Main'
import ExchangeRateRw from '../components/rw/Features/ExchangeRate/Main'
import CalendarRw from '../components/rw/Calendar/Main'
import ExchangeRateHisRw from '../components/rw/Features/ExchangeRateHis/Main'
// import NewsMainRw from '../components/rw/News/NewsMain/Main'
import {NewsDetailsRw} from '../components/rw/News/NewsDetails/Main'
import ContactRw from '../components/rw/Contact/Main'
import { Esstatistics } from '../components/Statistics/esstatistics'
import { Tendernotice } from '../components/Procurement/tendernotice'
import { Specificrule } from '../components/Procurement/specificrule'
import { Procurementplan } from '../components/Procurement/procurementplans'
import { Procurementportal } from '../components/Procurement/procurementportals'
import { Trustcompanies } from '../components/MarketConduct/trustcompanies'
import { Surveyreports } from '../components/MarketConduct/surveyreports'
import { Sectorcharters } from '../components/MarketConduct/sectorcharters'
import { Nondeposittaking } from '../components/MarketConduct/nondeposittaking'
import { Forexbureaus } from '../components/MarketConduct/forexbureaus'
import { Financialinnovation } from '../components/FinancialEducation/financialinnovation'
import { Regulatorysandbox } from '../components/FinancialEducation/regulatorysandbox'
import { Capacity } from '../components/SustainableFinance/capacity'
import { Otherreports } from '../components/SustainableFinance/otherreport'
import { Regulatoryframeworks } from '../components/SustainableFinance/regulatoryframework'
import { Reportandpublications } from '../components/SustainableFinance/reportandpublication'
import { Sustainablefinancegovernance } from '../components/SustainableFinance/governance'
import { Jobs } from '../components/Careers/joblist'
import { Internerships } from '../components/Careers/interns'
import { Results } from '../components/SearchResults'
import { Anreports } from '../components/Publications/anreports'
import { Ecreview } from '../components/Publications/ecreview'
import { Fpcs } from '../components/Publications/fpcs'
import { Fsreports } from '../components/Publications/fsreports'
import { Mpfsreports } from '../components/Publications/mpfsreports'
import { Pressrelease } from '../components/Publications/pressrelease'

// import { Inclusionsurvey } from '../components/FinancialEducation/inclusionsurvey'
// import { Inclusionpolicy } from '../components/FinancialEducation/inclusionpolicy'
// import { Savingsgroup } from '../components/FinancialEducation/savingsgroup'
// import { Consumerprotection } from '../components/FinancialEducation/consumerprotection'

const ExternalRedirectPage = () => {
  React.useEffect(() => {
    window.location.href = 'https://e-recruitment.bnr.rw/';
  }, []);
  
  return (
    <div>
      <h1>Redirecting...</h1>
    </div>
  );
};

const Routing = () => {
    const [homepage, sethomepage] = useState(false)
    const location = useLocation();
    useEffect(() => {
      if (location.pathname === "/home-02" || location.pathname === "/kinya"){
        sethomepage(false)
      }else{
        sethomepage(true)
      }
    }, [location])
    
    return (
        <>
            {/* {homepage && <Navbar />} */}
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/careers" element={<ExternalRedirectPage />} />
                <Route path="*" element={<NotFound/>} />
                <Route path="/Kinya" element={<Kinya />} />
                <Route path="/about" element={<About />} />
                <Route path="/service" element={<Service />} />
		            <Route path="/search" element={<Results />} />
                <Route path="/MonetaryPolicy" element={<MonetaryPolicy />} />
                <Route path="/education" element={<FinancialEducation />} />
                <Route path="/financialstability" element={<FinancialStability />} />
                <Route path="/financialmarket" element={<FinancialMarket />} />
                <Route path="/marketconduct" element={<MarketConduct />} />
                <Route path="/lawsandregulations" element={<LawsRegulations />} />
                <Route path="/statistics" element={<Statistics />} />
                <Route path="/statdetails/:id" element={<STATDetails />} />
                <Route path="/lrdetails/:id" element={<LRDetails />} />
                <Route path="/fmdetails/:id" element={<FMDetails />} />
                <Route path="/fedetails/:id" element={<FEDetails />} />
                <Route path="/fsdetails/:id" element={<FSDetails />} />
                <Route path="/Publications" element={<Publications />} />
                {/* <Route path="/MPdetails/:id" element={<MPFDetails />} /> */}
                <Route path="/teamdetails/:id" element={<TeamDetails />} />
                <Route path="/newsdetails/:id" element={<NewsDetails />} />
                <Route path="/jobdocuments" element={<JOBdocuments />} />
                <Route path="/joblist" element={<Jobs />} />
                <Route path="/interns" element={<Internerships />} />
                <Route path="/jobdocuments/:id" element={<JOBdocuments />} />
                <Route path="/prodocuments" element={<PROdocuments />} />
                <Route path="/prodocuments/:id" element={<PROdocuments />} />
                <Route path="/servicedetails" element={<ServiceDetails />} />
                <Route path="/team" element={<Team />} />
                <Route path="/teamdetails" element={<TeamDetails />} />
                <Route path="/creditaudit" element={<CreditAudit />} />
                <Route path="/creditrepair" element={<CreditRepair />} />
                <Route path="/careers" element={<Careers />} />
                <Route path="/faqs" element={<Faqs />} />
                <Route path="/aml" element={<Aml />} />
                <Route path="/resultdata" element={<Resultdata />} />
                <Route path="/infosec" element={<Infosec />} />
                <Route path="/statuse" element={<Statuse />} />
                <Route path="/banking" element={<Banking />} />
                <Route path="/creditreport" element={<Creditreport />} />
                <Route path="/regdigest" element={<Regdigest />} />
                <Route path="/pension" element={<Pension />} />
                <Route path="/insurance" element={<Insurance />} />
                <Route path="/insurances" element={<Insurances />} />
                <Route path="/pensions" element={<Pensions />} />
                <Route path="/accredit_low" element={<Accredit_low />} />
                <Route path="/accreditations" element={<Accreditations />} />
                <Route path="/currencylaw" element={<Currency />} />
                <Route path="/crosscuttings" element={<Crosscutting />} />
                <Route path="/depositinsurance" element={<Depositinsurance />} />
                <Route path="/financialmarkets" element={<Financialmarkets />} />
                <Route path="/foreignexchange" element={<Foreignexchange />} />
                <Route path="/fscprotection" element={<Fscprotection />} />
                <Route path="/microfinances" element={<Microfinances />} />
                <Route path="/ndfis" element={<Ndfis />} />
                <Route path="/paymentsystems" element={<Paymentsystems />} />
                <Route path="/tcsp" element={<Tcsps />} />
                <Route path="/anreports" element={<Anreports />} />          
                <Route path="/ecreview" element={<Ecreview />} />  
				<Route path="/fpcs" element={<Fpcs />} />  
                <Route path="/fsreports" element={<Fsreports />} />  
                <Route path="/mpfsreports" element={<Mpfsreports />} />  
                <Route path="/pressrelease" element={<Pressrelease />} />   
          
                <Route path="/Board" element={<Board />} />  
          <Route path="/aboutus" element={<Aboutus />} /> 
          <Route path="/directors" element={<Directors />} /> 
          <Route path="/employee" element={<Employee />} /> 
                <Route path="/inclusiondata" element={<Inclusiondata />} />
                <Route path="/educate" element={<Educate />} />
                <Route path="/financialinnovation" element={<Financialinnovation />} />
                <Route path="/inclusionpolicy" element={<Inclusionpolicy />} />
                <Route path="/regulatorysandbox" element={<Regulatorysandbox />} />
                <Route path="/mpCommitee" element={<Mpcommitee />} />
                <Route path="/mpFramework" element={<Mpframework />} />
                <Route path="/mpImplementation" element={<Mpimplementation />} />
                <Route path="/mpReports" element={<Mpreports />} />
                <Route path="/mpStrategy" element={<Mpstrategy />} />
                <Route path="/savingsgroup" element={<Savingsgroup />} />
                <Route path="/fsstatistics" element={<Fsstatistics />} />
                <Route path="/ecostatistics" element={<Ecostatistics />} />
                <Route path="/psstatistics" element={<Psstatistics />} />
                <Route path="/esstatistics" element={<Esstatistics />} />
                <Route path="/Forexbureaus" element={<Forexbureaus />} />
                <Route path="/Nondeposittaking" element={<Nondeposittaking />} />
                <Route path="/Sectorcharters" element={<Sectorcharters />} />
                <Route path="/Surveyreports" element={<Surveyreports />} />
                <Route path="/Trustcompanies" element={<Trustcompanies />} />
                <Route path="/sustainablefinance" element={<Sustainablefinance />} />
                <Route path="/capacity" element={<Capacity />} />
                <Route path="/otherreport" element={<Otherreports />} />
                <Route path="/regulatoryframework" element={<Regulatoryframeworks />} />
                <Route path="/reportandpublication" element={<Reportandpublications />} />
                <Route path="/governance" element={<Sustainablefinancegovernance />} />

                <Route path="/yieldcurve" element={<Yieldcurve />} />
                <Route path="/mminstruments" element={<Mminstruments />} />
                <Route path="/mminterestrates" element={<Mminterestrates />} />
                <Route path="/interbankmarket" element={<Interbankmarket />} />
                <Route path="/avgexchangerate" element={<Avgexchangerate />} />
                
                <Route path="/forex" element={<Forex />} />
                <Route path="/banksupervision" element={<Banksupervision />} />
                <Route path="/insurance" element={<Insurance />} />
                <Route path="/microfinance" element={<Microfinance />} />
                <Route path="/paymentsystem" element={<Paymentsystem />} />
                <Route path="/procurementPlan" element={<Procurementplan />} />
                <Route path="/specificrule" element={<Specificrule />} />
                <Route path="/tendernotice" element={<Tendernotice />} />
                <Route path="/procurementPortal" element={<Procurementportal />} />
                <Route path="/applynow" element={<ApplyNow />} />
                <Route path="/compareEMI" element={< CompareEMI/>} />
                <Route path="/exchangeRate" element={< ExchangeRate/>} />    
                <Route path="/calendar" element={< Calendar/>} />  
                <Route path="/financialed" element={< Education/>} />                 
                <Route path="/exchangeRatehis" element={< ExchangeRateHis/>} />
                <Route path="/homeloan" element={< HomeLoan />} />
                <Route path="/homeloaneligibility" element={<HomeLoanEligibility />} />
                <Route path="/loaneligibility" element={<LoanEligibility />} />
                <Route path="/monthlyloan" element={<MonthlyLoan />} />
                <Route path="/personalloan" element={<PersonalLoan />} />
                <Route path="/newsmain" element={<NewsMain />} />
                <Route path="/contact" element={<Contact />} />

                {/* Kinyarwanda version */}
                <Route path="rw/" element={<HomeRw />} />
                <Route path="rw/about" element={<AboutRw />} />
                <Route path="rw/MonetaryPolicy" element={<MonetaryPolicyRw />} />
                <Route path="rw/financialeducation" element={<FinancialEducationRw />} />
                <Route path="rw/financialstability" element={<FinancialStabilityRw />} />
                <Route path="rw/financialmarket" element={<FinancialMarketRw />} />
                <Route path="rw/lawsregulations" element={<LawsRegulationsRw />} />
                <Route path="rw/statistics" element={<StatisticsRw />} />
                <Route path="rw/statdetails/:id" element={<STATDetailsRw />} />
                <Route path="rw/lrdetails/:id" element={<LRDetailsRw />} />
                <Route path="rw/fmdetails/:id" element={<FMDetailsRw />} />
                <Route path="rw/fedetails/:id" element={<FEDetailsRw />} />
                <Route path="rw/fsdetails/:id" element={<FSDetailsRw />} />
                <Route path="rw/Publications" element={<PublicationsRw />} />
                {/* <Route path="rw/MPdetails/:id" element={<MPFDetailsRw />} /> */}
                <Route path="rw/teamdetails/:id" element={<TeamDetailsRw />} />
                <Route path="rw/newsdetails/:id" element={<NewsDetailsRw />} />
                <Route path="rw/jobdocuments" element={<JOBdocumentsRw />} />
                <Route path="rw/jobdocuments/:id" element={<JOBdocumentsRw />} />
                <Route path="rw/prodocuments" element={<PROdocumentsRw />} />
                <Route path="rw/prodocuments/:id" element={<PROdocumentsRw />} />
                {/* <Route path="rw/team" element={<TeamRw />} /> */}
                <Route path="rw/teamdetails" element={<TeamDetailsRw />} />
                <Route path="rw/careers" element={<CareersRw />} />
                <Route path="rw/faqs" element={<FaqsRw />} />
                {/* <Route path="rw/applynow" element={<ApplyNowRw />} /> */}
                <Route path="rw/exchangeRate" element={< ExchangeRateRw />} />    
                <Route path="rw/calendar" element={< CalendarRw/>} />               
                <Route path="rw/exchangeRateHis" element={< ExchangeRateHisRw/>} />
                {/* <Route path="rw/newsmain" element={<NewsMainRw />} /> */}
                <Route path="rw/contact" element={<ContactRw />} />
                <Route path="rw/NavbarKinya" element={<NavbarKinya />} />
                <Route path="rw/FooterKinya" element={<FooterKinya />} />
            </Routes>
            {/* <Footer /> */}
        </>
    )
}

export default Routing