import BGPH1 from "../../../../assets/images/backgrounds/page-header-bg-1-1.jpg";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment/moment";

import "react-datepicker/dist/react-datepicker.css";
import Navbar from "../../NavbarKinya/Main";
import Footer from "../../FooterKinya/Main";

const ExchangeRate = () => {

const [currency_name, setCurrName] = useState("");
const [startDate, setStartDate] = useState("");
const [endDate, setEndDate] = useState("");
const [currency, setCurrency] = useState([]);

const onSubmitForm = async (e) => {
  e.preventDefault()
  const response = await fetch(`/currency_his/?currency_name=${currency_name}&start_date=${startDate}&end_date=${endDate}`);
  const parseResponse = await response.json();
  setCurrency(parseResponse);
}

const [data, setData] = useState([]);
useEffect(() => {
  const getData = () => {
      fetch("/currency")
          .then(response => response.json())
          .then(json => {
            setData(json);
              console.log(json);
          });
  };
  getData();
}, []);

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
  });
};

  return (
    <>
    <Navbar/>
      <div className="stricky-header stricked-menu main-menu">
        <div className="sticky-header__content"></div>
      </div>
      <section className="page-header">
        <div
          className="page-header__bg"
          style={{ backgroundImage: `url(${BGPH1})` }}
        ></div>
        <div className="container">
          <ul className="thm-breadcrumb list-unstyled">
            <li>
              <Link to="/">Home</Link>
            </li>
          </ul>
          <h2>Exchange Rate Table</h2>
        </div>
      </section>

      <section className="service-five pt-50 pb-50">
      <div className="container">
      <div className="row row-gutter-y-10 service-four__inner">
          <div className="loan-comparison__body" id="compare-emi-1">
            <div className="row">
                <form onSubmit={onSubmitForm}>
                  
                <div className="row">
                <div className="col-xl-2">
                      <select 
                          value={currency_name} 
                          onChange={e => setCurrName(e.target.value)}
                          className="form-control input-control1"
                          id="emi-year-input"
                          name="currency_name" 
                          required>
                          <option value="">Select Currency...</option>
                          {data.map((item) => (
                            <option key={item.id} value={item.currency_name}>
                              {item.currency_name}
                            </option>
                          ))} 
                       </select>
                     </div>
                     <div className="col-xl-4">
                          <DatePicker
                            className="form-control input-control1"
                            id="emi-year-input"
                            value={startDate} 
                            name="start_date" 
                            placeholderText="Start Date"
                            onChange={e => setStartDate(moment(e).format("YYYY-MM-DD"))} 
                            required/>
                     </div>
                     <div className="col-xl-4">
                          <DatePicker
                            type ="date"
                            className="form-control input-control1"
                            id="emi-year-input"
                            value={endDate} 
                            name="end_date" 
                            placeholderText="End Date"
                            onChange={e => setEndDate(moment(e).format("YYYY-MM-DD"))} 
                            required/>
                     </div>
                        <div className="col-xl-2">
                            <input className="thm-btn-ccy" type="submit" value="Search" />
                        </div>
                        </div>
                </form>


                </div>
                <br></br>
     
                <div className="compare-table table-responsive">
                  <table className="table table-striped table-hover">
                    <thead>
                      <tr>
                        <th>Currency</th>
                        <th>Buying</th>
                        <th>Average</th>
                        <th>Selling</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                                    {currency ? currency.map(p => (
                                            <tr key={p.id}>
                                                <td>{p.currency_name}</td>
                                                <td>{p.selling_rate}</td>
                                                <td>{p.buying_rate}</td>                                                
                                                <td>{p.average_rate}</td>                                              
                                                <td>{formatDate(p.created_at)}</td>
                                            </tr>
                                        )) : <tr key=""><td>Loading.....</td></tr>
                                    }
                                </tbody>
                  </table>
                </div>
              </div>
          </div>
        </div>
      </section>
      <Link to="#" data-target="html" className="scroll-to-target scroll-to-top">
        <i className="fa fa-angle-up"></i>
      </Link>
      <Footer/>
    </>
  );
};

export default ExchangeRate;
