import BGPH1 from '../../assets/images/backgrounds/page-header-bg-1-1.jpg'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useParams } from 'react-router-dom'
import React, { useState, useEffect, useMemo }   from 'react'
import FEsidebar from './fesidebar'
import axios from 'axios'
//import { Container } from "react-bootstrap";
//import Header from "components/Header";
import { TableHeader, Pagination, Search } from '../DataTable';
import Navbar from '../Navbar/Main'
import Footer from '../Footer/Main'
import FEquery from './fequery'
import {
    MDBTabsContent,
    MDBTabsPane
  } from 'mdb-react-ui-kit';
// import Service from '../Main'
// import onSubmitForm from '../Main'

export const Regulatorysandbox = () => {
              
    const [iconsActive, setIconsActive] = useState('tab1');

    const handleIconsClick = (value) => {
      if (value === iconsActive) {
        return;
      }
      setIconsActive(value);
    };

   const [doc, setDoc] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });

    const ITEMS_PER_PAGE = 10;

    const headers = [
        { name: "Date", field: "Date", sortable: false },
        { name: "Documents", field: "Documents", sortable: false },
    ];
   const [loading, setLoading] = useState(true);
   useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`/fsp`);
        const docData = await response.json();
        setDoc(docData);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []); 
   const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    });
  };
  const docData = useMemo(() => {
    let computeddoc = doc;

    if (search) {
        computeddoc = computeddoc.filter(
                d =>
                d.date_last_modified.toLowerCase().includes(search.toLowerCase()) ||
                d.name.toLowerCase().includes(search.toLowerCase())
        );
    }

    setTotalItems(computeddoc.length);

    //Sorting doc
    if (sorting.field) {
        const reversed = sorting.order === "asc" ? 1 : -1;
        computeddoc = computeddoc.sort(
            (a, b) =>
                reversed * a[sorting.field].localeCompare(b[sorting.field])
        );
    }

    //Current Page slice
    return computeddoc.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
}, [doc, currentPage, search, sorting]);



        const [clicked, setClicked] = useState(0);
        const faqs = [
            {
                question: "Is there a fee to apply for the sandbox?",
                answer: "There is no application fee or participation fee for the sandbox"
            },
            {
                question: "Will BNR help me access data and information once admitted to the sandbox?",
                answer: "No. We don’t provide access to data and information. The applicant can independently negotiate with relevant institutions."
            },
            {
                question: "Can I be selected for the sandbox when my solution is not fully developed?",
                answer: "To be selected, the applicant must have at least a testable solution with its key features fully developed. Usually a Minimum Viable Product (MVP)."
            },
            {
                question: "Will the sandbox or sandbox office help me to create partnerships with other financial institutions or potential partners for the test?",
                answer: "Being in the sandbox does not guarantee sealing partnership deals. More importantly, the applicant should identify and create partnerships key to testing so that they can be considered ready for testing. "
            },
            {
                question: "Can I still apply for other licenses while in the sandbox? ",
                answer: "Yes. The sandbox participant or applicant is free to apply for other licenses. "
            },
            {
                question: "Once admitted in the sandbox, will I be allowed to mention that?",
                answer: "Yes. It is mandatory to mention that the product/solution is under the sandbox testing period to test users"
            },
            {
                question: "How can I follow up my application? ",
                answer: "Applicants follow up on their application via the e-correspondence platform or can always reach out to the Sandbox office via email at sandbox@bnr.rw "
            }
            
        ]

  return (
    <>
    <Navbar/>
        <div className="stricky-header stricked-menu main-menu">
            <div className="sticky-header__content"></div>
        </div>
        <section className="page-header">
            <div className="page-header__bg"   ></div>
            <div className="container">
                <ul className="thm-breadcrumb list-unstyled">
                    <li><Link to="/">Home</Link></li>
                    <li><span>Regulatory Sandbox</span></li>
                </ul>
                <div className="surrounding slider-one__title">
                The National Bank of Rwanda (NBR) in 2022 decided to create an enabling regulatory environment 
                for digital innovations or Fintechs by establishing a regulatory sandbox.
                </div>
            </div>
        </section>
        {/* <div  className="about-four__shape"></div>
  <div  className="about-five__shape-2"></div> 
  <div  className="about-five__shape-1"></div>
  <div  className="about-five__shape-9"></div> 
  <div  className="about-five__shape-0"></div> */}
        <section className="service-details pt-120 pb-120">
            <div className="container">
                <div className="row row-gutter-y-50 contact-info-one">
                    <div className="col-lg-4">
                    <FEsidebar/>
                    </div>
                    <div className="col-lg-8">
                        {/* <div className="service-details__image">
                            <a href="/monetarypolicy" className="service-details__icon"><i className="fa fa-arrow-left"></i></a>
                        </div> */}
                        <div className="service-details__content">
                       
                            <div className="row row-gutter-y-30">
                                <div className="col-md-12">
                 
                      {/* <img src={p.image} alt="" /> */}
                      <h3 className="service-details__title">Regulatory Sandbox</h3>
                      {/* <img src="../../../assets/images/services/service-d-1-1.png" alt="" /> */}
                      {/* <img src= {`../${p.image}`} alt="" /> */}
                      
                            <p>Emerging technologies have consistently positively disrupted the financial market or the financial sector globally by making business processes easier, more convenient, and efficient for customers. And digitally innovative products have enabled access to finance for millions of people, especially in Africa that were initially excluded by traditional business models. In Rwanda, the advent of mobile money services, now used by over 6 million people is an example of the importance of Digital Financial Services (DFS). 

<br/>Promoting DFS in payments; banking; insurance; and pension is a goal elaborated in Rwanda’s strategic programs including the National Payment system strategy; the National Financial Inclusion Strategy; the Financial Sector Development Strategic Plan, and others. Moreover, Rwanda is positioning itself to be a FinTech hub in the broader context of the Kigali International Financial Center.    

<br/>To support the Government objectives above, the National Bank of Rwanda (BNR) in 2022 decided to create an enabling regulatory environment for digital innovations or Fintechs by establishing a regulatory sandbox. 

<br/>The regulation No 41/2022 of 13/04/2022 governing the regulatory sandbox herein referred to as “this regulation” enables innovative financial services and products to be tested and deployed in a live environment with real customers. It fosters innovations that improve the quality, access, and usage of financial services while adequately managing risks and setting safeguards to ensure consumer protection and financial stability. 
                       <div className="benefit-two__tab__doc3">
                            <ul className="list-unstyled">
                                <li> 
                                   
                                 <span><Link className='linkcolor' to="https://www.bnr.rw/documents/18.04.2022_BNR_RegulatorySandbox.pdf" target="_blank">Click here to access this regulation</Link></span></li>
                            </ul>
                        </div>  
        The sandbox regulatory environment was created specifically for financial institutions, legal entities alone or in partnership with a financial institution, or individuals with products or services in the licensing scope of BNR. Participants are selected based on the eligibility criteria in Article 4 of this regulation. 
 </p>
                                <div className="row benefit-two__tab tabs-box" >
                            <ul className="list-unstyled benefit-two__tab__sand tab-btns tab-buttons"> 
        
                                            <li className={`${iconsActive === 'tab1'} ? 'active-btn' : 'tab-btn'}`} 
                                            onClick={() => handleIconsClick('tab1')} >
                                                <span>Eligibility Criteria</span>
                                            </li>
                                            <li className="tab-btn" onClick={() => handleIconsClick('tab2')} active={iconsActive === 'tab2'}>
                                                <span>Cohort Schedule</span>
                                            </li>
                                            <li className="tab-btn" onClick={() => handleIconsClick('tab3')} active={iconsActive === 'tab2'}>
                                                <span>Sandbox graduates</span>
                                            </li>
                                            <li className="tab-btn" onClick={() => handleIconsClick('tab4')} active={iconsActive === 'tab3'}>
                                                <span>FAQs</span>
                                            </li>
                                        </ul>
                    <MDBTabsContent>
                    <MDBTabsPane show={iconsActive === 'tab1'}>
                        <div className="service-details__content">
                            <p>
                    <b>Eligibility Criteria</b>  
<p>This regulation in its article 4 details the criteria for joining the regulatory sandbox of the National Bank of Rwanda. The criteria are strictly adhered to during the assessments of applications.  
</p>
<ol>
    <li>Genuine Innovation: The product, service, or solution is new on the market or offered differently. It improves quality, accessibility, and efficiency, and addresses gaps in the financial sector. 
    </li>
    <li>In Scope: The whole product or the core product is likely to fall under the regulatory or supervisory scope of the National Bank of Rwanda. The applicant should, where applicable, specify regulatory flexibilities needed based on which regulation they likely fall into. 
   </li>
    <li>Readiness to Test and Deploy: Availability of resources to support testing. Resources include a working and testable product/solution, human resources, and financial resources. To participate in the sandbox, one must prove the capacity to deploy at large scale after the sandbox period or show the plan to raise that ability before exiting. 
    </li>
</ol>
Kindly refer to the regulation in its article 4 for the detailed eligibility criteria. 


Key highlights for the Application, Evaluation, and Testing processes 
<ul>
<li>Applications are received in cohorts. Calls for applications will be announced via BNR website and/or social media. 
</li>
<li>Application are received via E-correspondence (https://e-correspondence.bnr.rw/services?service_id=aff78e71-666f-457d-ab41-c26b1c7a169c )  
</li>
<li>Evaluation procedure starts immediately when the submitted application is deemed complete as stipulated in Article 7 of this regulation. 
</li>
<li>Upon admission to the sandbox, the participant is allowed to test for 12 months subjected to increment upon presentation of valid reasons. 
</li>
</ul>
</p>
</div>
                    </MDBTabsPane>
                
                    <MDBTabsPane show={iconsActive === 'tab2'}>
                    <table className="table table-striped table-hover">
                  <thead>
                    <tr>
                    <th>Cohort</th>
                    <th>Participants</th>
                    <th>Service</th>  
                    <th>End of Testing</th>  
                    </tr>
                  </thead>
                    <tbody>
                       <tr>
                          <td rowspan="1">Cohort3</td>
                          <td>Rwanda Telecentre Network (RTN)</td>
                          <td>Aggregation</td>   
                          <td>1 March, 2025</td>  
                       </tr>
                       <tr>
                          <td rowspan="1">Cohort4</td>
                          <td>Insurance Brokerage</td>   
						  <td>Aggregation</td>   
                          <td>1 December, 2024</td>  
                       </tr>
                       <tr>
                          <td rowspan="2">Cohort6</td>
                          <td>KAYKO GROUP Ltd</td>
                          <td>Aggregation</td>   
                          <td>1 February, 2025</td>  
                       </tr>
                       <tr>
                          <td>SOKOWANDA Limited</td>
                          <td>Aggregation</td>   
                          <td>28 February, 2025</td>  
                       </tr>
                    </tbody>
                  </table>

                    <table className="table table-striped table-hover">
                  <thead>
                    <tr>
                    <th>Cohort</th>
                    <th>Theme</th>
                    <th>Call for applications dates</th>  
                    </tr>
                  </thead>
                    <tbody>
                       <tr>
                          <td>Cohort4</td>
                          <td>Thematic Sandbox: Only products in Insurance</td>
                          <td>August 14 – September 14, 2023</td>   
                       </tr>
                       <tr>
                          <td>Cohort5</td>
                          <td>General sandbox:Any financial service under the scope of the sandbox regulation</td>
                          <td>December 1 – December 31, 2023</td>   
                       </tr>
                       <tr>
                          <td>Cohort6</td>
                          <td>General Sandbox: Any financial service under the scope of the sandbox regulation</td>
                          <td>April 1 - April 30, 2024</td>   
                       </tr>
                       <tr>
                          <td>Cohort7</td>
                          <td>General sandbox: Any financial service under the scope of the sandbox regulation</td>
                          <td>August 1 – Aug 31, 2024</td>   
                       </tr>
                       <tr>
                          <td>Cohort8</td>
                          <td>General sandbox: Any financial service under the scope of the sandbox regulation</td>
                          <td>December 1 – December 31, 2024</td>   
                       </tr>
                    </tbody>
                  </table>

                    </MDBTabsPane>

                    <MDBTabsPane show={iconsActive === 'tab3'}>
                    <table className="table table-striped table-hover">
                  <thead>
                    <tr>
                    <th>Cohort</th>
                    <th>Applicant</th>
                    <th>Service</th>  
                    <th>End of Testing</th>  
                    </tr>
                  </thead>
                    <tbody>
                       <tr>
                          <td rowspan="2">Cohort1</td>
                          <td>CoolNet Solutions</td>
                          <td>Payments</td>   
                          <td>18 Nov 2023</td>  
                       </tr>
                       <tr>
                          <td>Icarus</td>
                          <td>Crowdfunding </td>   
                          <td>18 Nov 2023</td>  
                       </tr>
                       <tr>
                          <td rowspan="4">Cohort3</td>
                          <td>Food Bundles</td>
                          <td>Lending</td>   
                          <td>1 Aug 2024</td>  
                       </tr>
                       <tr>
                          <td>PayingTone</td>
                          <td>Buy Now Pay Later</td>   
                          <td>1 Aug 2024</td>  
                       </tr>
                       <tr>
                          <td>PayWS AftaWallet </td>
                          <td>Remittances </td>   
                          <td>1 Aug 2024</td>  
                       </tr>
                       <tr>
                          <td>RTN Iteme </td>
                          <td>Aggregation </td>   
                          <td>1 Aug 2024</td>  
                       </tr>
                       <tr>
                          <td rowspan="2">Cohort4</td>
                          <td>Ikanisa ASSIGU </td>
                          <td>Insurance Brokerage </td>   
                          <td>1 Dec 2024 </td>  
                       </tr>
                       <tr>
                          <td>Cover Soko </td>
                          <td>Insurance Brokerage </td>   
                          <td>1 Dec 2024 </td>  
                       </tr>
                       <tr>
                          <td rowspan="2">Cohort6</td>
                          <td>Kayko Group Ltd </td>
                          <td>Aggregation </td>   
                          <td>1 February 2025 </td>  
                       </tr>
                       <tr>
                          <td>SOKOWANDA Limited </td>
                          <td>Aggregation </td>   
                          <td>28 February 2025</td>  
                       </tr>
                    </tbody>
                  </table>

                    </MDBTabsPane>
                    
                    <MDBTabsPane show={iconsActive === 'tab4'}>
                    <div className="accrodion-grp faq-one-accrodion" data-grp-name="faq-one-accrodion-1">
                        {faqs.map((item, index) => (
                            <div className={`accrodion  wow fadeInUp ${index === clicked && "active"}`} key={index} data-wow-delay="0ms">
                                <div className="accrodion-title" onClick={() => setClicked(index)}>
                                    <h4>{item.question}</h4>
                                    <span className="accrodion-icon"></span>
                                </div>
                                {index === clicked && <div className="accrodion-content">
                                    <div className="inner">
                                        <p>{item.answer}</p>
                                    </div>
                                </div>}
                            </div>
                            ))}
                        </div>
                    </MDBTabsPane>
                </MDBTabsContent>
                   </div>
                            </div>
                            </div>
                    </div>
                    </div>
                </div>
            </div>
        </section>
        <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link>
        <Footer/>
    </>
  )
}
