import BGPH1 from '../../../assets/images/backgrounds/page-header-bg-1-1.jpg'
import { Link } from 'react-router-dom'
import React, { useEffect, useState, useMemo } from "react";
//import { Container } from "react-bootstrap";
//import Header from "components/Header";
import flagUrls from '../flags';
import { TableHeader, Pagination, Search } from '../../DataTable';
import Footer from '../../Footer/Main';
import Navbar from '../../Navbar/Main';
import ExportButton from '../../excel_export';

const DataTable = () => {
    const [currency, setcurrency] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });

    const ITEMS_PER_PAGE = 18;

    const headers = [
        { name: "Country", field: "code", sortable: false },
        { name: "Currency", field: "ccy", sortable: false },
        { name: "Buying Rate", field: "buying", sortable: true },
        { name: "Average Rate", field: "average", sortable: true },
        { name: "Selling Rate", field: "selling", sortable: true },
        { name: "Date", field: "date", sortable: false }

    ];
    useEffect(() => {
        const getData = () => {
            fetch("/currency_day")
                .then(response => response.json())
                .then(json => {
                    setcurrency(json);
                    console.log(json);
                });
        };
        getData();
    }, []);
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });
      };
    const currencyData = useMemo(() => {
        let computedcurrency = currency;

        if (search) {
            computedcurrency = computedcurrency.filter(
                ccy =>
                    ccy.currency_name.toLowerCase().includes(search.toLowerCase()) ||
                    ccy.post_date.toLowerCase().includes(search.toLowerCase())
            );
        }

        setTotalItems(computedcurrency.length);

        //Sorting currency
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedcurrency = computedcurrency.sort(
                (a, b) =>
                    reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        //Current Page slice
        return computedcurrency.slice(
            (currentPage - 1) * ITEMS_PER_PAGE,
            (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
        );
    }, [currency, currentPage, search, sorting]);

    return (
        <>
        <Navbar/>
         <div className="stricky-header stricked-menu main-menu">
            <div className="sticky-header__content"></div>
        </div>
        <section className="page-header">
            <div className="page-header__bg"    ></div>
              <div className="container">
                <ul className="thm-breadcrumb list-unstyled">
                    <li><Link to="/">Home</Link></li>
                    <li><span>Exchange rate</span></li>
                </ul>
            </div>
        </section>
        <section className="service-five pt-50 pb-50">
      <div className="container">
      <div className="row row-gutter-y-10 service-four__inner">
            <div className="loan-comparison__body" id="compare-emi-1">
                <div className="compare-table">
                    <div align="right">
                <span><Link className='linkcolor2' to="/exchangeRatehis">Go to Historical data</Link></span>  
                </div>
                <div className="row">
                        <div className="col-md-6">
                            <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div>
                        <div className="col-md-6 d-flex flex-row-reverse">
                            <Search 
                                onSearch={value => {
                                    setSearch(value);
                                    setCurrentPage(1);
                                }}
                            />
                                 
                        </div>  
                        </div>

                    <table className="table table-striped table-hover">
                    <thead>
                       <tr>
                        <th className="hide-on-mobile">Country</th>
                        <th>Currency</th>
                        <th>Buying Rate</th>
                        <th className="hide-on-mobile">Average Rate</th>
                        <th>Selling Rate</th>
                        <th className="hide-on-mobile">Date</th>
                       </tr>
                     </thead>
                        <tbody>
                            {currencyData.map(ccy => (
                                  <tr>
                                  <td className='hide-on-mobile'>
                                      <img 
                              src={flagUrls[ccy.currency_name]} 
                              style={{ width: '30px', height: '20px' }}
                                  /></td>
                                  <td>{ccy.currency_name}</td>
                                  <td>{ccy.buying_rate}</td>
                                  <td className='hide-on-mobile'>{ccy.average_rate}</td>
                                  <td>{ccy.selling_rate}</td>
                                  <td className='hide-on-mobile'>{formatDate(ccy.post_date)}</td>
                              </tr>                            
                            ))}
                        </tbody>
                    </table>
                    <div className="row">
                        <div className="col-md-12">
                            <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />
				<p></p>
                        <div className="col-md-12">
                        <ul className="list-unstyled">
                               <li className='row'> 
                              <ExportButton data={currency} fileName="currency_table.xlsx" />&nbsp;&nbsp;<Link className='thm-btn-ccy' to="https://fxrates.bnr.rw/" target="_blank">API Access</Link>
                                    </li> 
                              </ul>   
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </div>
            </section>
            <Footer/>
        </>
    );
};

export default DataTable;