import logo from '../../../assets/images/logo-dark.png'
import flagen from '../../../assets/images/shapes/flag-en.png'
import { Link, useLocation} from 'react-router-dom'
import React, { useEffect, useState } from 'react'


const NavbarKinya = () => {
    
    const [mobile, setmobile] = useState(false)
    const [search, setsearch] = useState(false)
    const [homeDrop, sethomeDrop] = useState(false)
    const [headerDrop, setheaderDrop] = useState(false)
    const [servicesDrop, setservicesDrop] = useState(false)
    const [featuresDrop, setfeaturesDrop] = useState(false)
    const [newsDrop, setnewsDrop] = useState(false)
    const [pagesDrop, setpagesDrop] = useState(false)
    const location = useLocation()
    const path = location.pathname
    const [menu, setmenu] = useState({})

    const activeMenu = () => {
        if(path === "/" || path === "/home-02" || path === "/kinya" ){
            setmenu({home:true})
        }else if(path === "/about" ){
            setmenu({about:true})
        }else if(path === "/MonetaryPolicy" || path === "/team" || path === "/teamdetails"  || path === "/creditaudit" || path === "/creditrepair" || path === "/careers" || path === "/faqs" ||  path === "/applynow"){
            setmenu({pages:true})
        }else if(path === "/exchangeRate" || path === "/personalloan" || path === "/loaneligibility" || path === "/homeloan" || path === "/homeloaneligibility" || path === "/compareEMI" || path === "/monthlyloan"){
            setmenu({features:true})
        }
        else if(path === "/contact"){
            setmenu({contact:true})
        }else{
            setmenu({home:true})
        }
    }
    
    const [sticky, setSticky] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };
    }, []);

    useEffect(() => {
        window.scroll(0, 0)
        activeMenu()
    }, [path]);
    const isSticky = () => {
        const scrollTop = window.scrollY;
        scrollTop >= 141 ? setSticky(true) : setSticky(false);
    }

  return (
    <>
    <div className="page-wrapper">
        {/* <div className="topbar">
            <div className="container">
               
                <div className="topbar__links">
                    <Link to="#">NATIONAL BANK OF RWANDA</Link>
                </div>
                
                <div className="topbar__social">
                    <Link to="#"><i className="fab fa-twitter"></i></Link>
                    <Link to="#"><i className="fab fa-facebook"></i></Link>
                    <Link to="#"><i className="fab fa-pinterest"></i></Link>
                    <Link to="#"><i className="fab fa-instagram"></i></Link>
                </div>
            </div>
            <div className ="mandate">Rwanda's Central Bank mandated to ensure price stability and a sound financial system</div>
        </div> */}
        <nav className={`main-menu ${sticky && "stricky-header stricked-menu stricky-fixed"}`}>
            <div className="container">
                <table width="100%">
                    <tr>
                      <td width="40%">
                      {/* <div style={{ backgroundImage: `url(assets/images/logo-dark.png)`}}></div> */}
                        <img src={logo} width="70" height="70"/><Link to="/rw" className='title1'>Banki Nkuru y'Urwanda</Link></td>
                      <td width="60%">
                      <div className="main-menu__nav">
                    <ul className="main-menu__list">
                        {/* <li className={`dropdown ${menu.pages && "current"}`}>
                    
                            <Link to="/"><i className='fa fa-bars'></i></Link>
                           <ul>
                                <li><Link to="/">About</Link> </li>
                                <li className="dropdown">
                                    <Link to="#">Monetary Policy</Link>
                                    <ul>
                                        <li><Link to="/">Monetary Policy Framework</Link></li>
                                        <li><Link to="/">Monetary Policy Report</Link></li>
                                        <li><Link to="/">Monetary Policy Strategy</Link></li>
                                        <li><Link to="/">Monetary Policy Implementation</Link></li>
                                        <li><Link to="/">Monetary Policy Commettee</Link></li>
                                    </ul>
                                </li>
                                <li className="dropdown">
                                    <Link to="#">Financial Stability</Link>
                                    <ul>
                                        <li><Link to="/">Forex bureaus</Link></li>
                                        <li><Link to="/">Financial Consumer Protection</Link></li>
                                        <li><Link to="/">Bank Supervision</Link></li>
                                        <li><Link to="/">Payment System</Link></li>
                                        <li><Link to="/">Credit Information System</Link></li>
                                        <li><Link to="/">Microfinance Institutions</Link></li>
                                        <li><Link to="/">Insurance & Pension</Link></li>
                                        <li><Link to="/">Macro-prudential Policy Framework</Link></li>
                                        <li><Link to="/">Licenses & Other Accreditations granted by NBR</Link></li>
                                        <li><Link to="/">List of Accredited External Auditors for Financial Institutions</Link></li>
                                        <li><Link to="/">Licenced NDFIs</Link></li>
                                    </ul>
                                </li>  
                                <li className="dropdown">
                                    <Link to="#">Statistics</Link>
                                    <ul>
                                        <li><Link to="/">Payment System Statistics</Link></li>
                                        <li><Link to="/">Financial Sector Statistics</Link></li>
                                        <li><Link to="/">External Sector Statistics</Link></li>
                                        <li><Link to="/">Economic Statistics</Link></li>
                                        <li><Link to="/">Public Finance Statistics</Link></li>
                                        <li><Link to="/">Statistics on Financial Markets</Link></li>
                                        <li><Link to="/">Monetary Statistics</Link></li>
                                        <li><Link to="/">CSD statistics</Link></li>
                                        <li><Link to="/">Consumer Price Index</Link></li>
                                        <li><Link to="/">EDWH Web Portal</Link></li>
                                    </ul>
                                </li>
                                <li><Link to="/">Publications</Link></li>
                                <li><Link to="/">Laws and Regulations</Link></li>
                                <li><Link to="/">Financial Inclusion and Education</Link></li>
                                <li><Link to="/">Careers</Link></li>
                            </ul> 
                        </li> 
                      <li className={`dropdown ${menu.home && "current"}`}>
                            <Link to="/">Home</Link>
                            
                            <ul>
                                <li><Link to="/">Home One</Link> </li>
                                <li><Link to="/home-02">Home Two</Link></li>
                                <li><Link to="/home-03">Home Three</Link></li>
                                <li className="dropdown">
                                    <Link to="#">Header Styles</Link>
                                    <ul>
                                        <li><Link to="/">Header One</Link></li>
                                        <li><Link to="/home2">Header Two</Link></li>
                                        <li><Link to="home3">Header Three</Link></li>
                                    </ul>
                                </li>
                            </ul> 
                        </li> */}
                        <li className={`about ${menu.about && "current"}`}>
                            <Link to="/rw/about">Abo Turibo</Link>
                        </li> 

                        <li className={`dropdown ${menu.services && "current" && "hover"}`} 
                        ><Link to="/rw/Publications">Ibyatangajwe</Link>
                            {/* <ul>
                                <li><Link to="/mpfdetails">Monetary Policy Framework</Link></li>
                                <li><Link to="/mpcdetails">Monetary Policy Committee</Link></li>
                                <li><Link to="/mpidetails">Monetary Policy Implementation</Link></li>
                                <li><Link to="/mprdetails">Monetary Policy Reports</Link></li>
                            </ul> */}
                        </li>
                        {/* <li className={`dropdown ${menu.pages && "current"}`}><Link to="#">Financial Stability</Link>
                            <ul>
                                <li><Link to="/team">Forex bureaus</Link></li>
                                <li><Link to="/teamdetails">Deposit Guarantee Fund</Link></li>
                                <li><Link to="/creditrepair">Bank Supervision</Link></li>
                                <li><Link to="/creditaudit">Payment System</Link></li>
                                <li><Link to="/careers">Credit Information System</Link></li>
                                <li><Link to="/faqs">Microfinance Institutions</Link></li>
                                <li><Link to="/applynow">Insurance & Pension</Link></li>
                                <li><Link to="/careers">Macro-prudential Policy Framework</Link></li>
                                <li><Link to="/faqs">Licenses & Other Accreditations granted by NBR</Link></li>
                                <li><Link to="/applynow">List of Accredited External Auditors for Financial Institutions</Link></li>
                                <li><Link to="/applynow">Licenced NDFIs</Link></li>
                            </ul>
                        </li> */}
                        {/* <li className={`dropdown ${menu.pages && "current"}`}><Link to="#">Statistics</Link>
                            <ul>
                                <li><Link to="/team">Team</Link></li>
                                <li><Link to="/teamdetails">Team Details</Link></li>
                                <li><Link to="/creditrepair">Credit Repair</Link></li>
                                <li><Link to="/creditaudit">Credit Audit</Link></li>
                                <li><Link to="/careers">Careers</Link></li>
                                <li><Link to="/faqs">Faqs</Link></li>
                                <li><Link to="/applynow">Apply Now</Link></li>
                            </ul>
                        </li> */}
                        <li className={`dropdown ${menu.features && "current"}`}><Link to="/rw/contact">Mutwandikire</Link>
                            {/* <ul>
                                <li><Link to="/exchangeRate">Exchange Rate Table</Link></li>
                                <li><Link to="/personalloan">Personal Loan</Link></li>
                                <li><Link to="/loaneligibility">Loan Eligibility</Link></li>
                                <li><Link to="/homeloan">Home Loan</Link></li>
                                <li><Link to="/homeloaneligibility">Home Loan Eligibility</Link></li>
                                <li><Link to="/compareEMI">Compare EMI</Link></li>
                                <li><Link to="/monthlyloan">Monthly Calculator</Link></li>
                            </ul> */}
                        </li>
                        <li className={`dropdown ${menu.news && "current"}`}><Link to="#"><img src={flagen} width="40" height="20"/></Link>
                            {/* <ul>
                                <li><Link to="/newsmain">News</Link></li>
                                <li><Link to="/newsdetails">News Details</Link></li>
                            </ul> */}
                        </li>
                        {/* <li className={`dropdown ${menu.news && "current"}`}><Link to="#">Statistics</Link> */}
                            {/* <ul>
                                <li><Link to="/newsmain">News</Link></li>
                                <li><Link to="/newsdetails">News Details</Link></li>
                            </ul> */}
                        {/* </li> */}
                        {/* <li className={`contact ${menu.contact && "current"}`}><Link to="/contact">Careers</Link></li> */}
                    </ul>
                </div>

                      </td>
                    </tr>
                </table>
                   
               
                <div className="main-menu__right">
                    <Link to="#" onClick= {() => setmobile(true)} className="main-menu__toggler mobile-nav__toggler">
                        <i className="fa fa-bars"></i>
                    </Link>
                    {/* <Link to="#" onClick={() => setsearch(true)} className="main-menu__search search-toggler">
                        <i className="icon-magnifying-glass"></i>
                    </Link>
                    
                    <Link to="exchangeRate" className="thm-btn main-menu__btn">Exchange Rate</Link>
                    
                    <Link to="tel:926668880000" className="main-menu__contact">
                        <span className="main-menu__contact__icon">
                            <i className="icon-phone"></i>
                        </span>
                        <span className="main-menu__contact__text">
                            <strong>+ (250) 788199000 </strong>
                            Mon to Fri: 9 am to 6 pm
                        </span>
                    </Link> */}
                </div>
            </div>
        </nav>
    </div>

    
    {/* <div className={`search-popup ${search && "active"}`}>
     <div className="search-popup__overlay search-toggler" onClick={() => setsearch(false)}></div>
        <div className="search-popup__content">
            <form action="#">
                <label htmlFor="search" className="sr-only">search here</label>
                <input type="text" id="search" placeholder="Search Here..." />
                <button type="submit" aria-label="search submit" className="thm-btn">
                    <i className="icon-magnifying-glass"></i>
                </button>
            </form>
        </div>
    </div> */}

    
    <div className={`mobile-nav__wrapper ${mobile ? "expanded" : ""}`}>
            <div className="mobile-nav__overlay mobile-nav__toggler" onClick={() => setmobile(false)}></div>
            <div className="mobile-nav__content">
                <span className="mobile-nav__close mobile-nav__toggler" onClick={() => setmobile(false)}><i className="fa fa-times"></i></span>

                <div className="logo-box">
                    <Link to="/" aria-label="logo image"><img src="assets/images/logo-light.png" width="155" alt="" /></Link>
                </div>
              
                <div className="mobile-nav__container">
                    <ul className="main-menu__list">
                        <li className="current"> <Link to="/rw" onClick={() => setmobile(false)}>Ahabanza</Link></li>
                        <li><Link to="/about" onClick={() => setmobile(false)}>About</Link></li>
                        <li className="dropdown current">
                            <Link to="#" className={homeDrop ? "expanded" : ""}>Monetary Policy<button aria-label="dropdown toggler" className={homeDrop ? "expanded" : ""} onClick={() => sethomeDrop(homeDrop ? false : true)} ><i className="fa fa-angle-down"></i></button></Link>
                            {homeDrop &&
                                <ul style={{ display: "block" }}>
                               
                                    <li><Link to="/" onClick={() => setmobile(false)}>Monetary Policy Framework</Link></li>
                                    <li><Link to="/" onClick={() => setmobile(false)}>Monetary Policy Report</Link></li>
                                    <li><Link to="/" onClick={() => setmobile(false)}>Monetary Policy Strategy</Link></li>
                                    <li><Link to="/" onClick={() => setmobile(false)}>Monetary Policy Implementation</Link></li>
                                    <li><Link to="/" onClick={() => setmobile(false)}>Monetary Policy Commettee</Link></li>
                                    {/* <li className="dropdown">
                                        <Link to="#" className={headerDrop ? "expanded" : ""}>Header Styles
                                            <button aria-label="dropdown toggler" className={headerDrop ? "expanded" : ""} onClick={() => setheaderDrop(headerDrop ? false : true)}>
                                                <i className="fa fa-angle-down"></i>
                                            </button>
                                        </Link>
                                        {headerDrop &&
                                            <ul style={{ display: "block" }}>
                                                <li className="current"><Link to="/" onClick={() => setmobile(false)}>Header One</Link></li>
                                                <li><Link to="/home2" onClick={() => setmobile(false)}>Header Two</Link></li>
                                                <li><Link to="/home3" onClick={() => setmobile(false)}>Header Three</Link></li>
                                            </ul>}
                                    </li> */}
                                </ul>}
                        </li>
                      
                        <li className="dropdown">
                            <Link to="#" className={servicesDrop ? "expanded" : ""}>Financial Stability
                                <button aria-label="dropdown toggler" className={servicesDrop ? "expanded" : ""} onClick={() => setservicesDrop(servicesDrop ? false : true)}>
                                    <i className="fa fa-angle-down"></i>
                                </button>
                            </Link>
                            {servicesDrop &&
                                <ul style={{ display: "block" }}>
                                        <li><Link to="/" onClick={() => setmobile(false)}>Forex bureaus</Link></li>
                                        <li><Link to="/" onClick={() => setmobile(false)}>Financial Consumer Protection</Link></li>
                                        <li><Link to="/" onClick={() => setmobile(false)}>Bank Supervision</Link></li>
                                        <li><Link to="/" onClick={() => setmobile(false)}>Payment System</Link></li>
                                        <li><Link to="/" onClick={() => setmobile(false)}>Credit Information System</Link></li>
                                        <li><Link to="/" onClick={() => setmobile(false)}>Microfinance Institutions</Link></li>
                                        <li><Link to="/" onClick={() => setmobile(false)}>Insurance & Pension</Link></li>
                                        <li><Link to="/" onClick={() => setmobile(false)}>Macro-prudential Policy Framework</Link></li>
                                        <li><Link to="/" onClick={() => setmobile(false)}>Licenses & Other Accreditations granted by NBR</Link></li>
                                        <li><Link to="/" onClick={() => setmobile(false)}>List of Accredited External Auditors for Financial Institutions</Link></li>
                                        <li><Link to="/" onClick={() => setmobile(false)}>Licenced NDFIs</Link></li>
                                </ul>}
                        </li>
                   
                        <li className="dropdown">
                            <Link to="#" className={pagesDrop ? "expanded" : ""}>Statistics
                                <button aria-label="dropdown toggler" className={pagesDrop ? "expanded" : ""} onClick={() => setpagesDrop(pagesDrop ? false : true)}>
                                    <i className="fa fa-angle-down"></i>
                                </button>
                            </Link>
                            {pagesDrop &&
                                <ul style={{ display: "block" }}>
                                    <li><Link to="/" onClick={() => setmobile(false)}>Payment System Statistics</Link></li>
                                        <li><Link to="/" onClick={() => setmobile(false)}>Financial Sector Statistics</Link></li>
                                        <li><Link to="/" onClick={() => setmobile(false)}>External Sector Statistics</Link></li>
                                        <li><Link to="/" onClick={() => setmobile(false)}>Economic Statistics</Link></li>
                                        <li><Link to="/" onClick={() => setmobile(false)}>Public Finance Statistics</Link></li>
                                        <li><Link to="/" onClick={() => setmobile(false)}>Statistics on Financial Markets</Link></li>
                                        <li><Link to="/" onClick={() => setmobile(false)}>Monetary Statistics</Link></li>
                                        <li><Link to="/" onClick={() => setmobile(false)}>CSD statistics</Link></li>
                                        <li><Link to="/" onClick={() => setmobile(false)}>Consumer Price Index</Link></li>
                                        <li><Link to="/" onClick={() => setmobile(false)}>EDWH Web Portal</Link></li>
                                </ul>}
                        </li>
                        {/* <li className="dropdown">
                            <Link to="#" className={featuresDrop ? "expanded" : ""}>Features
                                <button aria-label="dropdown toggler" className={featuresDrop ? "expanded" : ""} onClick={() => setfeaturesDrop(featuresDrop ? false : true)}>
                                    <i className="fa fa-angle-down"></i>
                                </button>
                            </Link>
                            {featuresDrop &&
                                <ul style={{ display: "block" }}>
                                    <li><Link to="/exchangeRate" onClick={() => setmobile(false)}>Exchange rate table</Link></li>
                                    <li><Link to="/personalloan" onClick={() => setmobile(false)}>Personal Loan</Link></li>
                                    <li><Link to="/loaneligibility" onClick={() => setmobile(false)}>Loan Eligibility</Link></li>
                                    <li><Link to="/homeloan" onClick={() => setmobile(false)}>Home Loan</Link></li>
                                    <li><Link to="/homeloaneligilibity" onClick={() => setmobile(false)}>Home Loan Eligibility</Link></li>
                                    <li><Link to="/compareEMI" onClick={() => setmobile(false)}>Compare EMI</Link></li>
                                    <li><Link to="/monthlycalculator" onClick={() => setmobile(false)}>Monthly Calculator</Link></li>
                                </ul>}
                        </li> */}
                        {/* <li className="dropdown">
                        <Link to="#" className={newsDrop ? "expanded" : ""}>News
                            <button aria-label="dropdown toggler" className={newsDrop ? "expanded" : ""} onClick={() => setnewsDrop(newsDrop ? false : true)}>
                                <i className="fa fa-angle-down"></i>
                            </button>
                        </Link>
                            {newsDrop &&
                                <ul style={{ display: "block" }}>
                                    <li><Link to="/newsmain" onClick={() => setmobile(false)}>News</Link></li>
                                    <li><Link to="/newsdetails" onClick={() => setmobile(false)}>News Details</Link></li>
                                </ul>}
                        </li> */}
                        <li>
                            <Link to="/" onClick={() => setmobile(false)}>Publications</Link>
                        </li>
                        <li>
                            <Link to="/" onClick={() => setmobile(false)}>Laws and Regulations</Link>
                        </li>
                        <li>
                            <Link to="/" onClick={() => setmobile(false)}>Careers</Link>
                        </li>
                    </ul>

                </div>
                

                <ul className="mobile-nav__contact list-unstyled">
                    <li>
                        <i className="icon-email"></i>
                        <Link to="mailto:info@bnr.rw">info@bnr.rw</Link>
                    </li>
                    <li>
                        <i className="icon-telephone"></i>
                        <Link to="tel:+250788199000">+ (250) 788199000 </Link>
                    </li>
                </ul>

                <div className="mobile-nav__social">
                    <Link to="#"><i className="fab fa-twitter"></i></Link>
                    <Link to="#"><i className="fab fa-facebook"></i></Link>
                    <Link to="#"><i className="fab fa-pinterest"></i></Link>
                    <Link to="#"><i className="fab fa-instagram"></i></Link>
                </div>

            </div>
          
        </div>
    
    </>
  )
}

export default NavbarKinya