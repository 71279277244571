import React, { useEffect, useState } from 'react'
import cross from '../../assets/images/backgrounds/cross.svg'
import axios from 'axios'
import { useNavigate } from 'react-router-dom';

function SearchLinks() {
    const [search, setSearch] = useState("")
    const [links, setLinks] = useState([]); 
  
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/resultdata');
  };

    useEffect(() => {
         axios.get(`/links`)
             .then((response) => {
                 let postData = response.data
                 console.log(postData);
                 setLinks(postData)
             })
             .catch((error) => console.log(error))
     }, [])
  const [searchResults, setSearchResults] = useState(links)
  const [searchStatus, setSearchStatus] = useState(false)

//   useEffect(() => {
//     document.getElementById('search').addEventListener('focus', function () {
//       // Replace 'newpage.html' with the URL of the page you want to navigate to
//       window.location.href = '/resultdata';
//     })
// });
  useEffect(() => {
    findResult()
      }, [search,findResult])
      
      function findResult() {
        console.log(search) // KSDJafk login
        if(!search) {
          setSearchResults([])
          setSearchStatus(false)
          return
        }
        setSearchResults(links.filter(link => link.keyword.toLowerCase().trim().includes(search.toLowerCase().trim() || '')))
        return
  }
  
  return (
    <div className="fsl_search_container">
      <input
        value={search}
        onClick={handleClick}
        autoComplete='off' style={{ width: '80%', borderTopLeftRadius: '50%', borderBottomLeftRadius: '50%' }}
        type="text"
        id="search"
        placeholder="Search here..." />
      {searchStatus && <img style={{ width: '20px' }} alt='close' src={cross}

        className='fsl_search_result_close' />}
        {searchStatus&&<div className={searchResults.length<3?
      'fsl_search_result_container':
      'fsl_search_result_container_2'  
      }>
        {searchResults.map((result, ky)=>(
            <a className='fsl_search_link' style={{color: '#753918', padding: '0 10px', margin:'0', fontWeight: 'bolder', borderBottom: '1px solid #0003'}} key={ky} href={`/search?q=${result.link.split("/")[1]}`}>{result.keyword}</a>
          ))}
        </div>}
    </div>
  )
}

export default SearchLinks