import BGPH1 from '../../assets/images/backgrounds/page-header-bg-1-1.jpg'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useParams } from 'react-router-dom'
import React, { useState, useEffect, useMemo }   from 'react'
import axios from 'axios'
//import { Container } from "react-bootstrap";
//import Header from "components/Header";
import { TableHeader, Pagination, Search } from '../DataTable';
import Navbar from '../Navbar/Main'
import Footer from '../Footer/Main'
import SFsidebar from './sfsidebar'
import SFquery from './sfquery'
// import Service from '../Main'
// import onSubmitForm from '../Main'

export const Fpcs = () => {
    

   const [doc, setDoc] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });

    const ITEMS_PER_PAGE = 10;

    const headers = [
        { name: "Date", field: "Date", sortable: false },
        { name: "Documents", field: "Documents", sortable: false },
    ];
   const [loading, setLoading] = useState(true);
   useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`/Other_Report`);
        //const response = await fetch(`/annual_report`);
        const docData = await response.json();
        setDoc(docData);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []); 
   const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    });
  };
  const docData = useMemo(() => {
    let computeddoc = doc;

    if (search) {
        computeddoc = computeddoc.filter(
                d =>
                d.date_last_modified.toLowerCase().includes(search.toLowerCase()) ||
                d.name.toLowerCase().includes(search.toLowerCase())
        );
    }

    setTotalItems(computeddoc.length);

    //Sorting doc
    if (sorting.field) {
        const reversed = sorting.order === "asc" ? 1 : -1;
        computeddoc = computeddoc.sort(
            (a, b) =>
                reversed * a[sorting.field].localeCompare(b[sorting.field])
        );
    }

    //Current Page slice
    return computeddoc.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
}, [doc, currentPage, search, sorting]);



        const [clicked, setClicked] = useState(0);

  return (
    <>
    <Navbar/>
        <div className="stricky-header stricked-menu main-menu">
            <div className="sticky-header__content"></div>
        </div>
        <section className="page-header">
            <div className="page-header__bg"    ></div>
            <div className="container">
                <ul className="thm-breadcrumb list-unstyled">
                    <li><Link to="/">Home</Link></li>
                    <li><span> Foreign Private Capital Survey</span></li>
                </ul>
                {/* <div className="surrounding slider-one__title">
                BNR Economic Review is devoted to publishing articles of interest to policymakers, academics and practitioners on a wide range of economic issues with policy relevance. 
		   The journal welcomes empirical and theoretical studies with an emphasis on central bank responsibilities and other economic policies. 
		   The journal aims to promote interaction between researchers and policymakers and contribute to knowledge dissemination.  
                </div> */}
            </div>
        </section>
        {/* <div  className="about-four__shape"></div>
  <div  className="about-five__shape-2"></div> 
  <div  className="about-five__shape-1"></div>
  <div  className="about-five__shape-9"></div> 
  <div  className="about-five__shape-0"></div> */}
        <section className="service-details pt-30 pb-30">
            <div className="container">
                <div className="row row-gutter-y-50 contact-info-one">
                    <div className="col-lg-8">
                        {/* <div className="service-details__image">
                            <a href="/monetarypolicy" className="service-details__icon"><i className="fa fa-arrow-left"></i></a>
                        </div> */}
                        <div className="service-details__content">
                       
                            <div className="row row-gutter-y-30">
                                <div className="col-md-12">
                 
                      {/* <img src={p.image} alt="" /> */}
                      <h3 className="service-details__title">Get to know about Foreign Private Capital Survey</h3>
                      {/* <img src="../../../assets/images/services/service-d-1-1.png" alt="" /> */}
                      {/* <img src= {`../${p.image}`} alt="" /> */}
                      
                                      <p>
                                          The National Bank of Rwanda (NBR), in collaboration with the Rwanda Development Board (RDB) 
										  and the National Institute of Statistics of Rwanda (NISR) 
										  conduct annually the Foreign Private Capital (FPC) survey. 
 </p>

<p> The survey provides comprehensive data on various aspects on private sector foreign investment in Rwanda divided 
into three main categories namely Foreign Direct Investment (FDI), 
Foreign Portfolio Investment (FPI) and Other Investment (OI). 
In addition, the survey provides information on investment by sectors of economic activities, 
as well as source of investment.</p>

<p> The information provided from the FPC survey are useful for policy formulations 
and compilation of other statistics such the Balance of Payment (BOP), International Investment Position (IIP), 
Private Sector External Debt (PSED) and Foreign Affiliates Trade (FATS) Statistics. </p>

<p> For more information and clarification about the FPC report, please contact <a href="#">bopstat@bnr.rw</a></p>
 {loading ? (
                
                <p></p>
                    ) : docData && docData.length > 0 ? (
                        <>
           
                       <div className="loan-comparison__body" id="compare-emi-1">
                <div className="compare-table_doc table-responsive">
                        <div className="benefit-two__tab__doc2">
                        <ul className="list-unstyled">
                                <li className='row'> 
                                    <Search className="search" onSearch={value => { setSearch(value); setCurrentPage(1); }}/>
                                 {/* <Route path="/esstatistics" element={<Esstatistics />} /><span><Link className='linkcolor' to="/pension">Link for Laws and Regulations</Link></span> */}
                                 </li> 
                            </ul>
                        </div>
                  <table className="table">
                  <TableHeader
                            headers={headers}
                            onSorting={(field, order) =>
                                setSorting({ field, order })
                            }
                        />
                                <tbody>
                                   
                         {
                                           docData ? docData.map((d, index) => (
                                            
                                            <tr key="">

                                       <td>{formatDate(d.publish_date)}</td>

                                       <td>

                                       <div className="accrodion-grp service-details__accrodion" data-grp-name="service-details__accrodion-1">

   

                               <div className={`accrodion  wow fadeInUp ${index === clicked && "active"}`} key={index} data-wow-delay="0ms">

                                   <div className="accrodion-title" onClick={() => setClicked(index)}>

                                       <h4>{d.name}</h4>

                                       <span className="accrodion-icon"></span>

                                   </div>

                                   {index === clicked &&

                                   <div className="accrodion-content">

                                       <div className="inner_doc" >

                                       <p>{d.summary}</p>

                                       {/*  <ul className="service-sidebar__menu">

                                           <li><b>Author:</b> <i>{d.author}</i></li>
                                             
                                            <li><Link to={`${d.link}`} target="_blank" rel="noopener noreferrer">More Details</Link></li>
                                         
                                             <b>Document to download:</b> <i> <Link to={d.file} target="_blank" rel="noopener noreferrer"> {d.file}</Link></i>
                                            
                                        </ul>
                                        */}
                                        <p></p>
                                        <Link to={d.file} target="_blank" rel="noopener noreferrer">More Details</Link>
                                    </div>

                                   </div>}

                               </div>

                           </div>

                                       </td>

                                   </tr>                                     
                               )) : <tr key=""><td>Loading.....</td></tr>
                            }
                                </tbody>
                  </table>
                  <div className="col-md-4">
                            <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div>
                            </div>
                            </div>
                            </>  
                      ) : (
                        <p></p>
                      )}
                            </div>
                            </div>
                    </div>
                    </div>
                    
                    <div className="col-lg-4">
                    <SFsidebar/>
                    </div>
                </div>
            </div>
        </section>
        <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link>
        <Footer/>
    </>
  )
}
