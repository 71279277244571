// src/components/Chatbot.js

import React, { useEffect } from 'react';

const Chatbot = () => {
  useEffect(() => {
    // Create and append the script tag to load the chatbot
    const script = document.createElement('script');
    script.src = 'https://embed.proto.cx/index.umd.js';
    script.type = 'text/javascript';
    script.async = true;

    // Define the onload behavior for the script
    script.onload = () => {
      if (window.proto) {
        window.proto.init({
          id: '01J41W3DJS1Q07YW4YHRCX78A7'
        });
      }
    };

    // Append the script to the document head
    document.head.appendChild(script);

    // Clean up by removing the script when the component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <div>
      {/* The chatbot will be injected into the page */}
    </div>
  );
};

export default Chatbot;
