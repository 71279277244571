import BGPH1 from '../../assets/images/backgrounds/page-header-bg-1-1.jpg'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useParams } from 'react-router-dom'
import React, { useState, useEffect, useMemo } from 'react'
import axios from 'axios'
//import { Container } from "react-bootstrap";
//import Header from "components/Header";
import { TableHeader, Pagination, Search } from '../DataTable';
import Navbar from '../Navbar/Main'
import Footer from '../Footer/Main'
import SFquery from './sfquery'
import SFsidebar from './sfsidebar'
// import Service from '../Main'
// import onSubmitForm from '../Main'

export const Otherreports = () => {


    const [doc, setDoc] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });

    const ITEMS_PER_PAGE = 10;

    const headers = [
        { name: "Date", field: "Date", sortable: false },
        { name: "Documents", field: "Documents", sortable: false },
    ];

    useEffect(() => {
        axios.get(`/otherreports`)
            .then((response) => {
                let docData = response.data
                console.log(docData);
                setDoc(docData)
            })
            .catch((error) => console.log(error))
    }, [])
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });
    };
    const docData = useMemo(() => {
        let computeddoc = doc;

        if (search) {
            computeddoc = computeddoc.filter(
                d =>
                    d.date_last_modified.toLowerCase().includes(search.toLowerCase()) ||
                    d.name.toLowerCase().includes(search.toLowerCase())
            );
        }

        setTotalItems(computeddoc.length);

        //Sorting doc
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computeddoc = computeddoc.sort(
                (a, b) =>
                    reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        //Current Page slice
        return computeddoc.slice(
            (currentPage - 1) * ITEMS_PER_PAGE,
            (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
        );
    }, [doc, currentPage, search, sorting]);



    const [clicked, setClicked] = useState(0);

    return (
        <>
            <Navbar />
            <div className="stricky-header stricked-menu main-menu">
                <div className="sticky-header__content"></div>
            </div>
            <section className="page-header">
                <div className="page-header__bg" style={{ backgroundImage: `url(${BGPH1})` }}></div>
                <div className="container">
                    <ul className="thm-breadcrumb list-unstyled">
                        <li><Link to="/">Home</Link></li>
                        <li><span>Useful links</span></li>
                    </ul>
                    <div className="surrounding slider-one__title">
                        This section presents a curated collection of useful links related to
                        sustainable finance, offering stakeholders a convenient gateway to relevant
                        external resources as well as related databases. Further, these links provide
                        access to valuable information, insights, and collaborative platforms that
                        align with the National Bank of Rwanda's commitment
                        to advancing sustainable practices in the financial sector
                    </div>
                </div>
            </section>
            {/* <div  className="about-four__shape"></div>
  <div  className="about-five__shape-2"></div> 
  <div  className="about-five__shape-1"></div>
  <div  className="about-five__shape-9"></div> 
  <div  className="about-five__shape-0"></div> */}
            <section className="service-details pt-30 pb-30">
                <div className="container">
                    <div className="row row-gutter-y-50 contact-info-one">

                        <div className="col-lg-8">
                            {/* <div className="service-details__image">
                            <a href="/monetarypolicy" className="service-details__icon"><i className="fa fa-arrow-left"></i></a>
                        </div> */}
                            <div className="service-details__content">

                                <div className="row row-gutter-y-30">
                                    <div className="col-md-12">

                                        {/* <img src={p.image} alt="" /> */}
                                        <h3 className="service-details__title">Useful links</h3>
                                        {/* <img src="../../../assets/images/services/service-d-1-1.png" alt="" /> */}
                                        {/* <img src= {`../${p.image}`} alt="" /> */}

                                        <p> This section presents a curated collection of useful links related to
                                            sustainable finance, offering stakeholders a convenient gateway to relevant
                                            external resources as well as related databases. Further, these links provide
                                            access to valuable information, insights, and collaborative platforms that
                                            align with the National Bank of Rwanda's commitment
                                            to advancing sustainable practices in the financial sector</p>
                                        <div className='documentation-content'>
                                            <ol className='className="list-unstyled'>
                                                <li><Link to="https://www.ngfs.net/en" target="_blank">https://www.ngfs.net/en</Link></li>
                                                <li><Link to="https://climatechange.gov.rw" target="_blank">The Paris Agreement (climatechange.gov.rw)</Link> </li>
                                                <li><Link to="https://kifc.rw/wpcontent/uploads/2023/01/KIFCSustainableFinanceRoadmap" target="_blank">https://kifc.rw/wpcontent/uploads/2023/01/KIFCSustainableFinanceRoadmap</Link></li>
                                                <li><Link to="http://www.fonerwa.org/" target="_blank">http://www.fonerwa.org/</Link></li>
                                                <li><Link to="https://www.environment.gov.rw/" target="_blank">https://www.environment.gov.rw/</Link></li>
                                            </ol>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <SFsidebar />
                        </div>
                        <SFquery />
                    </div>
                </div>
            </section>
            <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link>
            <Footer />
        </>
    )
}
