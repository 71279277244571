import logo from '../../assets/images/logo-dark.png'
import React, { useRef, useState, useEffect, useMemo } from "react"
import axios from 'axios'
import { Link } from 'react-router-dom'



const Footer = () => {
    
const [link, setPost] = useState()
useEffect(() => {
    axios.get('/prodocuments')
        .then((response) => {
            let postData = response.data
            console.log(postData);
            setPost(postData)
        })
        .catch((error) => console.log(error))
}, [])

  return (
    <>
        <footer className="main-footer">
            <div className="container">
                <div className="row row-gutter-y-30">
                    <div className="col-lg-3 col-md-6">
                        <div className="footer-widget__info">
                            <img src={logo} width="180" height="180" alt="BNR"/>
                            {/* <ul className="list-unstyled footer-widget__info">
                                <li>
                                    <i className="icon-email"></i>
                                    <Link to="mailto:info@bnr.rw ">info@bnr.rw</Link>
                                </li>
                                <li>
                                    <i className="icon-telephone"></i>
                                    <Link to="tel:+250788199000">+ (250) 788199000  </Link>
                                </li>
                            </ul>
                            <div className="footer-widget__social">
                                <Link to="#"><i className="fab fa-twitter"></i></Link>
                                <Link to="#"><i className="fab fa-facebook"></i></Link>
                                <Link to="#"><i className="fab fa-pinterest"></i></Link>
                                <Link to="#"><i className="fab fa-instagram"></i></Link>
                            </div> */}
                           
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="footer-widget footer-widget--links">
                            <h3 className="footer-widget__title">
                                Quick links
                            </h3>
                            <ul className="list-unstyled footer-widget__menu">
                                <li><Link to="https://www.bnr.rw/documents/CITIZEN_CHARTER_VERSION_4_FINAL.pdf" target="_blank"><i className="fa fa-angle-right"></i> Citizen Charter</Link></li>
                                <li><Link to="/Calendar"><i className="fa fa-angle-right"></i> Event Calendar</Link></li>
                                <li><Link to="https://e-recruitment.bnr.rw/"><i className="fa fa-angle-right"></i> Careers</Link></li>
                                <li><Link to="/contact"><i className="fa fa-angle-right"></i> Contact</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="footer-widget footer-widget--links">
                            <h3 className="footer-widget__title">
                            Quick links
                            </h3>
                            <ul className="list-unstyled footer-widget__menu">
                                <li><Link to='https://fininclusion.bnr.rw/inclusion' target='_blank'><i className="fa fa-angle-right"></i> Financial Inclusion Dashboard</Link></li>
                                <li><Link to='/infosec'><i className="fa fa-angle-right"></i> Information Security Policy</Link></li>
                                <li><Link to='/statuse'><i className="fa fa-angle-right"></i> Terms of Use for Official Statistics</Link></li>
                                <li><Link to='https://e-correspondence.bnr.rw/' target='_blank'><i className="fa fa-angle-right"></i> e-Correspondence</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="footer-widget footer-widget--links">
                            <h3 className="footer-widget__title">
                            Procurement
                            </h3>
                            <ul className="list-unstyled footer-widget__menu">
                            {/* {
                                 link ? link.map((l, index) => (
                                    <>
                                    <li key={l.id}>
                                    <Link to={`${l.link}`}><i className="fa fa-angle-right"></i> {l.title}</Link>
                                    </li>
                                    </>
                                    )) : <p>loading...</p>
                                } */}
                                <li><Link to="/procurementPlan"><i className="fa fa-angle-right"></i> Procurement Plan</Link></li>
                                <li><Link to="/tendernotice"><i className="fa fa-angle-right"></i> Tender Notices</Link></li>
                                <li><Link to="/specificrule"><i className="fa fa-angle-right"></i> Specific Rules</Link></li>
                                <li><Link to="/procurementPortal"><i className="fa fa-angle-right"></i> e-Procurement Portal</Link></li>
                            </ul>
                        </div>
                    </div>
                    {/* <div className="col-lg-3 col-md-6">
                        <div className="footer-widget footer-widget--time">
                            <h3 className="footer-widget__title">
                                Timing
                            </h3>
                            <p className="footer-widget__text">
                                Mon Fri: 7:00am - 6:00pm <br/> Saturday: 9:00am - 5:00pm <br /> Sunday: Closed
                            </p>
                            <div className="footer-widget__social">
                                <Link to="#"><i className="fab fa-twitter"></i></Link>
                                <Link to="#"><i className="fab fa-facebook"></i></Link>
                                <Link to="#"><i className="fab fa-pinterest"></i></Link>
                                <Link to="#"><i className="fab fa-instagram"></i></Link>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </footer>
        <div className="bottom-footer">
            <div className="container">
                <p className="bottom-footer__text text-center">Copyright &copy; NBR {new Date().getFullYear()} All Rights Reserved.</p>
                
            </div>
        </div>
        
    </>
  )
}

export default Footer
