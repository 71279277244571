import BGPH1 from '../../assets/images/backgrounds/page-header-bg-1-1.jpg'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useParams } from 'react-router-dom'
import React, { useState, useEffect, useMemo }   from 'react'
import FEsidebar from './fesidebar'
import axios from 'axios'
//import { Container } from "react-bootstrap";
//import Header from "components/Header";
import { TableHeader, Pagination, Search } from '../DataTable';
import Navbar from '../Navbar/Main'
import Footer from '../Footer/Main'
import FEquery from './fequery'
// import Service from '../Main'
// import onSubmitForm from '../Main'

export const Financialinnovation = () => {
    

   const [doc, setDoc] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });

    const ITEMS_PER_PAGE = 10;

    const headers = [
        { name: "Date", field: "Date", sortable: false },
        { name: "Documents", field: "Documents", sortable: false },
    ];

   const [loading, setLoading] = useState(true);
   useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`/fidev`);
        const docData = await response.json();
        setDoc(docData);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []); 
   const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    });
  };
  const docData = useMemo(() => {
    let computeddoc = doc;

    if (search) {
        computeddoc = computeddoc.filter(
                d =>
                d.date_last_modified.toLowerCase().includes(search.toLowerCase()) ||
                d.name.toLowerCase().includes(search.toLowerCase())
        );
    }

    setTotalItems(computeddoc.length);

    //Sorting doc
    if (sorting.field) {
        const reversed = sorting.order === "asc" ? 1 : -1;
        computeddoc = computeddoc.sort(
            (a, b) =>
                reversed * a[sorting.field].localeCompare(b[sorting.field])
        );
    }

    //Current Page slice
    return computeddoc.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
}, [doc, currentPage, search, sorting]);



        const [clicked, setClicked] = useState(0);

  return (
    <>
    <Navbar/>
        <div className="stricky-header stricked-menu main-menu">
            <div className="sticky-header__content"></div>
        </div>
        <section className="page-header">
            <div className="page-header__bg"    ></div>
            <div className="container">
                <ul className="thm-breadcrumb list-unstyled">
                    <li><Link to="/">Home</Link></li>
                    <li><span>Financial Innovation and Development</span></li>
                </ul>
            </div>
        </section>
        {/* <div  className="about-four__shape"></div>
  <div  className="about-five__shape-2"></div> 
  <div  className="about-five__shape-1"></div>
  <div  className="about-five__shape-9"></div> 
  <div  className="about-five__shape-0"></div> */}
        <section className="service-details pt-30 pb-30">
            <div className="container">
                <div className="row row-gutter-y-50 contact-info-one">
                    <div className="col-lg-4">
                    <FEsidebar/>
                    </div>
                    <div className="col-lg-8">
                        {/* <div className="service-details__image">
                            <a href="/monetarypolicy" className="service-details__icon"><i className="fa fa-arrow-left"></i></a>
                        </div> */}
                        <div className="service-details__content">
                       
                            <div className="row row-gutter-y-30">
                                <div className="col-md-12">
                 
                      {/* <img src={p.image} alt="" /> */}
                      <h3 className="service-details__title">Financial Innovation and Development</h3>
                      {/* <img src="../../../assets/images/services/service-d-1-1.png" alt="" /> */}
                      {/* <img src= {`../${p.image}`} alt="" /> */}
                      
                            <p>a. Open Finance Feasibility Study:</p>
                            <p>i. Introduction:</p>
                            <p>In today's digital era, data holds a dual significance, driving innovation and serving as a key competitive asset. 
                               Recognizing the strategic importance of understanding data ownership and sharing, regulators worldwide, including Rwanda, are turning to open finance—a framework facilitating data exchange among Financial Service Providers (FSPs) and third-party providers (TPPs) with consumer consent. 
                               This model relies on technologies like APIs and varies from voluntary to mandatory adoption. 
                               Despite its potential benefits, concerns over data privacy and security have prompted global adoption of data protection regulations, empowering consumers while aligning with open finance principles. 
                               However, as countries, including those in Africa, move towards open finance implementation, stakeholders face a balancing act, needing to grasp both its opportunities and risks. While open finance promises tailored products, expanded customer bases, and enhanced financial inclusion, the risks of data breaches and consumer fraud loom large. 
                               Decisions on open finance intervention must be informed by context-specific feasibility assessments to navigate these complexities effectively.</p>
                            <p>Key Findings:</p>
                            <p>
                                <ul><li>Market Context: Rwanda's diverse financial sector, marked by numerous banks, mobile money operators, microfinance institutions, and high mobile phone penetration, presents fertile ground for open finance. With 77% financial inclusion and 30% digital payments, there's a substantial pool of electronic transaction data. 
                                    However, challenges like low smartphone penetration and a skewed fintech landscape toward payments hinder open finance implementation. Tailored solutions are needed to address these barriers, emphasizing collaboration between fintechs and traditional institutions. 
                                    Despite hurdles, open finance holds promise in Rwanda to enhance financial access and innovation, particularly for underserved segments like MSMEs.</li></ul></p>
                                <p><ul><li>Regulatory context: Rwanda's supportive environment for open finance is grounded in constitutional principles, policy objectives, and regulatory frameworks. 
                                    Constitutionally, Article 23 prioritizes privacy consent, laying the foundation for open finance. Policy objectives align with open finance outcomes, focusing on fintech innovation, financial inclusion, and data revolution. 
                                    Regulatory frameworks encompass data protection, cybersecurity, and consumer protection, requiring minor adjustments to fully support open finance. The National Bank of Rwanda (BNR) is empowered to lead open finance initiatives, ensuring consumer rights, and enforcing data-sharing standards. 
                                    However, effective implementation requires coordinated efforts among policymakers and regulators due to its cross-cutting nature.</li></ul></p>

                                <p><ul><li>Demand side context: Understanding consumer needs and preferences is crucial for open finance success.
                                          Qualitative research in Rwanda reveals a willingness to share personal financial data if protected and transparent. Account aggregation and alternative credit are favored use cases, indicating a clear understanding of value. 
                                          Trust in traditional FSPs is high, bolstered by BNR compliance, while low-income earners express concerns about potential exclusion and data security. To promote adoption, open finance must prioritize accessibility, transparency, and clear benefits, ensuring regulatory compliance and addressing consumer apprehensions. 
                                          Ultimately, fostering trust and delivering tangible benefits will drive widespread acceptance and engagement with open finance initiatives.</li></ul></p>
                                <p><ul><li>Supply side context: Bilateral partnerships in Rwanda's financial sector, primarily focused on payment services, face challenges due to lengthy processes, complex contracts, and inconsistent data protection. 
                                          Variations in digital readiness among institutions suggest a need for incentives to encourage participation in open finance initiatives, especially for smaller entities. 
                                          Incentives like charging for data requests and access to government datasets could drive sustainable participation, addressing potential reluctance from larger players. Streamlining partnership processes and offering meaningful incentives are crucial steps toward fostering innovation and efficient collaborations in the financial sector, even without a mandated open finance framework.</li></ul></p>
                                          <p>b.	CBDC Position Paper:</p>
                                          <p>i. Introduction:</p>
                                          <p>This Position Paper summarizes the key findings of the Feasibility Study on Central Bank Digital Currency (CBDC) in Rwanda in terms of economic, functional, legal, and financial perspectives. 
                                            It applies a quantitative analysis of opportunities, risks, and challenges to validate the feasibility of a CBDC in Rwanda. The paper also identifies a potential CBDC design and suggests a way forward.</p>
                                        <p>The four identified Sweet Spots for CBDC, within the Rwandan context, are the following:</p>
                                        <p><ul><li>To increase resilience against possible network outages, power failures and natural disasters.</li></ul></p>
                                        <p><ul><li>To improve innovation and competition.</li></ul></p>
                                        <p><ul><li>To contribute to achieving the cashless economy national initiative over time.</li></ul></p>
                                        <p><ul><li>To develop faster, cheaper, more transparent, and more inclusive cross-border remittances</li></ul></p>           
                                    
                        
                            {loading ? (
                
                <p></p>
                    ) : docData && docData.length > 0 ? (
                        <>  
                       <p className="service-details__text">The following are documents related to Financial Innovation and Development</p>
                      
                          
                        
               <div className="loan-comparison__body" id="compare-emi-1">
                <div className="compare-table_doc table-responsive">
                        <div className="col-md-4 d-flex flex-row-reverse">
                            <Search
                                onSearch={value => {
                                    setSearch(value);
                                    setCurrentPage(1);
                                }}
                            />
                        </div>
                  <table className="table">
                  <TableHeader
                            headers={headers}
                            onSorting={(field, order) =>
                                setSorting({ field, order })
                            }
                        />
                                <tbody>
                                   
                         {
                                           docData ? docData.map((d, index) => (
                                            
                                            <tr key="">
                                                <td>{formatDate(d.date_last_modified)}</td>
                                                <td>
                                                <div className="accrodion-grp service-details__accrodion" data-grp-name="service-details__accrodion-1">

                                        <div className={`accrodion  wow fadeInUp ${index === clicked && "active"}`} key={index} data-wow-delay="0ms">
                                            <div className="accrodion-title" onClick={() => setClicked(index)}>
                                                <h4>{d.name}</h4>
                                                <span className="accrodion-icon"></span>
                                            </div>
                                            {index === clicked && 
                                            <div className="accrodion-content">
                                                <div className="inner_doc" >
                                                    <p>{d.summary}</p>
                                                    <Link to={d.file} target="_blank">Download/View <i className="fa fa-file-pdf"></i></Link>
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                                </td>
                                            </tr>
                                     
                               )) : <tr key=""><td>Loading.....</td></tr>
                            }
                                </tbody>
                  </table>
                  <div className="col-md-4">
                            <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div>
                            </div>
                            </div>
                            </>  
                      ) : (
                        <p></p>
                      )}
                            </div>
                            </div>
                    </div>
                    </div>
                </div>
            </div>
        </section>
        <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link>
        <Footer/>
    </>
  )
}
