import BGPH1 from '../../assets/images/backgrounds/page-header-bg-1-1.jpg'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useParams } from 'react-router-dom'
import React, { useState, useEffect, useMemo }   from 'react'
import FSsidebar from './fssidebar'
import axios from 'axios'
//import { Container } from "react-bootstrap";
//import Header from "components/Header";
import { TableHeader, Pagination, Search } from '../DataTable';
import Navbar from '../Navbar/Main'
import Footer from '../Footer/Main'
import FSquery from './fsquery'
// import Service from '../Main'
// import onSubmitForm from '../Main'

export const Pensions = () => {
    

   const [doc, setDoc] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });

    const ITEMS_PER_PAGE = 10;

    const headers = [
        { name: "Date", field: "Date", sortable: false },
        { name: "Documents", field: "Documents", sortable: false },
    ];

   useEffect(() => {
       axios.get(`/fspens`)
           .then((response) => {
               let docData = response.data
               console.log(docData);
               setDoc(docData)
           })
           .catch((error) => console.log(error))
   }, [])
   const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    });
  };
  const docData = useMemo(() => {
    let computeddoc = doc;

    if (search) {
        computeddoc = computeddoc.filter(
                d =>
                d.date_last_modified.toLowerCase().includes(search.toLowerCase()) ||
                d.name.toLowerCase().includes(search.toLowerCase())
        );
    }

    setTotalItems(computeddoc.length);

    //Sorting doc
    if (sorting.field) {
        const reversed = sorting.order === "asc" ? 1 : -1;
        computeddoc = computeddoc.sort(
            (a, b) =>
                reversed * a[sorting.field].localeCompare(b[sorting.field])
        );
    }

    //Current Page slice
    return computeddoc.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
}, [doc, currentPage, search, sorting]);



        const [clicked, setClicked] = useState(0);

  return (
    <>
    <Navbar/>
        <div className="stricky-header stricked-menu main-menu">
            <div className="sticky-header__content"></div>
        </div>
        <section className="page-header">
            <div className="page-header__bg"    ></div>
            <div className="container">
                <ul className="thm-breadcrumb list-unstyled">
                    <li><Link to="/">Home</Link></li>
                    <li><span>Pension</span></li>
                </ul>
                <div className="surrounding slider-one__title">
                The National Bank of Rwanda is in charge of analyzing the functioning of the financial sphere, 
                                of which the Pension Sector is part, to ensure sound and stable financial sector in 
                                general and a sound, responsive, and resilient pension sub-sector in particular.  
                                This is achieved through a legal and regulatory framework that follows principles developed by the
                                International Organization of Pension Supervisors (IOPS). 
                </div>
            </div>
        </section>
        {/* <div  className="about-four__shape"></div>
  <div  className="about-five__shape-2"></div> 
  <div  className="about-five__shape-1"></div>
  <div  className="about-five__shape-9"></div> 
  <div  className="about-five__shape-0"></div> */}
        <section className="service-details pt-30 pb-30">
            <div className="container">
                <div className="row row-gutter-y-50 contact-info-one">
                    <div className="col-lg-8">
                        {/* <div className="service-details__image">
                            <a href="/monetarypolicy" className="service-details__icon"><i className="fa fa-arrow-left"></i></a>
                        </div> */}
                        <div className="service-details__content">
                       
                            <div className="row row-gutter-y-30">
                                <div className="col-md-12">
                 
                      {/* <img src={p.image} alt="" /> */}
                      <h3 className="service-details__title">Pension</h3>
                      {/* <img src="../../../assets/images/services/service-d-1-1.png" alt="" /> */}
                      {/* <img src= {`../${p.image}`} alt="" /> */}
                      
                            <p>The National Bank of Rwanda is in charge of analyzing the functioning of the financial sphere, 
                                of which Insurance and Pension Sector is part, to ensure sound and stable financial sector in general. 
                                This is achieved through a legal and regulatory framework that follows principles developed by the 
                                International Organization of Pension Supervisors (IOPS). </p><br></br>

                                <p className="service-details__text"><b>Pension Supervision:</b></p>
                                <p>
                                The National Bank of Rwanda (NBR) plays a pivotal role in supervising and regulating the insurance and pension sub-sectors. 
                                As part of its mandate, the NBR meticulously examines the functioning of the 
                                pension schemes and pension services providers to uphold the integrity and stability of the broader financial landscape. </p><br></br>
                                
                                <p>In Rwanda, the NBR regulates and supervises a range of institutions operating within the pension sector. These include:</p><br></br>
                                <p>
                                <ul><li>Pension schemes,</li>
                                <li>Pension service providers (custodians, administrators, fund managers and Trustees)</li>
                                <li>And any other person/entity involved in providing insurance and pension services to the public. </li>
                                </ul>
                                </p>
            
                                <p>
                                This oversight activity is conducted through three main activities, namely licensing, off-site surveillance and on-site inspection of regulated insurance and pension market players. 
                                It is carried out within a robust legal and regulatory framework based on Law N° 030/2021 of 30/06/2021 
                                governing the organization of insurance business and Law no. N° 05/2015 of 30/03/2015 governing the organization of pension schemes and their implementing regulations.</p><br></br>

                       
                       <p className="service-details__text"><b>The following are documents related Pension</b></p>
                                         
                        
               <div className="loan-comparison__body" id="compare-emi-1">
                <div className="compare-table_doc table-responsive">
                        <div className="benefit-two__tab__doc2">
                            <ul className="list-unstyled">
                                <li className='row'> 
                                    <Search className="search" onSearch={value => { setSearch(value); setCurrentPage(1); }}/>
                                 <span><Link className='linkcolor' to="/pension">Link for Laws and Regulations</Link></span></li> 
                            </ul>
                        </div>
                  <table className="table">
                  <TableHeader
                            headers={headers}
                            onSorting={(field, order) =>
                                setSorting({ field, order })
                            }
                        />
                                <tbody>
                                   
                         {
                                           docData ? docData.map((d, index) => (
                                            
                                            <tr key="">
                                                <td>{formatDate(d.date_last_modified)}</td>
                                                <td>
                                                <div className="accrodion-grp service-details__accrodion" data-grp-name="service-details__accrodion-1">

                                        <div className={`accrodion  wow fadeInUp ${index === clicked && "active"}`} key={index} data-wow-delay="0ms">
                                            <div className="accrodion-title" onClick={() => setClicked(index)}>
                                                <h4>{d.name}</h4>
                                                <span className="accrodion-icon"></span>
                                            </div>
                                            {index === clicked && 
                                            <div className="accrodion-content">
                                                <div className="inner_doc" >
                                                    <p>{d.summary}</p>
                                                    <Link to={d.file} target="_blank">Download/View <i className="fa fa-file-pdf"></i></Link>
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                                </td>
                                            </tr>
                                     
                               )) : <tr key=""><td>Loading.....</td></tr>
                            }
                                </tbody>
                  </table>
                  <div className="col-md-4">
                            <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div>
                            </div>
                            </div>
                            </div>
                            </div>
                    </div>
                    </div>
                    <div className="col-lg-4">
                    <FSsidebar/>
                    </div>
                </div>
            </div>
        </section>
        <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link>
        <Footer/>
    </>
  )
}
