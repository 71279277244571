import BGPH1 from '../../assets/images/backgrounds/page-header-bg-1-1.jpg'
import { Link, useParams } from 'react-router-dom'
import React, { useState, useEffect, useMemo }   from 'react'
import axios from 'axios'
//import { Container } from "react-bootstrap";
//import Header from "components/Header";
import { TableHeader, Pagination, Search } from '../DataTable';
// import Service from '../Main'
// import onSubmitForm from '../Main'
import {
    MDBTabsContent,
    MDBTabsPane
  } from 'mdb-react-ui-kit';
import Navbar from '../Navbar/Main';
import Footer from '../Footer/Main';


export const Anreports = () => {

    const [iconsActive, setIconsActive] = useState('tab1');

    const handleIconsClick = (value) => {
      if (value === iconsActive) {
        return;
      }
      setIconsActive(value);
    };

    //axios api to display annual reports
    const [doc, setDoc] = useState([]); 
    const [docfsr, setDocfsr] = useState([]);
    const [docrb, setDocrb] = useState([]);
    const [docmr, setDocmr] = useState([]);
    const [docer, setDocer] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });

    const ITEMS_PER_PAGE = 50;

    const headers = [
        { name: "Date", field: "Date", sortable: false },
        { name: "Annual Report", field: "Annual_Report", sortable: false },
    ];

   useEffect(() => {
       axios.get(`/annual_report`)
           .then((response) => {
               let postData = response.data
               console.log(postData);
               setDoc(postData)
           })
           .catch((error) => console.log(error))
   }, [])

   const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    });
  };
  
  const docData = useMemo(() => {
    let computeddoc = doc;

    if (search) {
        computeddoc = computeddoc.filter(
                d =>
                d.date_last_modified.toLowerCase().includes(search.toLowerCase()) ||
                d.name.toLowerCase().includes(search.toLowerCase())
        );
    }

    setTotalItems(computeddoc.length);

    //Sorting doc
    if (sorting.field) {
        const reversed = sorting.order === "asc" ? 1 : -1;
        computeddoc = computeddoc.sort(
            (a, b) =>
                reversed * a[sorting.field].localeCompare(b[sorting.field])
        );
    }

    //Current Page slice
    return computeddoc.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
}, [doc, currentPage, search, sorting]);

    //axios api to display financial stability reports

    const ITEMS_PER_PAGE2 = 50;

    const headers1 = [
        { name: "Date", field: "Date", sortable: false },
        { name: "Financial Stability Report", field: "financial_stability_report", sortable: false },
    ];

   useEffect(() => {
       axios.get(`/financial_stability_report`)
           .then((response) => {
               let postData = response.data
               console.log(postData);
               setDocfsr(postData)
           })
           .catch((error) => console.log(error))
   }, [])

  const docData1 = useMemo(() => {
    let computeddoc = docfsr;

    if (search) {
        computeddoc = computeddoc.filter(
                d =>
                d.date_last_modified.toLowerCase().includes(search.toLowerCase()) ||
                d.name.toLowerCase().includes(search.toLowerCase())
        );
    }

    setTotalItems(computeddoc.length);

    //Sorting doc
    if (sorting.field) {
        const reversed = sorting.order === "asc" ? 1 : -1;
        computeddoc = computeddoc.sort(
            (a, b) =>
                reversed * a[sorting.field].localeCompare(b[sorting.field])
        );
    }

    //Current Page slice
    return computeddoc.slice(
        (currentPage - 1) * ITEMS_PER_PAGE2,
        (currentPage - 1) * ITEMS_PER_PAGE2 + ITEMS_PER_PAGE2
    );
}, [docfsr, currentPage, search, sorting]);


    //axios api to display Rwandan Banker reports

    const ITEMS_PER_PAGE3 = 50;

    const headers3 = [
        { name: "Date", field: "Date", sortable: false },
        { name: "Foreign Pricate Capital Reports", field: "Foreign Pricate Capital Reports", sortable: false },
    ];

   useEffect(() => {
       axios.get(`/Other_Report`)
           .then((response) => {
               let postData = response.data
               console.log(postData);
               setDocrb(postData)
           })
           .catch((error) => console.log(error))
   }, [])

  const docData3 = useMemo(() => {
    let computeddoc = docrb;

    if (search) {
        computeddoc = computeddoc.filter(
                d =>
                d.date_last_modified.toLowerCase().includes(search.toLowerCase()) ||
                d.name.toLowerCase().includes(search.toLowerCase())
        );
    }

    setTotalItems(computeddoc.length);

    //Sorting doc
    if (sorting.field) {
        const reversed = sorting.order === "asc" ? 1 : -1;
        computeddoc = computeddoc.sort(
            (a, b) =>
                reversed * a[sorting.field].localeCompare(b[sorting.field])
        );
    }

    //Current Page slice
    return computeddoc.slice(
        (currentPage - 1) * ITEMS_PER_PAGE3,
        (currentPage - 1) * ITEMS_PER_PAGE3 + ITEMS_PER_PAGE3
    );
}, [docrb, currentPage, search, sorting]);

    //axios api to display MPFS reports

    const ITEMS_PER_PAGE4 = 50;

    const headers4 = [
        { name: "Date", field: "Date", sortable: false },
        { name: "MPFS Reports", field: "MPFS Reports", sortable: false },
    ];

   useEffect(() => {
       axios.get(`/MPFS_Report`)
           .then((response) => {
               let postData = response.data
               console.log(postData);
               setDocmr(postData)
           })
           .catch((error) => console.log(error))
   }, [])

  const docData4 = useMemo(() => {
    let computeddoc = docmr;

    if (search) {
        computeddoc = computeddoc.filter(
                d =>
                d.date_last_modified.toLowerCase().includes(search.toLowerCase()) ||
                d.name.toLowerCase().includes(search.toLowerCase())
        );
    }

    setTotalItems(computeddoc.length);

    //Sorting doc
    if (sorting.field) {
        const reversed = sorting.order === "asc" ? 1 : -1;
        computeddoc = computeddoc.sort(
            (a, b) =>
                reversed * a[sorting.field].localeCompare(b[sorting.field])
        );
    }

    //Current Page slice
    return computeddoc.slice(
        (currentPage - 1) * ITEMS_PER_PAGE4,
        (currentPage - 1) * ITEMS_PER_PAGE4 + ITEMS_PER_PAGE4
    );
}, [docmr, currentPage, search, sorting]);


    //axios api to display MPFS reports

    const ITEMS_PER_PAGE5 = 50;

    const headers5 = [
        { name: "Date", field: "Date", sortable: false },
        { name: "Monetary Policy Reports", field: "Monetary Policy Reports", sortable: false },
    ];

   useEffect(() => {
       axios.get(`/Monetary_Policy_Report`)
           .then((response) => {
               let postData = response.data
               console.log(postData);
               setDocer(postData)
           })
           .catch((error) => console.log(error))
   }, [])

  const docData5 = useMemo(() => {
    let computeddoc = docer;

    if (search) {
        computeddoc = computeddoc.filter(
                d =>
                d.publish_date.toLowerCase().includes(search.toLowerCase()) ||
                d.name.toLowerCase().includes(search.toLowerCase())
        );
    }

    setTotalItems(computeddoc.length);

    //Sorting doc
    if (sorting.field) {
        const reversed = sorting.order === "asc" ? 1 : -1;
        computeddoc = computeddoc.sort(
            (a, b) =>
                reversed * a[sorting.field].localeCompare(b[sorting.field])
        );
    }

    //Current Page slice
    return computeddoc.slice(
        (currentPage - 1) * ITEMS_PER_PAGE5,
        (currentPage - 1) * ITEMS_PER_PAGE5 + ITEMS_PER_PAGE5
    );
}, [docer, currentPage, search, sorting]);


const [clicked, setClicked] = useState(0);

  return (
    <>
    <Navbar/>
        <div className="stricky-header stricked-menu main-menu">
            <div className="sticky-header__content"></div>
        </div>
        <section className="page-header">
            <div className="page-header__bg"   ></div>
            <div className="container">
                <ul className="thm-breadcrumb list-unstyled">
                    <li><Link to="/">Home</Link></li>
                    <li><span>Reports</span></li>
                </ul>
                 <div className="surrounding slider-one__title">
                  Welcome to our comprehensive report section, where we explore the latest insights, analysis, 
					and findings on various topics and functions that fall into our mandate. 
					Here, you'll discover a wealth of information aimed at providing clarity and understanding on key issues impacting the financial industry.
					Through meticulously curated reports, we aim to offer valuable perspectives, trends, and data-driven insights to our readers. 
					This section serves as a valuable resource hub for all.                
				</div> 
            </div>
        </section>
        <section className="service-five pt-50 pb-50">
            <div className="container">
            <div className="row row-gutter-y-50 service-four__inner">    
               <div className="about-three__content"> 
                        <div className="block-title text-center">
                          <h2 className="block-title__tagline">Reports</h2>
                        </div>
                        {/* <p className="about-three__text">Publications are the process by which a central bank
                        manages the supply and demand of money and credit in an economy to achieve 
                        specific macroeconomic objectives. The primary objective of monetary policy is 
                        to promote price stability, which is typically defined as low and stable inflation.
                        </p>   */}
                </div>    
                <div className="row benefit-two__tab tabs-box" >
                    <ul className="list-unstyled benefit-two__tab__title2 tab-btns tab-buttons"> 

                                    <li className={`${iconsActive === 'tab1'} ? 'active-btn' : 'tab-btn'}`} 
                                    onClick={() => handleIconsClick('tab1')} >
                                        <span>Annual Reports</span>
                                    </li>

                                    <li className="tab-btn" onClick={() => handleIconsClick('tab2')} active={iconsActive === 'tab2'}>
                                        <span>Financial Stability Reports</span>
                                    </li>
                                    <li className="tab-btn" onClick={() => handleIconsClick('tab3')} active={iconsActive === 'tab3'}>
                                        <span>MPFSS Reports</span>
                                    </li>
                                    <li className="tab-btn" onClick={() => handleIconsClick('tab4')} active={iconsActive === 'tab4'}>
                                        <span>Monetary Policy Reports</span>
                                    </li>
                                    
                                </ul>
      <MDBTabsContent>
      <MDBTabsPane show={iconsActive === 'tab1'}>

<div className="loan-comparison__body" id="compare-emi-1">
   <div className="compare-table_doc table-responsive">
           <div className="col-md-4 d-flex flex-row-reverse">
               <Search
                   onSearch={value => {
                       setSearch(value);
                       setCurrentPage(1);
                   }}
               />
           </div>
     <table className="table">
     <TableHeader
               headers={headers}
               onSorting={(field, order) =>
                   setSorting({ field, order })
               }
           />
                   <tbody>
                      
            {
                              docData ? docData.map((d, index) => (
                               
                               <tr key="">
                                   <td>{formatDate(d.date_last_modified)}</td>
                                   <td>
                                   <div className="accrodion-grp service-details__accrodion" data-grp-name="service-details__accrodion-1">

                           <div className={`accrodion  wow fadeInUp ${index === clicked && "active"}`} key={index} data-wow-delay="0ms">
                               <div className="accrodion-title" onClick={() => setClicked(index)}>
                                   <h4>{d.name}</h4>
                                   <span className="accrodion-icon"></span>
                               </div>
                               {index === clicked && 
                               <div className="accrodion-content">
                                   <div className="inner_doc" >
                                   <p>{d.summary}</p>
                                       <Link to={d.file} target="_blank">Download/View <i className="fa fa-file-pdf"></i></Link>
                                   </div>
                               </div>}
                           </div>
                       </div>
                                   </td>
                               </tr>
                        
                  )) : <tr key=""><td>Loading.....</td></tr>
               }
                   </tbody>
        </table>
          <div className="col-md-4">
               <Pagination
                   total={totalItems}
                   itemsPerPage={ITEMS_PER_PAGE}
                   currentPage={currentPage}
                   onPageChange={page => setCurrentPage(page)}
               />
           </div>
       </div>
   </div>
</MDBTabsPane>
        <MDBTabsPane show={iconsActive === 'tab2'}>
            
        <div className="loan-comparison__body" id="compare-emi-1">
   <div className="compare-table_doc table-responsive">
           <div className="col-md-4 d-flex flex-row-reverse">
               <Search
                   onSearch={value => {
                       setSearch(value);
                       setCurrentPage(1);
                   }}
               />
           </div>
     <table className="table">
     <TableHeader
               headers={headers1}
               onSorting={(field, order) =>
                   setSorting({ field, order })
               }
           />
                   <tbody>
                      
            {
                              docData1 ? docData1.map((d, index) => (
                               
                               <tr key="">
                                   <td>{formatDate(d.date_last_modified)}</td>
                                   <td>
                                   <div className="accrodion-grp service-details__accrodion" data-grp-name="service-details__accrodion-1">

                           <div className={`accrodion  wow fadeInUp ${index === clicked && "active"}`} key={index} data-wow-delay="0ms">
                               <div className="accrodion-title" onClick={() => setClicked(index)}>
                                   <h4>{d.name}</h4>
                                   <span className="accrodion-icon"></span>
                               </div>
                               {index === clicked && 
                               <div className="accrodion-content">
                                   <div className="inner_doc" >
                                       <p>{d.summary}</p>
                                       <Link to={d.file} target="_blank">Download/View <i className="fa fa-file-pdf"></i></Link>
                                   </div>
                               </div>}
                           </div>
                       </div>
                                   </td>
                               </tr>
                        
                  )) : <tr key=""><td>Loading.....</td></tr>
               }
                   </tbody>
        </table>
          <div className="col-md-4">
               <Pagination
                   total={totalItems}
                   itemsPerPage={ITEMS_PER_PAGE2}
                   currentPage={currentPage}
                   onPageChange={page => setCurrentPage(page)}
               />
           </div>
       </div>
   </div>
        </MDBTabsPane>
        
            <MDBTabsPane show={iconsActive === 'tab3'}>
            
            <div className="loan-comparison__body" id="compare-emi-1">
       <div className="compare-table_doc table-responsive">
               <div className="col-md-4 d-flex flex-row-reverse">
                   <Search
                       onSearch={value => {
                           setSearch(value);
                           setCurrentPage(1);
                       }}
                   />
               </div>
         <table className="table">
         <TableHeader
                   headers={headers4}
                   onSorting={(field, order) =>
                       setSorting({ field, order })
                   }
               />
                       <tbody>
                          
                {
                                  docData4 ? docData4.map((d, index) => (
                                   
                                   <tr key="">
                                       <td>{formatDate(d.date_last_modified)}</td>
                                       <td>
                                       <div className="accrodion-grp service-details__accrodion" data-grp-name="service-details__accrodion-1">
    
                               <div className={`accrodion  wow fadeInUp ${index === clicked && "active"}`} key={index} data-wow-delay="0ms">
                                   <div className="accrodion-title" onClick={() => setClicked(index)}>
                                       <h4>{d.name}</h4>
                                       <span className="accrodion-icon"></span>
                                   </div>
                                   {index === clicked && 
                                   <div className="accrodion-content">
                                       <div className="inner_doc" >
                                           <p>{d.summary}</p>
                                           <Link to={d.file} target="_blank">Download/View <i className="fa fa-file-pdf"></i></Link>
                                       </div>
                                   </div>}
                               </div>
                           </div>
                                       </td>
                                   </tr>
                            
                      )) : <tr key=""><td>Loading.....</td></tr>
                   }
                       </tbody>
            </table>
              <div className="col-md-4">
                   <Pagination
                       total={totalItems}
                       itemsPerPage={ITEMS_PER_PAGE4}
                       currentPage={currentPage}
                       onPageChange={page => setCurrentPage(page)}
                   />
               </div>
           </div>
       </div>
            </MDBTabsPane>

            <MDBTabsPane show={iconsActive === 'tab4'}>
            
            <div className="loan-comparison__body" id="compare-emi-1">
            {/* <p className="about-three__text">BNR Economic Review is devoted to publishing articles of interest to policymakers, 
            academics and practitioners on a wide range of economic issues with policy relevance. 
            The journal welcomes empirical and theoretical studies with an emphasis on central bank responsibilities and other economic policies. 
            The journal aims to promote interaction between researchers and policymakers and contribute to knowledge dissemination. 
            Topics of particular interest are notably on monetary policy, financial stability and intermediation, 
            financial markets, payment systems, trade and fiscal policy.
                        </p>   */}
       <div className="compare-table_doc table-responsive">
               <div className="col-md-4 d-flex flex-row-reverse">
                   <Search
                       onSearch={value => {
                           setSearch(value);
                           setCurrentPage(1);
                       }}
                   />
               </div>

         <table className="table">
         <TableHeader
                   headers={headers5}
                   onSorting={(field, order) =>
                       setSorting({ field, order })
                   }
               />
                       <tbody>
                      
                      {
                                        docData5 ? docData5.map((d, index) => (
                                         
                                         <tr key="">
                                             <td>{formatDate(d.date_last_modified)}</td>
                                             <td>
                                             <div className="accrodion-grp service-details__accrodion" data-grp-name="service-details__accrodion-1">
          
                                     <div className={`accrodion  wow fadeInUp ${index === clicked && "active"}`} key={index} data-wow-delay="0ms">
                                         <div className="accrodion-title" onClick={() => setClicked(index)}>
                                             <h4>{d.name}</h4>
                                             <span className="accrodion-icon"></span>
                                         </div>
                                         {index === clicked && 
                                         <div className="accrodion-content">
                                             <div className="inner_doc" >
                                                 <p>{d.summary}</p>
                                                 <Link to={d.file} target="_blank">Download/View <i className="fa fa-file-pdf"></i></Link>
                                             </div>
                                         </div>}
                                     </div>
                                 </div>
                                             </td>
                                         </tr>
                                  
                            )) : <tr key=""><td>Loading.....</td></tr>
                         }
                             </tbody>
            </table>
              <div className="col-md-4">
                   <Pagination
                       total={totalItems}
                       itemsPerPage={ITEMS_PER_PAGE5}
                       currentPage={currentPage}
                       onPageChange={page => setCurrentPage(page)}
                   />
               </div>
           </div>
       </div>
            </MDBTabsPane>
            
        </MDBTabsContent>
           </div>
             </div>
         </div>
    </section>
        <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link>
        <Footer/>
    </>
  )
}

