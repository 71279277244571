import BGPH1 from '../../../assets/images/backgrounds/page-header-bg-1-1.jpg'
import { Link } from 'react-router-dom'
import React, { useState, useEffect}   from 'react'
import axios from 'axios'
import Footer from '../FooterKinya/Main'
import Navbar from '../NavbarKinya/Main'

const Faqs = () => {

    const [clicked, setClicked] = useState(0);
    const [faqs, setFaqs] = useState([]); 

   useEffect(() => {
       axios.get(`/faqs`)
           .then((response) => {
               let postData = response.data
               console.log(postData);
               setFaqs(postData)
           })
           .catch((error) => console.log(error))
   }, [])
   
  return (
    <>
    <Navbar/>
        <div className="stricky-header stricked-menu main-menu">
            <div className="sticky-header__content"></div>
        </div>
        <section className="page-header">
            <div className="page-header__bg"     ></div>
              <div className="container">
                <ul className="thm-breadcrumb list-unstyled">
                    <li><Link to="/">Home</Link></li>
                    <li><span>FAQs</span></li>
                </ul>
                <h2>FAQs</h2>
            </div>
        </section>
        <section className="service-five pt-50 pb-50">
        <div className="container">
            <div className="row row-gutter-y-20 service-four__inner"> 
                    <div className="col-lg-12">
                        <div className="accrodion-grp faq-one-accrodion" data-grp-name="faq-one-accrodion-1">
                        {faqs.map((item, index) => (
                            <div className={`accrodion  wow fadeInUp ${index === clicked && "active"}`} key={index} data-wow-delay="0ms">
                                <div className="accrodion-title" onClick={() => setClicked(index)}>
                                    <h4>{item.question}</h4>
                                    <span className="accrodion-icon"></span>
                                </div>
                                {index === clicked && <div className="accrodion-content">
                                    <div className="inner">
                                        <p>{item.answer}</p>
                                    </div>
                                </div>}
                            </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link>
        <Footer/>
    </>
  )
}

export default Faqs