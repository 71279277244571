import React from 'react'
import { Link } from 'react-router-dom'

const ResultCard = ({ props }) => {

    const { image, title, name, summary, details, link, file } = props;

  return (
    <div className='flex bg-gray-100 p-3 rounded-md mb-2'>
        
        {/* <div className='w-56 h-56'    >image</div> */}
        {/* <img src= {`../${image}`} alt="" className='w-72 object-cover'/> */}
        <div className='m-2'></div>
        <div className='flex-grow flex flex-col'>
        <h3 className="career-one__title"><Link to={`${link}`} target="_blank"> {title}</Link></h3>
        <ul className="list-unstyled blog-sidebar__post">
                                        <li className="blog-sidebar__post__item">
                                           {/*} <div className="blog-sidebar__post__image col-lg-3">
                                            <Link to={`${link}`}><img src= {`../${image}`}/></Link> 
  </div>*/}
                                            <div className="blog-sidebar__post__content col-lg-12">
                                                <p className='text-xl'>{summary} {"  "}<br></br><Link className="text-2xl" to={`${link}`} target="_blank">More details</Link></p>
                                            </div>
                                        </li>
                                    </ul>
        </div>

    </div>
  )
}

export default ResultCard