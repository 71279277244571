import BGPH1 from '../../../assets/images/backgrounds/page-header-bg-1-1.jpg'
import { Link } from 'react-router-dom'
import React, { useState, useEffect } from "react";
import axios from 'axios'
import Navbar from '../NavbarKinya/Main';
import Footer from '../FooterKinya/Main';

export default function Service() {

    const [post, setPost] = useState()
    useEffect(() => {
        axios.get('/statistics')
            .then((response) => {
                let postData = response.data
                console.log(postData);
                setPost(postData)
            })
            .catch((error) => console.log(error))
    }, [])


  return (
    <>
    <Navbar/>
        <div className="stricky-header stricked-menu main-menu">
            <div className="sticky-header__content"></div>
        </div>
        <section className="page-header">
            <div className="page-header__bg"    ></div>
            <div className="container">
                <ul className="thm-breadcrumb list-unstyled">
                    <li><Link to="/">Home</Link></li>
                    <li><span>Statistics</span></li>
                </ul>
                <h2>Statistics</h2>
            </div>
        </section>
        <section className="service-five ">
            <div className="container">
            <div className="row row-gutter-y-50 service-four__inner">    
               <div className="about-three__content"> 
                        <div className="block-title text-center">
                          <h2 className="block-title__tagline">Get to know about BNR Statistics</h2>
                        </div>
                        <p className="about-three__text">Statistics is the process by which a central bank
                        manages the supply and demand of money and credit in an economy to achieve 
                        specific macroeconomic objectives. The primary objective of monetary policy is 
                        to promote price stability, which is typically defined as low and stable inflation.
                        </p>  
                </div>                
                {
                      post ? post.map((p, index) => (
                                    <>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="service-card">
                            <div className="service-card__image">
                                <img src={`/rw/${p.image}`} width="370" height="300" alt="" />
                                <Link to={`/statdetails/${p.id}`} > 
                                 </Link>
                            </div>
                            <div className="service-card__content">
                                <div className="service-card__content__inner">
                                    <div className="service-card__icon">
                                        <i className="icon-payment"></i><Link to={`/statdetails/${p.id}`} > </Link>
                                    </div>
                                    <h3 className="service-card__title">
                                    <Link to={`/statdetails/${p.id}`}>{p.title}</Link>
                                    </h3>
                                    <p className="service-card__text">{p.summary}</p>
                                    <Link to={`/statdetails/${p.id}`} className="service-card__link">
                                        <i className="fa fa-angle-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
             
                </div>
                </> 
                           
                           )) : <p>loading...</p>
                       }
          
                 </div>
               </div>
        </section>
        <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link>
        <Footer/>
    </>
  )
}

