// import BGS1 from '../../../assets/images/services/service-s-1-1.png'
import React, { useState, useEffect }   from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'

export default function JOBsidebar() {

    const [link, setPost] = useState()
    useEffect(() => {
        axios.get('/jobdocuments')
            .then((response) => {
                let postData = response.data
                console.log(postData);
                setPost(postData)
            })
            .catch((error) => console.log(error))
    }, [])


  return (
    <div className="service-sidebar">
        <div className="service-sidebar__item service-sidebar__item--menu">
            <ul className="service-sidebar__menu">
            {
                      link ? link.map((l, index) => (
                                    <>
              <li key={l.id}>
              <Link to={`/jobdocuments/${l.category}`}>{l.category}</Link>
              </li>
              </>
                                    )) : <p>loading...</p>
                                }
                  
             </ul>
           </div>
        <div className="service-sidebar__item service-sidebar__item--contact">
           {/* <div className="service-sidebar__bg" style={{ backgroundImage: `url(${BGS1})`}}>
              </div> */}
        <div className="service-sidebar__contact">
            <div className="service-sidebar__icon">
                <i className="icon-phone-ringing"></i>
            </div>
            <h3 className="service-sidebar__title">Any Query related to Job Adverts?</h3>
            <hr className="service-sidebar__separator" />
            <p className="service-sidebar__tagline">Talk to an expert</p>
            <Link to="tel:+250-788823949" className="service-sidebar__phone" >+250-788823949</Link>
            <p className="service-sidebar__phone">hrd@bnr.rw</p>
            </div>
        </div>
    </div>
  )
}
