import React from 'react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const ExportButton = ({ data, fileName }) => {
  const handleExport = () => {
    // Create a new workbook and add the data
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // Generate buffer and save it as an Excel file
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    saveAs(new Blob([wbout], { type: 'application/octet-stream' }), fileName);
  };

  return (
      <input class="thm-btn-ccy" type="submit" onClick={handleExport} value="Export to Excel" />
          
  );
};

export default ExportButton;