import BGPH1 from '../../assets/images/backgrounds/page-header-bg-1-1.jpg'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useParams } from 'react-router-dom'
import React, { useState, useEffect, useMemo }   from 'react'
import axios from 'axios'
//import { Container } from "react-bootstrap";
//import Header from "components/Header";
import { TableHeader, Pagination, Search } from '../DataTable';
import Navbar from '../Navbar/Main'
import Footer from '../Footer/Main'
import MCquery from './mcquery'
import MCsidebar from './mcsidebar'
// import Service from '../Main'
// import onSubmitForm from '../Main'

export const Forexbureaus = () => {
    

   const [doc, setDoc] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });

    const ITEMS_PER_PAGE = 10;

    const headers = [
        { name: "Date", field: "Date", sortable: false },
        { name: "Documents", field: "Documents", sortable: false },
    ];

   const [loading, setLoading] = useState(true);
   useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`/forexbureaus`);
        const docData = await response.json();
        setDoc(docData);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []); 
   const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    });
  };
  const docData = useMemo(() => {
    let computeddoc = doc;

    if (search) {
        computeddoc = computeddoc.filter(
                d =>
                d.date_last_modified.toLowerCase().includes(search.toLowerCase()) ||
                d.name.toLowerCase().includes(search.toLowerCase())
        );
    }

    setTotalItems(computeddoc.length);

    //Sorting doc
    if (sorting.field) {
        const reversed = sorting.order === "asc" ? 1 : -1;
        computeddoc = computeddoc.sort(
            (a, b) =>
                reversed * a[sorting.field].localeCompare(b[sorting.field])
        );
    }

    //Current Page slice
    return computeddoc.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
}, [doc, currentPage, search, sorting]);



        const [clicked, setClicked] = useState(0);

  return (
    <>
    <Navbar/>
        <div className="stricky-header stricked-menu main-menu">
            <div className="sticky-header__content"></div>
        </div>
        <section className="page-header">
            <div className="page-header__bg"    ></div>
            <div className="container">
                <ul className="thm-breadcrumb list-unstyled">
                    <li><Link to="/">Home</Link></li>
                    <li><span>Forex Bureaus</span></li>
                </ul>
                <div className="surrounding slider-one__title">
                The National Bank of Rwanda is mandated to manage foreign currency. 
                                It is through this mandate that the National Bank of Rwanda is in charge of regulating 
                                and supervising Forex Bureaus to ensure that their complying with laws and regulatory requirements.
                                The primary activity of Forex Bureaus business is the buying and selling foreign currencies. 
                </div>
            </div>
        </section>
        {/* <div  className="about-four__shape"></div>
  <div  className="about-five__shape-2"></div> 
  <div  className="about-five__shape-1"></div>
  <div  className="about-five__shape-9"></div> 
  <div  className="about-five__shape-0"></div> */}
        <section className="service-details pt-30 pb-30">
            <div className="container">
                <div className="row row-gutter-y-50 contact-info-one">
                    <div className="col-lg-8">
                        {/* <div className="service-details__image">
                            <a href="/monetarypolicy" className="service-details__icon"><i className="fa fa-arrow-left"></i></a>
                        </div> */}
                        <div className="service-details__content">
                       
                            <div className="row row-gutter-y-30">
                                <div className="col-md-12">
                 
                      {/* <img src={p.image} alt="" /> */}
                      <h3 className="service-details__title">Forex Bureaus</h3>
                      
                            <p>The National Bank of Rwanda is mandated to manage foreign currency. 
                                It is through this mandate that the National Bank of Rwanda is in charge of regulating 
                                and supervising Forex Bureaus to ensure that their complying with laws and regulatory requirements.
                                The primary activity of Forex Bureaus business is the buying and selling foreign currencies. 
                                It is in this context that no individual or a company is authorized to transact in foreign currencies 
                                unless it is licensed by National Bank of Rwanda.
                                Any person who sells or exchanges the national or foreign currency illegally commit a crime and is sentenced in 
                                accordance with the penal code provisions.

                                Therefore, the NBR published the Regulation no 2310/2018 - 00015 [614] of 27/12/2018 governing Foreign Exchange Bureaus. 
                                Further, the NBR issues the Regulation n° 42/2022 of 13/04/2022 governing foreign exchange operations. 

                                To facilitate those needed to transact in foreign currencies given the nature of their business, 
                                the NBR issued the Directive no 0520/2023-00041[613.1.4] dated 22/02/2023 on transactions in foreign currencies by non-licensed persons 
                                that requires non-licensed persons applying for authorization to transact in foreign currencies.  
                            </p>
                            {loading ? (
                
                <p></p>
                    ) : docData && docData.length > 0 ? (
                        <>  
                       
                       <p className="service-details__text"><b>The following are documents related to Forex Bureaus</b></p>
                                         
                        
               <div className="loan-comparison__body" id="compare-emi-1">
                <div className="compare-table_doc table-responsive">
                        <div className="benefit-two__tab__doc2">
                            <ul className="list-unstyled">
                                <li className='row'> 
                                    <Search className="search" onSearch={value => { setSearch(value); setCurrentPage(1); }}/>
                                 <span><Link className='linkcolor' to="/foreignexchange">Link for Laws and Regulations</Link></span></li> 
                            </ul>
                        </div>
                  <table className="table">
                  <TableHeader
                            headers={headers}
                            onSorting={(field, order) =>
                                setSorting({ field, order })
                            }
                        />
                                <tbody>
                                   
                         {
                                           docData ? docData.map((d, index) => (
                                            
                                            <tr key="">
                                                <td>{formatDate(d.date_last_modified)}</td>
                                                <td>
                                                <div className="accrodion-grp service-details__accrodion" data-grp-name="service-details__accrodion-1">

                                        <div className={`accrodion  wow fadeInUp ${index === clicked && "active"}`} key={index} data-wow-delay="0ms">
                                            <div className="accrodion-title" onClick={() => setClicked(index)}>
                                                <h4>{d.name}</h4>
                                                <span className="accrodion-icon"></span>
                                            </div>
                                            {index === clicked && 
                                            <div className="accrodion-content">
                                                <div className="inner_doc" >
                                                    <p>{d.summary}</p>
                                                    <Link to={d.file} target="_blank">Download/View <i className="fa fa-file-pdf"></i></Link>
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                                </td>
                                            </tr>
                                     
                               )) : <tr key=""><td>Loading.....</td></tr>
                            }
                                </tbody>
                  </table>
                  <div className="col-md-4">
                            <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div>
                            </div>
                            </div>
                            </>  
                      ) : (
                        <p></p>
                      )}
                            </div>
                            </div>
                    </div>
                    </div>
                    <div className="col-lg-4">
                    <MCsidebar/>
                    </div>
                    <MCquery/>
                </div>
            </div>
        </section>
        <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link>
        <Footer/>
    </>
  )
}
