import BGPH1 from '../../../assets/images/backgrounds/page-header-bg-1-1.jpg'
import { Link } from 'react-router-dom'
import React, { useRef, useState, useEffect, useMemo } from "react";
import axios from 'axios';
import Navbar from '../NavbarKinya/Main';
import Footer from '../FooterKinya/Main';

const Careers = () => {

    const [post, setPost] = useState()
    useEffect(() => {
        axios.get('/jobdocuments')
            .then((response) => {
                let postData = response.data
                console.log(postData);
                setPost(postData)
            })
            .catch((error) => console.log(error))
    }, [])

  return (
    <>
      <Navbar/>
      <div className="stricky-header stricked-menu main-menu">
        <div className="sticky-header__content"></div>
        </div>
        <section className="page-header">
            <div className="page-header__bg"    ></div>
           
            <div className="container">
                <ul className="thm-breadcrumb list-unstyled">
                    <li><Link to="/">Home</Link></li>
                    <li><span>Careers</span></li>
                </ul>
                <h2>Careers</h2>
            </div>
        </section>
        <section className="service-five pt-50 pb-50">
            <div className="container">
            <div className="row row-gutter-y-50 service-four__inner">   
                       <div className="block-title text-center">
                          <h2 className="block-title__tagline">Job Opportunities</h2>
                        </div>
                {
                post ? post.map((p, index) => (
                    <div className="col-lg-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">
                        <div className="career-one__item">
                            <div className="career-one__icon">
                                <i className="icon-programmer"></i>
                            </div>
                            <div className="career-one__content">
                                <h3 className="career-one__title"><Link to={`/jobdocuments/${p.category}`}>{p.category} </Link></h3>
                                
                                {/* <p className="career-one__text">{p.name}</p> */}
                            </div>
                            <div className="career-one__btns">
                                <Link to={`/jobdocuments/${p.category}`} className="thm-btn">More details</Link>
                            </div>
                        </div>
                    </div>
                    )) : <p>Loading.....</p>
                                    }

                </div>
                 
                 
            </div>
        </section>

        <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link>
        <Footer/>
    </>
  )
}

export default Careers