import React from 'react';
import { Link } from 'react-router-dom';
import Footer from './Footer/Main';
import Navbar from './Navbar/Main';

const NotFound = () => {
  return (
    <>
      <Navbar/>
      <div className="stricky-header stricked-menu main-menu">
        <div className="sticky-header__content"></div>
        </div>
        <section className="page-header">
            <div className="page-header__bg"></div>  
            <div className="container">
                <ul className="thm-breadcrumb list-unstyled">
                    <li><Link to="/">Home</Link></li>
                </ul>
            </div>
        </section>
        <section className="service-five pt-50 pb-50">
        <div className="container">
            <div className="row row-gutter-y-50 service-four__inner"> 
               <div className="col-lg-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">
                        <div>
                              <center>
                                  <br />
                                  <br />
                                  <h4>404 - Page Not Found</h4><br />
                              <h5><Link to="/">Click here to go to home page</Link></h5></center>
                        </div>
                    </div>
                </div> 
            </div>
        </section>
        <Footer/>
    </>

  );
};

export default NotFound;