import BGPH1 from "../../../assets/images/backgrounds/bnrimage.jpg";
import { Link } from "react-router-dom";
import React, { useEffect, useState, useMemo } from "react";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import flagUrls from '../flags';
import { TableHeader, Pagination, Search } from '../../DataTable';
import "react-datepicker/dist/react-datepicker.css";
import Navbar from "../../Navbar/Main";
import Footer from "../../Footer/Main";
import ExportButton from "../../excel_export";

export const ExchangeRateHis = () => {
const [currency_name, setCurrName] = useState("");
const [startDate, setStartDate] = useState("");
const [endDate, setEndDate] = useState("");
const [currency, setCurrency] = useState([]);
const [totalItems, setTotalItems] = useState(0);
const [currentPage, setCurrentPage] = useState(1);
const [search, setSearch] = useState("");
const [sorting, setSorting] = useState({ field: "", order: "" });

const ITEMS_PER_PAGE = 3000;

    const headers = [
      { name: "Country", field: "code", sortable: false },
      { name: "Currency", field: "ccy", sortable: false },
        { name: "Buying Rate", field: "buying", sortable: true },
        { name: "Average Rate", field: "average", sortable: true },
        { name: "Selling Rate", field: "selling", sortable: true },
        { name: "Date", field: "date", sortable: false }
    ];


const onSubmitForm = async (e) => {
  e.preventDefault()
  const response = await fetch(`/currency_his/?currency_name=${currency_name}&start_date=${startDate}&end_date=${endDate}`);
  const parseResponse = await response.json();
  setCurrency(parseResponse);
}

const [data, setData] = useState([]);
useEffect(() => {
  const getData = () => {
      fetch("/currencyselect")
          .then(response => response.json())
          .then(json => {
            setData(json);
              console.log(json);
          });
  };
  getData();
}, []);
/*
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-based
    const year = date.getFullYear() % 100;
    
    return `${day}-${month}-${year}`;
  };
*/

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate(); // Get the day of the month
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[date.getMonth()]; // Get the month name
    const year = date.getFullYear() % 100; // Get the last two digits of the year

    return `${day}-${month}-${year < 10 ? '0' + year : year}`; // Format and return the string
};


const currencyData = useMemo(() => {
  let computedcurrency = currency;

  if (search) {
      computedcurrency = computedcurrency.filter(
          ccy =>
              ccy.currency_name.toLowerCase().includes(search.toLowerCase()) ||
              ccy.post_date.toLowerCase().includes(search.toLowerCase())
      );
  }

  setTotalItems(computedcurrency.length);

  //Sorting currency
  if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedcurrency = computedcurrency.sort(
          (a, b) =>
              reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
  }

  //Current Page slice
  return computedcurrency.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
  );
}, [currency, currentPage, search, sorting]);

  return (
    <>
    <Navbar/>
      <div className="stricky-header stricked-menu main-menu">
        <div className="sticky-header__content"></div>
      </div>
      <section className="page-header">
        <div
          className="page-header__bg"
        ></div>
        <div className="container">
          <ul className="thm-breadcrumb list-unstyled">
                    <li><Link to="/">Home</Link></li>
                    <li><span>Exchange Rate Table</span></li>
          </ul>  
        </div>
      </section>

      <section className="service-five pt-50 pb-50">
      <div className="container">
      <div className="row row-gutter-y-10 service-four__inner">
          <div className="loan-comparison__body" id="compare-emi-1">
            <div className="row">
                <form onSubmit={onSubmitForm}>
                  
                <div className="row">
                <div className="col-xl-2">
                      <select 
                          value={currency_name} 
                          onChange={e => setCurrName(e.target.value)}
                          className="form-control input-control1"
                          id="emi-year-input"
                          name="currency_name" 
                          required>
                          <option value="">Select Currency...</option>
                          {data.map((item) => (
                            <option key={item.id} value={item.currency_name}>
                              {item.currency_name}
                            </option>
                          ))} 
                       </select>
                     </div>
                     <div className="col-xl-4">
                          <DatePicker
                            className="form-control input-control1"
                            id="emi-year-input"
                            value={startDate} 
                            name="start_date" 
                            placeholderText="Start Date"
                            onChange={e => setStartDate(moment(e).format("DD/MM/YYYY"))} 
                            required/>
                     </div>
                     <div className="col-xl-4">
                          <DatePicker
                            type ="date"
                            className="form-control input-control1"
                            id="emi-year-input"
                            value={endDate} 
                            name="end_date" 
                            placeholderText="End Date"
                            onChange={e => setEndDate(moment(e).format("DD/MM/YYYY"))} 
                            required/>
                     </div>
                        <div className="col-xl-2">
                            <input className="thm-btn-ccy" type="submit" value="Search" />
                        </div>
                        </div>
                </form>


                </div>
                <br></br>
          
     
                <div className="compare-table table-responsive">
                {/* <TableHeader
                            headers={headers}
                            onSorting={(field, order) =>
                                setSorting({ field, order })
                            }
                        /> */}
                              <div className="row">
                        {/* <div className="col-md-6 d-flex flex-row-reverse">
                            <Search 
                                onSearch={value => {
                                    setSearch(value);
                                    setCurrentPage(1);
                                }}
                            />
                        </div> */}
                    </div>
                  <table className="table table-striped table-hover">
                  <TableHeader
                            headers={headers}
                            onSorting={(field, order) =>
                                setSorting({ field, order })
                            }
                        />
                    <tbody>

                                    {currencyData ? currencyData.map(p => (
                                            <tr key={p.id}>
                                                <td>
                                 <img 
                                    src={flagUrls[p.currency_name]} 
                                    style={{ width: '30px', height: '20px' }}
                                 />
                                </td>
                                    <td>{p.currency_name}</td>
                                                <td>{p.buying_rate}</td>
                                                <td>{p.average_rate}</td>   
                                                <td>{p.selling_rate}</td>    
                                                <td>{formatDate(p.post_date)}</td>
                                            </tr>
                                        )) : <tr key=""><td>Loading.....</td></tr>
                                    }
                                </tbody>
                  </table>
                  <div className="col-md-12">
                        <ul className="list-unstyled">
                               <li className='row'> 
                              <ExportButton data={currency} fileName="currency_table.xlsx" />&nbsp;&nbsp;<Link className='thm-btn-ccy' to="https://fxrates.bnr.rw/" target="_blank">API Access</Link>
                                    </li> 
                              </ul>   
                        </div>
                </div>
              </div>
          </div>
        </div>
      </section>
      <Link to="#" data-target="html" className="scroll-to-target scroll-to-top">
        <i className="fa fa-angle-up"></i>
      </Link>
      <Footer/>
    </>
  );
};
