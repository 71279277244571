import BGPH1 from '../../assets/images/backgrounds/page-header-bg-1-1.jpg'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useParams } from 'react-router-dom'
import React, { useState, useEffect, useMemo }   from 'react'
import axios from 'axios'
//import { Container } from "react-bootstrap";
//import Header from "components/Header";
import { TableHeader, Pagination, Search } from '../DataTable';
import Navbar from '../Navbar/Main'
import Footer from '../Footer/Main'
import SFsidebar from './sfsidebar'
import SFquery from './sfquery'
// import Service from '../Main'
// import onSubmitForm from '../Main'

export const Capacity = () => {
    

   const [doc, setDoc] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });

    const ITEMS_PER_PAGE = 10;

    const headers = [
        { name: "Date", field: "Date", sortable: false },
        { name: "Documents", field: "Documents", sortable: false },
    ];
   const [loading, setLoading] = useState(true);
   useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`/capacity`);
        const docData = await response.json();
        setDoc(docData);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []); 
   const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    });
  };
  const docData = useMemo(() => {
    let computeddoc = doc;

    if (search) {
        computeddoc = computeddoc.filter(
                d =>
                d.date_last_modified.toLowerCase().includes(search.toLowerCase()) ||
                d.name.toLowerCase().includes(search.toLowerCase())
        );
    }

    setTotalItems(computeddoc.length);

    //Sorting doc
    if (sorting.field) {
        const reversed = sorting.order === "asc" ? 1 : -1;
        computeddoc = computeddoc.sort(
            (a, b) =>
                reversed * a[sorting.field].localeCompare(b[sorting.field])
        );
    }

    //Current Page slice
    return computeddoc.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
}, [doc, currentPage, search, sorting]);



        const [clicked, setClicked] = useState(0);

  return (
    <>
    <Navbar/>
        <div className="stricky-header stricked-menu main-menu">
            <div className="sticky-header__content"></div>
        </div>
        <section className="page-header">
            <div className="page-header__bg"    ></div>
            <div className="container">
                <ul className="thm-breadcrumb list-unstyled">
                    <li><Link to="/">Home</Link></li>
                    <li><span> Get To Know Sustainable Finance</span></li>
                </ul>
                <div className="surrounding slider-one__title">
               Sustainable finance integrates environmental, social, and governance (ESG) criteria into decision-making processes, 
               fostering economic growth while addressing environmental and social challenges. 
               Recognizing the urgency of sustainability and mainly climate change, we actively engage with stakeholders and we are an active member of the Network for Greening the Financial System (NGFS). 
                </div>
            </div>
        </section>
        {/* <div  className="about-four__shape"></div>
  <div  className="about-five__shape-2"></div> 
  <div  className="about-five__shape-1"></div>
  <div  className="about-five__shape-9"></div> 
  <div  className="about-five__shape-0"></div> */}
        <section className="service-details pt-30 pb-30">
            <div className="container">
                <div className="row row-gutter-y-50 contact-info-one">
                    <div className="col-lg-8">
                        {/* <div className="service-details__image">
                            <a href="/monetarypolicy" className="service-details__icon"><i className="fa fa-arrow-left"></i></a>
                        </div> */}
                        <div className="service-details__content">
                       
                            <div className="row row-gutter-y-30">
                                <div className="col-md-12">
                 
                      {/* <img src={p.image} alt="" /> */}
                      <h3 className="service-details__title">Get To Know Sustainable Finance</h3>
                      {/* <img src="../../../assets/images/services/service-d-1-1.png" alt="" /> */}
                      {/* <img src= {`../${p.image}`} alt="" /> */}
                      
                            <p> Climate change poses risks to price and financial stability by affecting economic activities, asset values, and overall market conditions. 
We acknowledge the impact of climate-related and environmental risks on the price stability and the soundness of our financial system and are committed to addressing these challenges.
As a central bank, we play a crucial role in ensuring the safety and soundness of the financial sector. 
While governments bear the primary responsibility, we actively contribute by implementing green finance policies and collaborating with national and international partners. Our actions are guided by the Sustainable Finance Roadmap, developed by the Kigali International Financial Centre (KIFC).
 </p>

<p> We have conducted a diagnostic review to assess regulated institutions' capabilities in implementing the National Sustainable Finance Roadmap. 
This review proposed a detailed action plan, emphasizing the necessary steps to achieve sustainability goals. This includes creating financial instruments, 
developing financial sector infrastructure, building human capacity, and actively sharing expertise to drive global behavioral changes.
Our Nationwide Sustainable Finance Roadmap highlights eight key strategic priority areas. As the National Bank of Rwanda out of these strategic priority areas, 
these areas guide our actions, emphasizing risk and vulnerability assessments, improving data collection, and making continuous updates. 
This helps us make significant contributions to combating climate change.
</p>

<p> Believing in collective action, we collaborate with institutions and partners committed to combating climate change, 
aiming to build a financial landscape that safeguards economic stability and contributes to global environmental sustainability. 
Our commitment extends to supporting general economic policies that align with environmental protection, 
reinforcing our dedication to a sustainable future for our region.</p>
 {loading ? (
                
                <p></p>
                    ) : docData && docData.length > 0 ? (
                        <>  
                       <p className="service-details__text">The following are documents related to Capacity Buildings and Awareness</p>
           
                       <div className="loan-comparison__body" id="compare-emi-1">
                <div className="compare-table_doc table-responsive">
                        <div className="benefit-two__tab__doc2">
                        <ul className="list-unstyled">
                                <li className='row'> 
                                    <Search className="search" onSearch={value => { setSearch(value); setCurrentPage(1); }}/>
                                 {/* <Route path="/esstatistics" element={<Esstatistics />} /><span><Link className='linkcolor' to="/pension">Link for Laws and Regulations</Link></span> */}
                                 </li> 
                            </ul>
                        </div>
                  <table className="table">
                  <TableHeader
                            headers={headers}
                            onSorting={(field, order) =>
                                setSorting({ field, order })
                            }
                        />
                                <tbody>
                                   
                         {
                                           docData ? docData.map((d, index) => (
                                            
                                            <tr key="">
                                                <td>{formatDate(d.date_last_modified)}</td>
                                                <td>
                                                <div className="accrodion-grp service-details__accrodion" data-grp-name="service-details__accrodion-1">

                                        <div className={`accrodion  wow fadeInUp ${index === clicked && "active"}`} key={index} data-wow-delay="0ms">
                                            <div className="accrodion-title" onClick={() => setClicked(index)}>
                                                <h4>{d.name}</h4>
                                                <span className="accrodion-icon"></span>
                                            </div>
                                            {index === clicked && 
                                            <div className="accrodion-content">
                                                <div className="inner_doc" >
                                                    <p>{d.summary}</p>
                                                    <Link to={d.file} target="_blank">Download/View <i className="fa fa-file-pdf"></i></Link>
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                                </td>
                                            </tr>
                                     
                               )) : <tr key=""><td>Loading.....</td></tr>
                            }
                                </tbody>
                  </table>
                  <div className="col-md-4">
                            <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div>
                            </div>
                            </div>
                            </>  
                      ) : (
                        <p></p>
                      )}
                            </div>
                            </div>
                    </div>
                    </div>
                    
                    <div className="col-lg-4">
                    <SFsidebar/>
                    </div>
                    <SFquery/>
                </div>
            </div>
        </section>
        <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link>
        <Footer/>
    </>
  )
}
