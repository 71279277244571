import BGPH1 from '../../assets/images/backgrounds/page-header-bg-1-1.jpg'
import logo from '../../assets/images/logo-dark.png'
import { Link } from 'react-router-dom'
import React, { useState, useEffect } from "react";
import axios from 'axios'
// import FSsidebar from './fssidebar';
import Navbar from '../Navbar/Main';
import Footer from '../Footer/Main';

export default function Service() {
    //axios api to display monetary policy data
    const [post, setPost] = useState()
    useEffect(() => {
        axios.get('/marketconduct')
            .then((response) => {
                let postData = response.data
                console.log(postData);
                setPost(postData)
            })
            .catch((error) => console.log(error))
    }, [])


  return (
    <>
    <Navbar/>
        <div className="stricky-header stricked-menu main-menu">
            <div className="sticky-header__content"></div>
        </div>
        <section className="page-header">
            <div className="page-header__bg"    ></div>
            <div className="container">
                <ul className="thm-breadcrumb list-unstyled">
                    <li><Link to="/">Home</Link></li>
                    <li><span>Market Conduct</span></li>
                </ul>
                  <div className="surrounding slider-one__title">
                  The National Bank of Rwanda adopted new mandate of market conduct supervision 
                  with an aim of creating a conducive environment for consumer empowerment, 
                  ensure transparency and integrity within the financial sector. 
                </div>
                {/* <h2>Financial Stability</h2> */}
            </div>
        </section>
        <section className="service-five ">
            <div className="container">
            <div className="row row-gutter-y-50 service-four__inner">    
               <div className="about-three__content"> 
                        <div className="block-title text-center">
                          <h2 className="block-title__tagline">Get to know about NBR Market Conduct</h2>
                        </div>
                </div>                
                {
                      post ? post.map((p, index) => (
                                    <>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="service-card">
                            <div className="service-card__image">
                                <img src={p.image} width="370" height="300" alt="" />
                                <Link to={`${p.link}`} > 
                                 </Link>
                            </div>
                            <div className="service-card__content">
                                <div className="service-card__content__inner">
                                    <div className="service-card__icon">
                                        {/* <i className="icon-payment"></i><Link to={`${p.link}`} > </Link> */}
                                        <img src={logo} width="70" height="70" alt="BNR"/>
                                    </div>
                                    <h3 className="service-card__title">
                                    <Link to={`${p.link}`}>{p.title}</Link>
                                    </h3>
                                    <p className="service-card__text">{p.summary}
                                    <Link to={`${p.link}`} className="service-card__link">
                                        <i className="fa fa-angle-right"></i>
                                    </Link>
                                    </p>
                                </div>
                            </div>
                        </div>
             
                </div>
                </> 
                           
                           )) : <p>loading...</p>
                       }
          
                 </div>
               </div>
        </section>
        <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link>
        <Footer/>
    </>
  )
}

