import BGPH1 from '../../assets/images/backgrounds/page-header-bg-1-1.jpg'
import logo from '../../assets/images/logo-dark.png'
import { Link } from 'react-router-dom'
import React, { useState, useEffect } from "react";
import Navbar from '../Navbar/Main';
import Footer from '../Footer/Main';
import categoryInfo from '../../categoryInfo.json'

export default function Service() {
    //axios api to display monetary policy data
    const [post, setPost] = useState([]); 
    useEffect(() => {
    setPost(categoryInfo.financialmarket);
}, []);

  return (
    <>
    <Navbar/>
        <div className="stricky-header stricked-menu main-menu">
            <div className="sticky-header__content"></div>
        </div>
        <section className="page-header">
            <div className="page-header__bg"    ></div>
            <div className="container">
                <ul className="thm-breadcrumb list-unstyled">
                    <li><Link to="/">Home</Link></li>
                    <li><span>Financial Markets</span></li>
                </ul>                
                <div className="surrounding slider-one__title">
                A Financial market is a marketplace where financial instruments are issued and/or traded.  
                It plays a vital role for a smooth functioning of the modern economy as it enables investors to efficiently allocate their funds, manage risk, 
                and speculate on market movements. 
                </div>
            </div>
        </section>
        <section className="service-five ">
            <div className="container">
            <div className="row row-gutter-y-50 service-four__inner">    
               <div className="about-three__content"> 
                        <div className="block-title text-center">
                          <h2 className="block-title__tagline">Get to know about NBR Financial Market</h2>
                        </div>
                        <p className="about-three__text"> It is mainly through the financial markets that the Central Bank Rate (CBR) influences interest rates and ensure a market-driven exchange rate. 
                        This, in turn, helps the Central Bank to achieve its monetary policy objectives. 
                        The Central Bank is also involved in financial markets through auctions of government securities. 
                        At the National Bank of Rwanda (NBR), the implementation of monetary policy decisions and issuance of government securities are undertaken by the Financial Markets Department. 
                        The department is also responsible for computing and publishing the official exchange rate and the implementation of the Bank decisions regarding the Foreign Exchange Reserves Management. 
                        </p>  
                </div>                
                {
                      post ? post.map((p, index) => (
                                    <>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="service-card">
                            <div className="service-card__image">
                                <img src={p.image} width="370" height="300" alt="" />
                                <Link to={`${p.link}`} > 
                                 </Link>
                            </div>
                            <div className="service-card__content">
                                <div className="service-card__content__inner">
                                <div className="service-card__icon">
                                        {/* <i className="icon-payment"></i><Link to={`${p.link}`} > </Link> */}
                                        <img src={logo} width="70" height="70" alt="BNR"/>
                                    </div>
                                    <h3 className="service-card__title">
                                    <Link to={`${p.link}`}>{p.title}</Link>
                                    </h3>
                                    <p className="service-card__text">{p.summary}
                                    <Link to={`${p.link}`} className="service-card__link">
                                        <i className="fa fa-angle-right"></i>
                                    </Link>
                                    </p>
                                </div>
                            </div>
                        </div>
             
                </div>
                </> 
                           
                           )) : <p>loading...</p>
                       }
          
                   
                    {/* <div className="call-to-action-one__inner wow call-to-action-one fadeInUp blog-details__form" data-wow-duration="1500ms">
                    <form onSubmit={handleSubmit} className="form-one contact-form-validated">
                                <div key="" className="row row-gutter-y-20 row-gutter-x-20">
                                 
                                    <div className="col-md-5">
                                     <h3 className="call-to-action-one__title">How do you rate our services?</h3>
                                     <ul className="list-unstyled radiolist">
                                          <li>
                                            <input type="radio" name="flexRadioDefault" className='checker'></input>
                                                 Excellent
                                          </li>
                                          <li>
                                            <input type="radio" name="flexRadioDefault" className='checker' checked></input>
                                                 Very good
                                          </li>
                                          <li>
                                            <input type="radio" name="flexRadioDefault" className='checker'></input>
                                                 Good
                                          </li>
                                          
                                     </ul>
                                    </div>
                                    <div className="col-md-6">
                                        <textarea placeholder="add your comment/suggestion here" id='comment' name="comment" value={newComment.comment} onChange={handleChange}></textarea>
                                    </div>
                                    <div className="col-md-1 call-to-action-one__btns">
                                        <button type="submit" className="thm-btn" disabled={isSubmitting}> 
                                        {isSubmitting ? 'Submitting...' : 'Submit'}</button>
                                        {isSubmitted && <p>Comment submitted successfully!</p>}
                                    </div>
                                </div>
                            </form>
                </div> */}
                 </div>
               </div>
        </section>
        <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link>
        <Footer/>
    </>
  )
}

