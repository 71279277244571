import React from 'react'
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import ResultCard from './ResultCard';
import { useState } from 'react';
import Navbar from "../Navbar/Main";
import Footer from "../Footer/Main";
import BGPH1 from '../../assets/images/backgrounds/page-header-bg-1-1.jpg'

import { Link, useParams } from 'react-router-dom'
import Searchbar from './searchbar';



const Results = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [localSearch, setLocalSearch] = useState('');

  const [checked, setChecked] = useState({
    name: '',
    isChecked: false
  })

  const [results, setResults] = useState({
    isLoading: false,
    error: {
      isError: false,
      message: ""
    },
    data: null
  });

  const fetchResults = async (name) => {
    setResults(() => {
      return {
        isLoading: true,
        error: {
          isError: false,
          message: ''
        },
        data: null
      }
    })

    axios.get(`/${name}`)
      .then((response) => {
        let postData = response.data
        console.log(postData.length, 'we are printing the length')
        setResults(() => {
          return {
            isLoading: false,
            error: {
              isError: false,
              message: ''
            },
            data: postData
          }
        })
        // setPost(postData)
      })
      .catch((error) => {
        setResults(() => {
          return {
            isLoading: false,
            error: {
              isError: true,
              message: error
            },
            data: null
          }
        })
      })
  }

  useEffect(() => {
    fetchResults(searchParams.get("q").replace(" ", ""));
  }, [searchParams])

  return (
    <>
    <Navbar/>
        <div className="stricky-header stricked-menu main-menu">
            <div className="sticky-header__content"></div>
        </div>
        <section className="page-header">
            <div className="page-header__bg"    ></div>
            <div className="container">
                <ul className="thm-breadcrumb list-unstyled">
                    <li><Link to="/">Home</Link></li>
                    <li><span>Search result</span></li>
                </ul>
            </div>
        </section>
        <section className="service-details pt-30 pb-30">
    <div className="container">
     <div className="row row-gutter-y-50 contact-info-one">
      <div className="col-lg-8">
        <div className='searching'>
        Search Results:
          {/*<span className='text-2xl'>{`${searchParams.get("q")}`}</span>*/}
        </div>
        <div className='service-details__content'>

          <div className='flex-grow'>
            {
              results?.data?.map((el, id) => {
                return (
                  <ResultCard props={el} key={id} />
                )
              })
            }
          </div>


        </div>

        </div>
        <div className="col-lg-4">
          <Searchbar/>
         </div>

      </div>
    </div>
    </section>
    <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link>
        <Footer/>
    </>
  )
}

export default Results