import BGPH1 from '../../../../assets/images/backgrounds/page-header-bg-1-1.jpg'
import { Link } from 'react-router-dom'
import React, { useEffect, useState, useMemo } from "react";
//import { Container } from "react-bootstrap";
//import Header from "components/Header";
import { TableHeader, Pagination, Search } from '../../DataTable';
import Footer from '../../FooterKinya/Main';
import Navbar from '../../NavbarKinya/Main';

const DataTable = () => {
    const [currency, setcurrency] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });

    const ITEMS_PER_PAGE = 17;

    const headers = [
        { name: "CCY", field: "ccy", sortable: false },
        { name: "Buying", field: "buying", sortable: true },
        { name: "Selling", field: "selling", sortable: true },
        { name: "Date", field: "date", sortable: false }
    ];

    useEffect(() => {
        const getData = () => {
            fetch("/currency")
                .then(response => response.json())
                .then(json => {
                    setcurrency(json);
                    console.log(json);
                });
        };
        getData();
    }, []);
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });
      };
    const currencyData = useMemo(() => {
        let computedcurrency = currency;

        if (search) {
            computedcurrency = computedcurrency.filter(
                ccy =>
                    ccy.currency_name.toLowerCase().includes(search.toLowerCase()) ||
                    ccy.post_date.toLowerCase().includes(search.toLowerCase())
            );
        }

        setTotalItems(computedcurrency.length);

        //Sorting currency
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedcurrency = computedcurrency.sort(
                (a, b) =>
                    reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        //Current Page slice
        return computedcurrency.slice(
            (currentPage - 1) * ITEMS_PER_PAGE,
            (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
        );
    }, [currency, currentPage, search, sorting]);

    return (
        <>
        <Navbar/>
         <div className="stricky-header stricked-menu main-menu">
            <div className="sticky-header__content"></div>
        </div>
        <section className="page-header">
            <div className="page-header__bg"     ></div>
              <div className="container">
                <ul className="thm-breadcrumb list-unstyled">
                    <li><Link to="/">Home</Link></li>
                    <li><span>Exchange rate</span></li>
                </ul>
                <h2>Exchange rate</h2>
            </div>
        </section>
        <section className="service-five pt-50 pb-50">
      <div className="container">
      <div className="row row-gutter-y-10 service-four__inner">
            <div className="loan-comparison__body" id="compare-emi-1">
                <div className="compare-table">
                <div className="row">
                        <div className="col-md-6">
                            <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div>
                        <div className="col-md-6 d-flex flex-row-reverse">
                            <Search 
                                onSearch={value => {
                                    setSearch(value);
                                    setCurrentPage(1);
                                }}
                            />
                        </div>
                    </div>

                    <table className="table table-striped table-hover">
                        <TableHeader
                            headers={headers}
                            onSorting={(field, order) =>
                                setSorting({ field, order })
                            }
                        />
                        <tbody>
                            {currencyData.map(ccy => (
                                <tr>
                                    <td>{ccy.currency_name}</td>
                                    <td>{ccy.selling_rate}</td>
                                    <td>{ccy.buying_rate}</td>
                                    <td>{formatDate(ccy.created_at)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="row">
                        <div className="col-md-12">
                            <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </div>
            </section>
            <Footer/>
        </>
    );
};

export default DataTable;