import logo from '../../../assets/images/logo-dark.png'
import React, { useRef, useState, useEffect, useMemo } from "react"
import axios from 'axios'
import { Link } from 'react-router-dom'



const FooterKinya = () => {
    
const [link, setPost] = useState()
useEffect(() => {
    axios.get('/prodocuments')
        .then((response) => {
            let postData = response.data
            console.log(postData);
            setPost(postData)
        })
        .catch((error) => console.log(error))
}, [])

  return (
    <>
        <footer className="main-footer">
            <div className="container">
                <div className="row row-gutter-y-30">
                    <div className="col-lg-3 col-md-6">
                        <div className="footer-widget__info">
                            <img src={logo} width="90" height="90" alt="BNR"/>
                            <ul className="list-unstyled footer-widget__info">
                                <li>
                                    <i className="icon-email"></i>
                                    <Link to="mailto:info@bnr.rw ">info@bnr.rw</Link>
                                </li>
                                <li>
                                    <i className="icon-telephone"></i>
                                    <Link to="tel:+250788199000">+ (250) 788199000  </Link>
                                </li>
                            </ul>
                            <div className="footer-widget__social">
                                <Link to="#"><i className="fab fa-twitter"></i></Link>
                                <Link to="#"><i className="fab fa-facebook"></i></Link>
                                <Link to="#"><i className="fab fa-pinterest"></i></Link>
                                <Link to="#"><i className="fab fa-instagram"></i></Link>
                            </div>
                           
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="footer-widget footer-widget--links">
                            <h3 className="footer-widget__title">
                                Quick links
                            </h3>
                            <ul className="list-unstyled footer-widget__menu">
                                <li><Link to="/rw/about"><i className="fa fa-angle-right"></i> Abo turibo</Link></li>
                                <li><Link to="/rw/calendar"><i className="fa fa-angle-right"></i> Ikirangaminsi cy'ibiteganyije </Link></li>
                                <li><Link to="/rw/careers"><i className="fa fa-angle-right"></i> Kwamamaza akazi</Link></li>
                                <li><Link to="/rw/contact"><i className="fa fa-angle-right"></i> Mutwandikire</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="footer-widget footer-widget--links">
                            <h3 className="footer-widget__title">
                            Quick links
                            </h3>
                            <ul className="list-unstyled footer-widget__menu">
                                <li><Link to="#"><i className="fa fa-angle-right"></i> Amasezerano y'abaturage</Link></li>
                                <li><Link to="#"><i className="fa fa-angle-right"></i> Inzu y'ibitabo</Link></li>
                                <li><Link to="#"><i className="fa fa-angle-right"></i> e-Correspondence</Link></li>
                                <li><Link to="/rw/faqs"><i className="fa fa-angle-right"></i> Ibibazo bikunze kubazwa</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="footer-widget footer-widget--links">
                            <h3 className="footer-widget__title">
                            Amasoko
                            </h3>
                            <ul className="list-unstyled footer-widget__menu">
                            {
                                 link ? link.map((l, index) => (
                                    <>
                                    <li key={l.id}>
                                    <Link to={`/rw/prodocuments/${l.category}`}><i className="fa fa-angle-right"></i> {l.category}</Link>
                                    </li>
                                    </>
                                    )) : <p>loading...</p>
                                }
                                <li><Link to="#"><i className="fa fa-angle-right"></i> e-Procurement Portal</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <div className="bottom-footer">
            <div className="container">
                <p className="bottom-footer__text text-center">Copyright &copy; NBR {new Date().getFullYear()} All Rights Reserved.</p>
                
            </div>
        </div>
        
    </>
  )
}

export default FooterKinya
