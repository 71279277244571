import BGPH1 from '../../../assets/images/backgrounds/page-header-bg-1-1.jpg'
import { Link, useParams } from 'react-router-dom'
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import React, { useState, useEffect } from "react";
import axios from 'axios'
import Navbar from '../NavbarKinya/Main';
import Footer from '../FooterKinya/Main';

const TeamDetailsRw = () => {

    const {id} = useParams();
   // const pos = Service(id);
   const [pos, setPost] = useState()
   useEffect(() => {
       axios.get(`/teamdetails/?id=${id}`)
           .then((response) => {
               let postData = response.data
               console.log(postData);
               setPost(postData)
           })
           .catch((error) => console.log(error))
   }, [id])

  return (
    <>
    <Navbar/>
        <div className="stricky-header stricked-menu main-menu">
            <div className="sticky-header__content"></div>
        </div>
        <section className="page-header">
            <div className="page-header__bg"    ></div>
            <div className="container">
                <ul className="thm-breadcrumb list-unstyled">
                    <li><Link to="/">Home</Link></li>
                    <li><span>Team</span></li>
                </ul>
                <h2>Team Details</h2>
            </div>
        </section>

        <section className="team-details pt-120 pb-120">
            <div className="container ">
                <div className="row row-gutter-y-50 contact-info-one">
                          
                {
                                    
                                    pos ? pos.map((p, index) => (
                                      
                                    <>
                    <div className="col-lg-6">
                   
                        <div align="center" className="team-details__image wow fadeInUp" data-wow-duration="1500ms">
                            <img src={`../${p.image}`} width="300" height ="300" alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6">

                        <div className="team-details__content">
                                <div className="service-details__image">
                                    <a href="/About" className="service-details__icon"><i className="fa fa-arrow-left"></i></a>
                                </div>
                            <div className="team-details__floated">{p.name}</div>
                            <div className="block-title text-left">
                                <p className="block-title__tagline">{p.title}</p>
                                <h2 className="block-title__title">{p.name}</h2>
                            </div>
                            <div className="team-details__social">
                                <Link to="#"><i className="fab fa-twitter"></i></Link>
                                <Link to="#"><i className="fab fa-facebook"></i></Link>
                                <Link to="#"><i className="fab fa-pinterest"></i></Link>
                                <Link to="#"><i className="fab fa-instagram"></i></Link>
                            </div>
                            {/* <p className="team-details__highlight">Duis irure dolor lipsum is simply free text available.</p> */}

                            <p className="team-details__text">{p.description}</p>
                               
                        </div>
                        
                    </div>
                    </>
                            )) : <p>...</p>
                        }
                </div>
            </div>
        </section>

        <div className="container">
            <hr className="block-separator" />
        </div>

        <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link>
        <Footer/>


    </>
  )
}

export default TeamDetailsRw