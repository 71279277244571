import BGPH1 from '../../../assets/images/backgrounds/page-header-bg-1-1.jpg'
import { Link } from 'react-router-dom'
import React, { useState, useEffect } from "react";
import axios from 'axios'
import Navbar from '../NavbarKinya/Main';
import Footer from '../FooterKinya/Main';

export default function Service() {
    //axios api to display monetary policy data
    const [post, setPost] = useState()
    useEffect(() => {
        axios.get('/monetaryPolicy')
            .then((response) => {
                let postData = response.data
                console.log(postData);
                setPost(postData)
            })
            .catch((error) => console.log(error))
    }, [])

  return (
    <>
    <Navbar/>
        <div className="stricky-header stricked-menu main-menu">
            <div className="sticky-header__content"></div>
        </div>
        <section className="page-header">
            <div className="page-header__bg"    ></div>
            <div className="container">
                <ul className="thm-breadcrumb list-unstyled">
                    <li><Link to="/">Home</Link></li>
                    <li><span>Monetary Policy</span></li>
                </ul>
                <h2>Monetary Policy</h2>
            </div>
        </section>
        <section className="service-five ">
            <div className="container">
            <div className="row row-gutter-y-50 service-four__inner">    
               <div className="about-three__content"> 
                        <div className="block-title text-center">
                          <h2 className="block-title__tagline">Get to know about BNR Monetary Policy</h2>
                        </div>
                        <p className="about-three__text">Monetary policy is the process by which a central bank
                        manages the supply and demand of money and credit in an economy to achieve 
                        specific macroeconomic objectives. The primary objective of monetary policy is 
                        to promote price stability, which is typically defined as low and stable inflation.
                        </p>  
                </div>                
                {
                      post ? post.map((p, index) => (
                                    <>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="service-card">
                            <div className="service-card__image">
                                <img src={`/rw/${p.image}`} width="370" height="300" alt="" />
                                <Link to={`/mpdetails/${p.id}`} > 
                                 </Link>
                            </div>
                            <div className="service-card__content">
                                <div className="service-card__content__inner">
                                    <div className="service-card__icon">
                                        <i className="icon-payment"></i><Link to={`/mpdetails/${p.id}`} > </Link>
                                    </div>
                                    <h3 className="service-card__title">
                                    <Link to={`/mpdetails/${p.id}`}>{p.title}</Link>
                                    </h3>
                                    <p className="service-card__text">{p.summary}</p>
                                    <Link to={`/mpdetails/${p.id}`} className="service-card__link">
                                        <i className="fa fa-angle-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
             
                </div>
                </> 
                           
                           )) : <p>loading...</p>
                       }
          
                   
                    {/* <div className="call-to-action-one__inner wow call-to-action-one fadeInUp blog-details__form" data-wow-duration="1500ms">
                    <form onSubmit={handleSubmit} className="form-one contact-form-validated">
                                <div key="" className="row row-gutter-y-20 row-gutter-x-20">
                                 
                                    <div className="col-md-5">
                                     <h3 className="call-to-action-one__title">How do you rate our services?</h3>
                                     <ul className="list-unstyled radiolist">
                                          <li>
                                            <input type="radio" name="flexRadioDefault" className='checker'></input>
                                                 Excellent
                                          </li>
                                          <li>
                                            <input type="radio" name="flexRadioDefault" className='checker' checked></input>
                                                 Very good
                                          </li>
                                          <li>
                                            <input type="radio" name="flexRadioDefault" className='checker'></input>
                                                 Good
                                          </li>
                                          
                                     </ul>
                                    </div>
                                    <div className="col-md-6">
                                        <textarea placeholder="add your comment/suggestion here" id='comment' name="comment" value={newComment.comment} onChange={handleChange}></textarea>
                                    </div>
                                    <div className="col-md-1 call-to-action-one__btns">
                                        <button type="submit" className="thm-btn" disabled={isSubmitting}> 
                                        {isSubmitting ? 'Submitting...' : 'Submit'}</button>
                                        {isSubmitted && <p>Comment submitted successfully!</p>}
                                    </div>
                                </div>
                            </form>
                </div> */}
                 </div>
               </div>
        </section>
        <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link>
        <Footer/>
    </>
  )
}

