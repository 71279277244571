import BGPH1 from '../../assets/images/backgrounds/page-header-bg-1-1.jpg'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useParams } from 'react-router-dom'
import React, { useState, useEffect, useMemo }   from 'react'
import axios from 'axios'
import { TableHeader, Pagination, Search } from '../DataTable';
import Navbar from '../Navbar/Main'
import Footer from '../Footer/Main'
import SFquery from './sfquery';
import SFsidebar from './sfsidebar';

export const Sustainablefinancegovernance = () => {
    

   const [doc, setDoc] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });

    const ITEMS_PER_PAGE = 10;

    const headers = [
        { name: "Date", field: "Date", sortable: false },
        { name: "Documents", field: "Documents", sortable: false },
    ];

   const [loading, setLoading] = useState(true);
   useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`/sustainablefinancegovernance`);
        const docData = await response.json();
        setDoc(docData);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []); 

   const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    });
  };
  const docData = useMemo(() => {
    let computeddoc = doc;

    if (search) {
        computeddoc = computeddoc.filter(
                d =>
                d.date_last_modified.toLowerCase().includes(search.toLowerCase()) ||
                d.name.toLowerCase().includes(search.toLowerCase())
        );
    }

    setTotalItems(computeddoc.length);

    //Sorting doc
    if (sorting.field) {
        const reversed = sorting.order === "asc" ? 1 : -1;
        computeddoc = computeddoc.sort(
            (a, b) =>
                reversed * a[sorting.field].localeCompare(b[sorting.field])
        );
    }

    //Current Page slice
    return computeddoc.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
}, [doc, currentPage, search, sorting]);



        const [clicked, setClicked] = useState(0);

  return (
    <>
    <Navbar/>
        <div className="stricky-header stricked-menu main-menu">
            <div className="sticky-header__content"></div>
        </div>
        <section className="page-header">
            <div className="page-header__bg"    ></div>
            <div className="container">
                <ul className="thm-breadcrumb list-unstyled">
                    <li><Link to="/">Home</Link></li>
                    <li><span>Sustainable Finance Governance</span></li>
                </ul>
                <div className="surrounding slider-one__title">
                The Sustainable Finance Governance aims at planning, coordinating and supervising the environmental 
                and climate change actions of the NBR and acting as a reference team and central point of contact for stakeholders.
                The governance structure for sustainable finance at NBR consists of a steering committee, task forces, and a secretariat.

. 
                </div>
            </div>
        </section>
        {/* <div  className="about-four__shape"></div>
  <div  className="about-five__shape-2"></div> 
  <div  className="about-five__shape-1"></div>
  <div  className="about-five__shape-9"></div> 
  <div  className="about-five__shape-0"></div> */}
        <section className="service-details pt-30 pb-30">
            <div className="container">
                <div className="row row-gutter-y-50 contact-info-one">
                    <div className="col-lg-8">
                        {/* <div className="service-details__image">
                            <a href="/monetarypolicy" className="service-details__icon"><i className="fa fa-arrow-left"></i></a>
                        </div> */}
                        <div className="service-details__content">
                       
                            <div className="row row-gutter-y-30">
                                <div className="col-md-12">
                 
                      {/* <img src={p.image} alt="" /> */}
                      <h3 className="service-details__title">Sustainable Finance Governance</h3>
                      {/* <img src="../../../assets/images/services/service-d-1-1.png" alt="" /> */}
                      {/* <img src= {`../${p.image}`} alt="" /> */}
                      
                            <p>    The Sustainable Finance Governance aims at planning, coordinating and supervising the environmental 
                and climate change actions of the NBR and acting as a reference team and central point of contact for stakeholders.
                The governance structure for sustainable finance at NBR consists of a steering committee, task forces, and a secretariat:
                <ol type='a'>
                <li>Financial Sector Sustainability Task Force,</li>
                <li>Monetary Policy and Financial Market Task force,</li>
                <li>Net Zero Central Bank Task Force,</li>
                <li>Secretariat</li>
                </ol>

 </p>

                                <b>Responsibilities</b>
                                <p><b>The responsibilities of the Steering Committee are the following: </b></p>
                                <ol type='i'>
                                    <li>providing strategic orientation to the sustainable finance group and setting annual climate-related priorities of the BNR;</li>
                                    <li>review and approve bi-annual progress reports;</li>
                                    <li>report to the management and the Board</li>
                                </ol>
                                <p><b>The Financial Sector Sustainability Task Force has the following responsibilities: </b></p>
                                <ol type='i'>
                                    <li>to integrate climate-related and environmental financial risks into the BNR’s regulatory and supervisory frameworks, looking at opportunities and risks;</li>
                                    <li>bridging data gaps and devising key metrics for analysing climate-related risks; </li>
                                    <li>to build capacity and create awareness of Sustainable Finance among the regulated institutions, including facilitating engagement with stakeholders;</li>
                                    <li>to adopt the plan of activities of the task force.</li>
                                </ol>
                                <p><b>The Monetary Policy and Financial Markets Task Force has the following responsibilities: </b></p>
                                <ol type='i'>
                                <li>to integrate climate-related and environmental financial risks into the BNR’s monetary policy frameworks;</li>
                                <li>to integrate climate change considerations into the Bank’s investment portfolios;</li>
                                <li>to devise key metrics for analyzing climate-related risks on Monetary Policy;</li>
                                <li>to make a research and analysis of climate risk impact;</li>
                                <li>to develop sustainable financial market strategies and products;</li>
                                <li>to adopt the plan of activities of the task force.</li>

                                </ol>
                                <p><b>The Monetary Policy and Financial Markets Task Force has the following responsibilities: </b></p>
                                <ol type='i'>
                                <li>Define net zero strategy for BNR.</li>
                                <li>Assess BNR’s Climate risk exposure.</li>
                                <li>Greening central banks’ corporate operations.</li>
                                <li>Devise key metrics and targets for measuring and reporting climate-related risks for BNR.</li>
                                <li>Foster Central banks’ own disclosure of climate-related and environmental risks.</li>
                                <li>Build capacity in the BNR on Sustainable Finance. </li>
                                <li>Adopt the plan of activities of the task force</li>
                                </ol>
                                <p><b>The Secretariat has the following responsibilities: </b></p>
                                <ol type='i'>
                                <li>Coordinate the sustainable finance group’s activities on a day-to-day basis.</li> 
                                <li>To prepare consolidated reports of the sustainable finance group’s activities.</li>
                                <li>To support any task force as the need arises. </li>
                                <li>To coordinate capacity-building efforts.</li>
                                <li>Prepare and coordinate the BNR representation in high-level international forums.</li>
                                <li>Coordinate BNR’s engagement with local and international partners.</li>
                                <li>Act as the secretary of the steering committee. </li>
                                </ol>

                                {loading ? (
                
                <p></p>
                    ) : docData && docData.length > 0 ? (
                        <>  
                       <p className="service-details__text"><b>The following are documents related to Sustainable Finance Governance</b></p>
                                         
                        
               <div className="loan-comparison__body" id="compare-emi-1">
                <div className="compare-table_doc table-responsive">
                        <div className="benefit-two__tab__doc2">
                            <ul className="list-unstyled">
                                <li className='row'> 
                                    <Search className="search" onSearch={value => { setSearch(value); setCurrentPage(1); }}/>
                                 {/* <Route path="/esstatistics" element={<Esstatistics />} /><span><Link className='linkcolor' to="/pension">Link for Laws and Regulations</Link></span> */}
                                 </li> 
                            </ul>
                        </div>
                  <table className="table">
                  <TableHeader
                            headers={headers}
                            onSorting={(field, order) =>
                                setSorting({ field, order })
                            }
                        />
                                <tbody>
                                   
                         {
                                           docData ? docData.map((d, index) => (
                                            
                                            <tr key="">
                                                <td>{formatDate(d.date_last_modified)}</td>
                                                <td>
                                                <div className="accrodion-grp service-details__accrodion" data-grp-name="service-details__accrodion-1">

                                        <div className={`accrodion  wow fadeInUp ${index === clicked && "active"}`} key={index} data-wow-delay="0ms">
                                            <div className="accrodion-title" onClick={() => setClicked(index)}>
                                                <h4>{d.name}</h4>
                                                <span className="accrodion-icon"></span>
                                            </div>
                                            {index === clicked && 
                                            <div className="accrodion-content">
                                                <div className="inner_doc" >
                                                    <p>{d.summary}</p>
                                                    <Link to={d.file} target="_blank">Download/View <i className="fa fa-file-pdf"></i></Link>
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                                </td>
                                            </tr>
                                     
                               )) : <tr key=""><td>Loading.....</td></tr>
                            }
                                </tbody>
                  </table>
                  <div className="col-md-4">
                            <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div>
                            </div>
                            </div>
                            </>  
                      ) : (
                        <p></p>
                      )}
                            </div>
                            </div>
                    </div>
                    </div>
                    <div className="col-lg-4">
                    <SFsidebar/>
                    </div>

                </div>
            </div>
        </section>
        <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link>
        <Footer/>
    </>
  )
}
