import BGPH1 from '../../assets/images/backgrounds/page-header-bg-1-1.jpg'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useParams } from 'react-router-dom'
import React, { useState, useEffect, useMemo }   from 'react'
import FEsidebar from './fesidebar'
import axios from 'axios'
//import { Container } from "react-bootstrap";
//import Header from "components/Header";
import { TableHeader, Pagination, Search } from '../DataTable';
import Navbar from '../Navbar/Main'
import Footer from '../Footer/Main'
import FEquery from './fequery'
// import Service from '../Main'
// import onSubmitForm from '../Main'

export const Education = () => {
    

   const [doc, setDoc] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });

    const ITEMS_PER_PAGE = 10;

    const headers = [
        { name: "Date", field: "Date", sortable: false },
        { name: "Documents", field: "Documents", sortable: false },
    ];

   const [loading, setLoading] = useState(true);
   useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`/fis`);
        const docData = await response.json();
        setDoc(docData);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []); 
   const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    });
  };
  const docData = useMemo(() => {
    let computeddoc = doc;

    if (search) {
        computeddoc = computeddoc.filter(
                d =>
                d.date_last_modified.toLowerCase().includes(search.toLowerCase()) ||
                d.name.toLowerCase().includes(search.toLowerCase())
        );
    }

    setTotalItems(computeddoc.length);

    //Sorting doc
    if (sorting.field) {
        const reversed = sorting.order === "asc" ? 1 : -1;
        computeddoc = computeddoc.sort(
            (a, b) =>
                reversed * a[sorting.field].localeCompare(b[sorting.field])
        );
    }

    //Current Page slice
    return computeddoc.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
}, [doc, currentPage, search, sorting]);



        const [clicked, setClicked] = useState(0);

  return (
    <>
    <Navbar/>
        <div className="stricky-header stricked-menu main-menu">
            <div className="sticky-header__content"></div>
        </div>
        <section className="page-header">
            <div className="page-header__bg"    ></div>
            <div className="container">
                <ul className="thm-breadcrumb list-unstyled">
                    <li><Link to="/">Home</Link></li>
                    <li><span>Financial Education</span></li>
                </ul>
                <div className="surrounding slider-one__title">
                NBR aims to empower unserved and underserved segments of the population such as women, the youth, 
                and MSMEs through awareness campaigns and 
                financial education programs to help them make sound financial decisions. 
                </div>
            </div>
        </section>
        {/* <div  className="about-four__shape"></div>
  <div  className="about-five__shape-2"></div> 
  <div  className="about-five__shape-1"></div>
  <div  className="about-five__shape-9"></div> 
  <div  className="about-five__shape-0"></div> */}
        <section className="service-details pt-30 pb-30">
            <div className="container">
                <div className="row row-gutter-y-50 contact-info-one">
                    <div className="col-lg-4">
                    <FEsidebar/>
                    </div>
                    <div className="col-lg-8">
                        {/* <div className="service-details__image">
                            <a href="/monetarypolicy" className="service-details__icon"><i className="fa fa-arrow-left"></i></a>
                        </div> */}
                        <div className="service-details__content">
                       
                            <div className="row row-gutter-y-30">
                                <div className="col-md-12">
                 
                      {/* <img src={p.image} alt="" /> */}
                      <h3 className="service-details__title">Financial Education</h3>
                      {/* <img src="../../../assets/images/services/service-d-1-1.png" alt="" /> */}
                      {/* <img src= {`../${p.image}`} alt="" /> */}
                      
                            <p>Financial Education/Literacy is about sharing knowledge and skills with the 
                                different target groups on how best they can manage their finances. 
                                The National Bank of Rwanda carries out financial literacy among the different target groups for these reasons: 
                            </p>
                        <ul>
                            <li>Share knowledge and skills: As the saying goes, knowledge is power. 
                                One of the top reasons as to why some people do not use financial services or products is because they are not aware about them or do not know how to use the available resources. 
                                Therefore, through financial literacy, 
                                we equip the masses with knowledge and skills about the available financial products or services on the Rwandan market and this therefore allows them to make better informed financial decisions.
</li>
                        </ul>
                        <ul>
                            <li>When Rwandans have attained sufficient financial knowledge and skills, this impacts their financial behaviors. 
                                They start to have positive behaviors towards money and their finances in general. 
                                Therefore, financial education is a means to an end in this aspect. 
                                To have all Rwandans equipped with knowledge and skills to improve their financial health and have positive financial habits. </li>
                        </ul>
                        <p> Financial education activities at the Central Bank looks at the people in Rwanda in general. 
                            However, there are under served segments that the Central Bank pays special focus to like; Youth, Women, Refugees, MSMEs and Persons with disabilities.
                            
                        <br> Financial Literacy is not a one man’s job. For the National Bank of Rwanda to achieve their objective, it is important to collaborate with other stakeholders to reach as many people as possible and make sure no one is left behind. Some of our partners include but are not limited to MINEDUC, REB, MINECOFIN, MINIYOUTH, MIGEPROF, AFR, Gender Monitoring Office, Regulatory bodies like ASSAR, AMIR, RBA, MINALOC, Development Partners, Financial Service Providers, NUDOR, and so on.</br>
                        
                        <br> Core Education activities </br>
                            </p>

                        

                            {loading ? (
                
                <p></p>
                    ) : docData && docData.length > 0 ? (
                        <>  
                       <p className="service-details__text">The following are documents related to Financial Education</p>
                      
                          
                        
               <div className="loan-comparison__body" id="compare-emi-1">
                <div className="compare-table_doc table-responsive">
                        <div className="col-md-4 d-flex flex-row-reverse">
                            <Search
                                onSearch={value => {
                                    setSearch(value);
                                    setCurrentPage(1);
                                }}
                            />
                        </div>
                  <table className="table">
                  <TableHeader
                            headers={headers}
                            onSorting={(field, order) =>
                                setSorting({ field, order })
                            }
                        />
                                <tbody>
                                   
                         {
                                           docData ? docData.map((d, index) => (
                                            
                                            <tr key="">
                                                <td>{formatDate(d.date_last_modified)}</td>
                                                <td>
                                                <div className="accrodion-grp service-details__accrodion" data-grp-name="service-details__accrodion-1">

                                        <div className={`accrodion  wow fadeInUp ${index === clicked && "active"}`} key={index} data-wow-delay="0ms">
                                            <div className="accrodion-title" onClick={() => setClicked(index)}>
                                                <h4>{d.name}</h4>
                                                <span className="accrodion-icon"></span>
                                            </div>
                                            {index === clicked && 
                                            <div className="accrodion-content">
                                                <div className="inner_doc" >
                                                    <p>{d.summary}</p>
                                                    <Link to={d.file} target="_blank">Download/View <i className="fa fa-file-pdf"></i></Link>
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                                </td>
                                            </tr>
                                     
                               )) : <tr key=""><td>Loading.....</td></tr>
                            }
                                </tbody>
                  </table>
                  <div className="col-md-4">
                            <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div>
                            </div>
                            </div>
                            </>  
                      ) : (
                        <p></p>
                      )}
                            </div>
                            </div>
                    </div>
                    </div>
                </div>
            </div>
        </section>
        <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link>
        <Footer/>
    </>
  )
}
