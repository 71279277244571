import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../Navbar/Main'
import Footer from '../Footer/Main'

const Statuse = () => {
  return (
    <>
      <Navbar/>
      <div className="stricky-header stricked-menu main-menu">
        <div className="sticky-header__content"></div>
        </div>
        <section className="page-header">
            <div className="page-header__bg"    ></div>
            <div className="container">
                <ul className="thm-breadcrumb list-unstyled">
                    <li><Link to="/">Home</Link></li>
                    <li><span>Terms of Use for Official Statistics from BNR</span></li>
                </ul>
            </div>
        </section>
        <section className="service-five pt-50 pb-50">
        <div className="container">
            <div className="row row-gutter-y-50 service-four__inner"> 
               <div className="col-lg-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">
                        <div className="service-details__content">
                        <br/>
                            <h3 className="service-details__title">Introduction</h3>
                            <p>
                            The National Bank of Rwanda (NBR) provides access to official statistics for public use. 
                            These statistics are made available to enhance transparency, promote informed decision-making, and support research and analysis. 
                            By accessing and using the official statistics provided by the NBR, you agree to comply with these Terms of Use. 
                            </p><br/><br/>
                            <p>
                            <h3 className="service-details__title">Access and Use of Statistics</h3>
                           
                            The official statistics provided by NBR are available under <a href="https://creativecommons.org/licenses/by/4.0/deed.en">A Creative Commons Attribution 4.0 International </a> 
                            use by individuals, businesses, researchers, government agencies, and other organizations. 
                            Data published are free to use and Users may: 
                            <div className="documentation-content">
                          <ul>
                              <li><i className="fa fa-check-circle"></i> Access, download, and use the statistics for personal, commercial, academic, or research purposes.</li>
                              <li><i className="fa fa-check-circle"></i> Share or redistribute the statistics, provided the source ( National Bank of Rwanda) is clearly acknowledged.</li>
                            </ul>
                            </div>
                            </p><br/><br/>
                            <p>
                            <h3 className="service-details__title">Restrictions on Use</h3>
                           
                            Users are not permitted to:
                            <div className="documentation-content">
                          <ul>
                              <li><i className="fa fa-check-circle"></i> Apply legal terms or <a href="https://creativecommons.org/licenses/by/4.0/deed.en#ref-technological-measures">technological measures</a> 
                              that legally restrict others from doing anything the license permits.</li>
                            </ul>
                            </div>
                            </p><br/><br/>
                            <p>
                            <h3 className="service-details__title">Attribution and Acknowledgment</h3>
                            
                            When using or disseminating NBR official statistics, users must provide proper attribution, as follows: Source: 
                            National Bank of Rwanda Users are encouraged to link back to the NBR website or 
                            reference the relevant publication where the data was originally obtained.

                            </p><br/><br/>
                            <p>
                            <h3 className="service-details__title">Data Updates and Revisions</h3>
                            
                            Official statistics from the NBR are subject to periodic updates and revisions to ensure accuracy. 
                            The NBR reserves the right to modify, update, or remove data without prior notice.
                            Users are encouraged to regularly check for updates to ensure they are using the most current informations.
                            </p><br/><br/>
                            <p>
                            <h3 className="service-details__title">Third-Party Links</h3>
                            
                            The NBR may provide links to third-party websites or external data sources. These links are provided for convenience, 
                            and the NBR is not responsible for the content or accuracy of any external sources. 
                            Users accessing third-party sites should refer to their respective terms of use and privacy policies.
                            </p><br/><br/>
                            <p>
                            <h3 className="service-details__title">Intellectual Property Rights</h3>
                            
                            Data and official statistics are made available under an open license (See section, “Access and Use of Statistics”). 
                            However, other content, such as the NBR logo and photographs on the website, are subject to copyright and their use requires permission. 
                            Any unauthorized reproduction, redistribution, 
                            or commercial use of these materials without permission is prohibited.
                            </p><br/><br/>
                            <p>
                            <h3 className="service-details__title">Governing Law</h3>
                           
                            These Terms of Use are governed by and interpreted in accordance with the laws of Rwanda. 
                            Any disputes arising from the use of NBR official statistics will be subject to the jurisdiction of the courts in Rwanda.
                            </p><br/><br/>
                            <p>
                            <h3 className="service-details__title">Changes to Terms of Use</h3>
                            
                            The NBR reserves the right to modify these Terms of Use at any time. 
                            Users are encouraged to review the terms periodically to ensure compliance with the latest version
                            </p><br/><br/>
                            <p>
                            <h3 className="service-details__title">Contact Information</h3>
                           
                            For questions or clarifications regarding the use of official statistics, please contact:  <br/>
                                National Bank of Rwanda   <br/>
                                Statistics Department   <br/>
                                Email: info@bnr.rw  <br/>
                                Website: www.bnr.rw

                            </p>
                        </div>
                    </div>
                    </div>
                </div> 
        </section>
        <Footer/>
    </>

  );
};

export default Statuse;