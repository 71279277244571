import BGPH1 from '../../assets/images/backgrounds/bnrimage.jpg'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useParams } from 'react-router-dom'
import React, { useRef, useState, useEffect, useMemo }   from 'react'
import axios from 'axios'
//import { Container } from "react-bootstrap";
//import Header from "components/Header";
import { TableHeader, Pagination, Searchdata } from '../DataTable';
import Navbar from '../Navbar/Main'
import Footer from '../Footer/Main'
import SearchLinks from '../Home/Search';
import DOMPurify from 'dompurify';
// import Service from '../Main'
// import onSubmitForm from '../Main'

export const Resultdata = () => {
    
   const [doc, setDoc] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });

    const ITEMS_PER_PAGE = 10;

    const headers = [
        // { name: "Date", field: "Date", sortable: false },
        { name: "Search result ", field: "Search result ", sortable: false },
    ];
   const [loading, setLoading] = useState(true);
   useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`/search`);
        const docData = await response.json();
        setDoc(docData);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []); 
  const docData = useMemo(() => {
    let computeddoc = doc;

    if (search) {
        computeddoc = computeddoc.filter(
                d =>
                d.summary.toLowerCase().includes(search.toLowerCase()) ||
                d.title.toLowerCase().includes(search.toLowerCase())
        );
    }

    setTotalItems(computeddoc.length);

    //Sorting doc
    if (sorting.field) {
        const reversed = sorting.order === "asc" ? 1 : -1;
        computeddoc = computeddoc.sort(
            (a, b) =>
                reversed * a[sorting.field].localeCompare(b[sorting.field])
        );
    }

    //Current Page slice
    return computeddoc.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
}, [doc, currentPage, search, sorting]);

        const [clicked, setClicked] = useState(0);

  return (
    <>
    <Navbar/>
        <div className="stricky-header stricked-menu main-menu">
            <div className="sticky-header__content"></div>
        </div>
        <section className="page-header">
            <div className="page-header__bg slider-one__image"   ></div>
              <div className="container">
                  <div className="search-popup__content">
                      <div className ="mandate1">
                        <p>
                        Rwanda's Central Bank mandated to ensure price stability and a sound financial system
                        </p>
                    </div>
                  <form style={{ overflow: 'visible' }} action="/resultdata" onSubmit={e =>
                      e.preventDefault()}>                  
                      <Searchdata
                        //   style={{ width: '80%', borderTopLeftRadius: '50%', borderBottomLeftRadius: '50%' }}
                      type="text"
                      id="search"
                      placeholder="Search here..."
                      onSearch={value => { setSearch(value); setCurrentPage(1); }} />
                   <button type="submit" aria-label="search submit" className="thm-btn">
                    <i className="icon-magnifying-glass"></i>
                </button>
                      </form>
                      </div>
            </div>
        </section>
        <section className="service-details">
            <div className="container">
                <div className="row row-gutter-y-10">
                      <div className="col-lg-12">

                        <div className="service-details__content">
                       
                            <div className="row row-gutter-y-30">
                                <div className="col-md-12">
   
                        {loading ? (
                
                <p></p>
                    ) : docData && docData.length > 0 ? (
                        
                        <>  
                       <div className="loan-comparison__body" id="compare-emi-1">
                <div className="compare-table_search table-responsive">

                  <table className="table">
                  <TableHeader
                            headers={headers}
                            onSorting={(field, order) =>
                                setSorting({ field, order })
                            }
                        />
                                <tbody>
                                   
                         {
                                           docData ? docData.map((d, index) => (
                                            
                                            <tr key="">
                                                <td>
                                                <div className="accrodion-grp service-details__accrodion" data-grp-name="service-details__accrodion-1">

                                        <div className={`accrodion  wow fadeInUp ${index === clicked && "active"}`} key={index} data-wow-delay="0ms">
                                            <div className="accrodion-title" onClick={() => setClicked(index)}>
                                                <h4>{d.title}</h4>
                                                <span className="accrodion-icon"></span>
                                            </div>
                                            {index === clicked && 
                                            <div className="accrodion-content">
                                                <div className="inner_doc" >
                                                    <p>
													   <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(`${d.summary}`)}} />
                         {/*{d.summary} */}
                        <div/></p>
                                                    <Link to={d.link} target="_blank">View details <i className="fa fa-file-pdf"></i></Link>
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                                </td>
                                            </tr>
                                     
                               )) : <tr key=""><td>Loading.....</td></tr>
                            }
                                </tbody>
                  </table>
                  <div className="col-md-4">
                            <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div>
                        </div>
                        </div>
                      </> 
                         ) : (
                        <p>No result found</p>
                                      )}
                                      
                            </div>
                            </div>
                    </div>
                    </div>
                    
                    <div className="col-lg-4">
                    </div>
                </div>
            </div>
        </section>
        <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link>
        <Footer/>
    </>
  )
}
