import BGPH1 from '../../assets/images/backgrounds/page-header-bg-1-1.jpg'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useParams } from 'react-router-dom'
import React, { useState, useEffect, useMemo,Component }   from 'react'
import LRsidebar from './lrsidebar'
import LRquery from './lrquery'
import axios from 'axios'
//import { Container } from "react-bootstrap";
//import Header from "components/Header";
import { TableHeader, Pagination, Search } from '../DataTable';
import Footer from '../Footer/Main'
import Navbar from '../Navbar/Main'
// import Service from '../Main'
// import onSubmitForm from '../Main'
import {
    MDBTabsContent,
    MDBTabsPane
  } from 'mdb-react-ui-kit';
// import Service from '../Main'
// import onSubmitForm from '../Main'

export const Creditreport = () => {
    
          
    const [iconsActive, setIconsActive] = useState('tab1');

    const handleIconsClick = (value) => {
      if (value === iconsActive) {
        return;
      }
      setIconsActive(value);
    };
   const [doc, setDoc] = useState([]); 
   const [docfsr, setDocfsr] = useState([]);
   const [docrb, setDocrb] = useState([]);
   const [docmr, setDocmr] = useState([]);
   const [docer, setDocer] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });


    const ITEMS_PER_PAGE = 10;

    const headers = [
        { name: "Date", field: "Date", sortable: false },
        { name: "Laws", field: "Laws", sortable: false },
    ];

   useEffect(() => {
       axios.get(`/crs_laws`)
           .then((response) => {
               let postData = response.data
               console.log(postData);
               setDoc(postData)
           })
           .catch((error) => console.log(error))
   }, [])

   const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    });
  };
  
  const docData = useMemo(() => {
    let computeddoc = doc;

    if (search) {
        computeddoc = computeddoc.filter(
                d =>
                d.date_last_modified.toLowerCase().includes(search.toLowerCase()) ||
                d.name.toLowerCase().includes(search.toLowerCase())
        );
    }

    setTotalItems(computeddoc.length);

    //Sorting doc
    if (sorting.field) {
        const reversed = sorting.order === "asc" ? 1 : -1;
        computeddoc = computeddoc.sort(
            (a, b) =>
                reversed * a[sorting.field].localeCompare(b[sorting.field])
        );
    }

    //Current Page slices
    return computeddoc.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
}, [doc, currentPage, search, sorting]);

    //axios api to display financial stability reports

    const ITEMS_PER_PAGE2 = 10;

    const headers1 = [
        { name: "Date", field: "Date", sortable: false },
        { name: "Regulations", field: "Regulations", sortable: false },
    ];

   useEffect(() => {
       axios.get(`/crs_regulations`)
           .then((response) => {
               let postData = response.data
               console.log(postData);
               setDocfsr(postData)
           })
           .catch((error) => console.log(error))
   }, [])

  const docData1 = useMemo(() => {
    let computeddoc = docfsr;

    if (search) {
        computeddoc = computeddoc.filter(
                d =>
                d.date_last_modified.toLowerCase().includes(search.toLowerCase()) ||
                d.name.toLowerCase().includes(search.toLowerCase())
        );
    }

    setTotalItems(computeddoc.length);

    //Sorting doc
    if (sorting.field) {
        const reversed = sorting.order === "asc" ? 1 : -1;
        computeddoc = computeddoc.sort(
            (a, b) =>
                reversed * a[sorting.field].localeCompare(b[sorting.field])
        );
    }

    //Current Page slice
    return computeddoc.slice(
        (currentPage - 1) * ITEMS_PER_PAGE2,
        (currentPage - 1) * ITEMS_PER_PAGE2 + ITEMS_PER_PAGE2
    );
}, [docfsr, currentPage, search, sorting]);


    //axios api to display Rwandan Banker reports

    const ITEMS_PER_PAGE3 = 10;

    const headers3 = [
        { name: "Date", field: "Date", sortable: false },
        { name: "Guidelines", field: "Guidelines", sortable: false },
    ];

   useEffect(() => {
       axios.get(`/crs_guidelines`)
           .then((response) => {
               let postData = response.data
               console.log(postData);
               setDocrb(postData)
           })
           .catch((error) => console.log(error))
   }, [])

  const docData3 = useMemo(() => {
    let computeddoc = docrb;

    if (search) {
        computeddoc = computeddoc.filter(
                d =>
                d.date_last_modified.toLowerCase().includes(search.toLowerCase()) ||
                d.name.toLowerCase().includes(search.toLowerCase())
        );
    }

    setTotalItems(computeddoc.length);

    //Sorting doc
    if (sorting.field) {
        const reversed = sorting.order === "asc" ? 1 : -1;
        computeddoc = computeddoc.sort(
            (a, b) =>
                reversed * a[sorting.field].localeCompare(b[sorting.field])
        );
    }

    //Current Page slice
    return computeddoc.slice(
        (currentPage - 1) * ITEMS_PER_PAGE3,
        (currentPage - 1) * ITEMS_PER_PAGE3 + ITEMS_PER_PAGE3
    );
}, [docrb, currentPage, search, sorting]);
  
const ITEMS_PER_PAGE4 = 10;

const headers4 = [
    { name: "Date", field: "Date", sortable: false },
    { name: "Directives", field: "Directives", sortable: false },
];

useEffect(() => {
   axios.get(`/crs_directives`)
       .then((response) => {
           let postData = response.data
           console.log(postData);
           setDocmr(postData)
       })
       .catch((error) => console.log(error))
}, [])

const docData4 = useMemo(() => {
let computeddoc = docmr;

if (search) {
    computeddoc = computeddoc.filter(
            d =>
            d.date_last_modified.toLowerCase().includes(search.toLowerCase()) ||
            d.name.toLowerCase().includes(search.toLowerCase())
    );
}

setTotalItems(computeddoc.length);

//Sorting doc
if (sorting.field) {
    const reversed = sorting.order === "asc" ? 1 : -1;
    computeddoc = computeddoc.sort(
        (a, b) =>
            reversed * a[sorting.field].localeCompare(b[sorting.field])
    );
}

//Current Page slice
return computeddoc.slice(
    (currentPage - 1) * ITEMS_PER_PAGE4,
    (currentPage - 1) * ITEMS_PER_PAGE4 + ITEMS_PER_PAGE4
);
}, [docmr, currentPage, search, sorting]);

        const [clicked, setClicked] = useState(0);

        return (
            <>
            <Navbar/>
                <div className="stricky-header stricked-menu main-menu">
                    <div className="sticky-header__content"></div>
                </div>
                <section className="page-header">
                    <div className="page-header__bg"    ></div>
                    <div className="container">
                        <ul className="thm-breadcrumb list-unstyled">
                            <li><Link to="/">Home</Link></li>
                        </ul>
                    </div>
                </section>
                {/* <div  className="about-four__shape"></div>
          <div  className="about-five__shape-2"></div> 
          <div  className="about-five__shape-1"></div>
          <div  className="about-five__shape-9"></div> 
          <div  className="about-five__shape-0"></div> */}
                <section className="service-details pt-120 pb-120">
                    <div className="container">
                        <div className="row row-gutter-y-50 contact-info-one">
                            
                            <div className="col-lg-8">
                                {/* <div className="service-details__image">
                                    <a href="/monetarypolicy" className="service-details__icon"><i className="fa fa-arrow-left"></i></a>
                                </div> */}
                                <div className="service-details__content">
                               
                                    <div className="row row-gutter-y-30">
                                        <div className="col-md-12">
                                
                              <>
                              {/* <img src={p.image} alt="" /> */}
                              <h3 className="service-details__title">Credit Register System</h3>
                              {/* <img src="../../../assets/images/services/service-d-1-1.png" alt="" /> */}
                              {/* <img src= {`../${p.image}`} alt="" /> */}
                              
                                    <p>The National Bank of Rwanda is the supervisor and regulator of credit reporting system in Rwanda. 
                                        The promotion, soundness, protection, accuracy and reliability of credit information (Credit Data) 
                                        is attained through implementation of international principles and standards as reviewed from time to time by the data providers. 
                                        The usual business is that Data providers are mandated to collect credit information from data subjects and 
                                        submit to the credit registry database for supervisory and statistical purposes.</p>

                                        <p>On the other hand, all financial institutions are also mandated to provide data to a private credit 
                                        reference bureau licensed by the Central Bank as per the law governing Credit Information System in Rwanda, 
                                        The sole purpose of the establishment of a private credit reference bureau is the sharing of credit information 
                                        among financial institutions, to ease the credit assessment and improve decision making before granting any loan.</p>

                                        <p>The Legal and regulatory framework embedded in this section is therefore designed to ensure that both Credit Reference Bureau 
                                        and Data Providers adhere to the norms and robust protection of Credit Information. 
                                        This section on the Central Bank website contains all relevant Legal Instruments concerning Credit Information, 
                                        Data Providers, Data Subjects and Credit Reference Bureau.
                                        </p>
                                    {/* <p></p><h3 className="service-details__subtitle">{p.title} Documents</h3> */}
                               
                               <p className="service-details__text">The following are Laws and Regulations related to Credit Register System</p>
        
                               <div className="row benefit-two__tab tabs-box" >
                            <ul className="list-unstyled benefit-two__tab__doc tab-btns tab-buttons"> 
        
                                            <li className={`${iconsActive === 'tab1'} ? 'active-btn' : 'tab-btn'}`} 
                                            onClick={() => handleIconsClick('tab1')} >
                                                <span>Laws</span>
                                            </li>
                                            <li className="tab-btn" onClick={() => handleIconsClick('tab2')} active={iconsActive === 'tab2'}>
                                                <span>Regulations</span>
                                            </li>
                                            <li className="tab-btn" onClick={() => handleIconsClick('tab3')} active={iconsActive === 'tab3'}>
                                                <span>Directives</span>
                                            </li>
                                            <li className="tab-btn" onClick={() => handleIconsClick('tab4')} active={iconsActive === 'tab4'}>
                                                <span>Guidelines</span>
                                            </li>
                                        </ul>
              <MDBTabsContent>
              <MDBTabsPane show={iconsActive === 'tab1'}>
        
        <div className="loan-comparison__body" id="compare-emi-1">
           <div className="compare-table_doc table-responsive">
                   <div className="col-md-4 d-flex flex-row-reverse">
                       <Search
                           onSearch={value => {
                               setSearch(value);
                               setCurrentPage(1);
                           }}
                       />
                   </div>
             <table className="table">
             <TableHeader
                       headers={headers}
                       onSorting={(field, order) =>
                           setSorting({ field, order })
                       }
                   />
                           <tbody>
                              
                    {
                                      docData ? docData.map((d, index) => (
                                       
                                       <tr key="">
                                           <td>{formatDate(d.date_last_modified)}</td>
                                           <td>
                                           <div className="accrodion-grp service-details__accrodion" data-grp-name="service-details__accrodion-1">
        
                                   <div className={`accrodion  wow fadeInUp ${index === clicked && "active"}`} key={index} data-wow-delay="0ms">
                                       <div className="accrodion-title" onClick={() => setClicked(index)}>
                                           <h4>{d.name}</h4>
                                           <span className="accrodion-icon"></span>
                                       </div>
                                       {index === clicked && 
                                       <div className="accrodion-content">
                                           <div className="inner_doc" >
                                           <p>{d.summary}</p>
                                               <Link to={d.file} target="_blank">Download/View <i className="fa fa-file-pdf"></i></Link>
                                           </div>
                                       </div>}
                                   </div>
                               </div>
                                           </td>
                                       </tr>
                                
                          )) : <tr key=""><td>Loading.....</td></tr>
                       }
                           </tbody>
                </table>
                  <div className="col-md-4">
                       <Pagination
                           total={totalItems}
                           itemsPerPage={ITEMS_PER_PAGE}
                           currentPage={currentPage}
                           onPageChange={page => setCurrentPage(page)}
                       />
                   </div>
               </div>
           </div>
        </MDBTabsPane>
                <MDBTabsPane show={iconsActive === 'tab2'}>
                    
                <div className="loan-comparison__body" id="compare-emi-1">
           <div className="compare-table_doc table-responsive">
                   <div className="col-md-4 d-flex flex-row-reverse">
                       <Search
                           onSearch={value => {
                               setSearch(value);
                               setCurrentPage(1);
                           }}
                       />
                   </div>
             <table className="table">
             <TableHeader
                       headers={headers1}
                       onSorting={(field, order) =>
                           setSorting({ field, order })
                       }
                   />
                           <tbody>
                              
                    {
                                      docData1 ? docData1.map((d, index) => (
                                       
                                       <tr key="">
                                           <td>{formatDate(d.date_last_modified)}</td>
                                           <td>
                                           <div className="accrodion-grp service-details__accrodion" data-grp-name="service-details__accrodion-1">
        
                                   <div className={`accrodion  wow fadeInUp ${index === clicked && "active"}`} key={index} data-wow-delay="0ms">
                                       <div className="accrodion-title" onClick={() => setClicked(index)}>
                                           <h4>{d.name}</h4>
                                           <span className="accrodion-icon"></span>
                                       </div>
                                       {index === clicked && 
                                       <div className="accrodion-content">
                                           <div className="inner_doc" >
                                               <p>{d.summary}</p>
                                               <Link to={d.file} target="_blank">Download/View <i className="fa fa-file-pdf"></i></Link>
                                           </div>
                                       </div>}
                                   </div>
                               </div>
                                           </td>
                                       </tr>
                                
                          )) : <tr key=""><td>Loading.....</td></tr>
                       }
                           </tbody>
                </table>
                  <div className="col-md-4">
                       <Pagination
                           total={totalItems}
                           itemsPerPage={ITEMS_PER_PAGE2}
                           currentPage={currentPage}
                           onPageChange={page => setCurrentPage(page)}
                       />
                   </div>
               </div>
           </div>
                </MDBTabsPane>
                
                    <MDBTabsPane show={iconsActive === 'tab3'}>
                    
                    <div className="loan-comparison__body" id="compare-emi-1">
               <div className="compare-table_doc table-responsive">
                       <div className="col-md-4 d-flex flex-row-reverse">
                           <Search
                               onSearch={value => {
                                   setSearch(value);
                                   setCurrentPage(1);
                               }}
                           />
                       </div>
                 <table className="table">
                 <TableHeader
                           headers={headers4}
                           onSorting={(field, order) =>
                               setSorting({ field, order })
                           }
                       />
                               <tbody>
                                  
                        {
                                          docData4 ? docData4.map((d, index) => (
                                           
                                           <tr key="">
                                               <td>{formatDate(d.date_last_modified)}</td>
                                               <td>
                                               <div className="accrodion-grp service-details__accrodion" data-grp-name="service-details__accrodion-1">
            
                                       <div className={`accrodion  wow fadeInUp ${index === clicked && "active"}`} key={index} data-wow-delay="0ms">
                                           <div className="accrodion-title" onClick={() => setClicked(index)}>
                                               <h4>{d.name}</h4>
                                               <span className="accrodion-icon"></span>
                                           </div>
                                           {index === clicked && 
                                           <div className="accrodion-content">
                                               <div className="inner_doc" >
                                                   <p>{d.summary}</p>
                                                   <Link to={d.file} target="_blank">Download/View <i className="fa fa-file-pdf"></i></Link>
                                               </div>
                                           </div>}
                                       </div>
                                   </div>
                                               </td>
                                           </tr>
                                    
                              )) : <tr key=""><td>Loading.....</td></tr>
                           }
                               </tbody>
                    </table>
                      <div className="col-md-4">
                           <Pagination
                               total={totalItems}
                               itemsPerPage={ITEMS_PER_PAGE4}
                               currentPage={currentPage}
                               onPageChange={page => setCurrentPage(page)}
                           />
                       </div>
                   </div>
               </div>
                    </MDBTabsPane>
        
                    <MDBTabsPane show={iconsActive === 'tab4'}>
                    
                    <div className="loan-comparison__body" id="compare-emi-1">
               <div className="compare-table_doc table-responsive">
                       <div className="col-md-4 d-flex flex-row-reverse">
                           <Search
                               onSearch={value => {
                                   setSearch(value);
                                   setCurrentPage(1);
                               }}
                           />
                       </div>
                 <table className="table">
                 <TableHeader
                           headers={headers3}
                           onSorting={(field, order) =>
                               setSorting({ field, order })
                           }
                       />
                               <tbody>
                                  
                        {
                                          docData3 ? docData3.map((d, index) => (
                                           
                                           <tr key="">
                                               <td>{formatDate(d.date_last_modified)}</td>
                                               <td>
                                               <div className="accrodion-grp service-details__accrodion" data-grp-name="service-details__accrodion-1">
            
                                       <div className={`accrodion  wow fadeInUp ${index === clicked && "active"}`} key={index} data-wow-delay="0ms">
                                           <div className="accrodion-title" onClick={() => setClicked(index)}>
                                               <h4>{d.name}</h4>
                                               <span className="accrodion-icon"></span>
                                           </div>
                                           {index === clicked && 
                                           <div className="accrodion-content">
                                               <div className="inner_doc" >
                                                   <p>{d.summary}</p>
                                                   <Link to={d.file} target="_blank">Download/View <i className="fa fa-file-pdf"></i></Link>
                                               </div>
                                           </div>}
                                       </div>
                                   </div>
                                               </td>
                                           </tr>
                                    
                              )) : <tr key=""><td>Loading.....</td></tr>
                           }
                               </tbody>
                    </table>
                      <div className="col-md-4">
                           <Pagination
                               total={totalItems}
                               itemsPerPage={ITEMS_PER_PAGE3}
                               currentPage={currentPage}
                               onPageChange={page => setCurrentPage(page)}
                           />
                       </div>
                   </div>
               </div>
                    </MDBTabsPane>
                </MDBTabsContent>
                   </div>
                     
                     </>
                              
                                    </div>
                                    </div>
                            </div>
                            </div>
                            <div className="col-lg-4">
                            <LRsidebar/>
                            </div>
                        </div>
                    </div>
                </section>
                <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link>
                <Footer/>
            </>
          )
}
