import BGS1 from '../../assets/images/services/service-s-1-1.png'
import React, { useState, useEffect }   from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'

export default function PROsidebar() {

    const [link, setPost] = useState()
    useEffect(() => {
        axios.get('/prodocuments')
            .then((response) => {
                let postData = response.data
                console.log(postData);
                setPost(postData)
            })
            .catch((error) => console.log(error))
    }, [])


  return (
    <div className="service-sidebar">
        <div className="service-sidebar__item service-sidebar__item--menu">
            <ul className="service-sidebar__menu">
            {/* {
                      link ? link.map((l, index) => (
                                    <>
              <li key={l.id}>
              <Link to={`${l.link}`}>{l.title}</Link>
              </li>
              </>
                                    )) : <p>loading...</p>
                                } */}
                  <li><Link to="/procurementPlan">Procurement Plan</Link></li>  
                  <li><Link to="/procurementPortal">Procurement Portal</Link></li>  
                  <li><Link to="/specificrule">Specific Rules</Link></li>  
                  <li><Link to="/tendernotice">Tender Notices</Link></li>  
             </ul>
           </div>
    </div>
  )
}
