export default function SFquery() {

  return (
  <section className="contact-info-one">
<div className="container">
    <div className="row">
        <div className="col-md-4 col-sm-12">
            <div className="contact-info-one__item">
                <div className="contact-info-one__icon">
                    <i className="icon-telephone"></i>
                </div>
                <div className="contact-info-one__content">
                    <p className="contact-info-one__text">Have any question?</p>
                    <a className="contact-info-one__link" href="tel:+ (250) 788199000 ">+ (250) 788199000 </a>
                </div>
            </div>
        </div>
        <div className="col-md-4 col-sm-12">
            <div className="contact-info-one__item">
                <div className="contact-info-one__icon">
                    <i className="icon-email"></i>
                </div>
                <div className="contact-info-one__content">
                    <p className="contact-info-one__text">Write us email</p>
                    <a className="contact-info-one__link" href="mailto:info@bnr.rw">procurement@bnr.rw</a>
                </div>
            </div>
        </div>
        <div className="col-md-4 col-sm-12">
            <div className="contact-info-one__item">
                <div className="contact-info-one__icon">
                    <i className="icon-pin"></i>
                </div>
                <div className="contact-info-one__content">
                    <p className="contact-info-one__text">Visit anytime</p>
                    <a className="contact-info-one__link" href="#"> KN 6 AV 4, Kigali Rwanda</a>
                </div>
            </div>
        </div>
    </div>
</div>
</section>
  )
}